
import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import PdfA4Page from "../Components/PdfA4Page";
import PdfAconLogo from "../Components/PdfAconLogo";
import PdfGeneralData from "../Components/PdfGeneralData";
import PdfGeneralInfo from "./PdfHzbaOverviewGeneralInfo.vue";
import PdfPageBreak from "../Components/PdfPageBreak";
import PdfImageRow from "../Components/PdfImageRow";
import PdfColorCodes from "../Components/PdfColorCodes";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import PdfMSEDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import { MaengelReport } from "@/models/ba/MaengelReport";
import Immobilie from "@/models/immobilie.model";
import PdfHzbaOverviewGeneralInfo from "@/components/Pdf/Bericht_Heizung/PdfHzbaOverviewGeneralInfo.vue";
export default defineComponent({
  name: "PdfHzbaOverviewPage",
  components: {
    PdfHzbaOverviewGeneralInfo,
    PdfDataSectionSummary,
    PdfMSEDataTable,
    PdfColorCodes,
    PdfImageRow,
    PdfPageBreak,
    PdfGeneralInfo,
    PdfGeneralData,
    PdfAconLogo,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    tableOfContentMSEData: {
      type: Object as PropType<ExtendedMSERow[] | undefined>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const baMaengelReport = new MaengelReport(
      props.ba?.getFiredMaengel({ allRelevantMangels: true })
    );
    const baSummaryMSE = baMaengelReport.getMSESummary();

    return {
      t,
      baSummaryMSE,
      useIdentifierFrageFinder,
      useIdentifierFragenblockFinder,
    };
  },
});
