
import { defineComponent, PropType } from "vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
// import PdfImageCropped from "@/components/Pdf/Components/PdfImageCropped.vue";

export interface ImageObjects {
  image: any;
  title: string;
  width: "double" | undefined;
}

export default defineComponent({
  name: "PdfImageRow",
  // components: { PdfImageCropped },
  props: {
    imageObjects: {
      type: Array,
      required: true,
    },
    hideTitles: {
      type: Boolean,
      default: false,
    },
    maxImages: {
      type: Number,
      default: 5,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      getPhotoUrl,
    };
  },
});
