<template>
  <footer>
    <h2>Copyright &#169; ACON Energie Management {{ new Date().getFullYear() }}</h2>

  </footer>
</template>

<script>
export default {
  name: "FooterComp",
}
</script>


<style scoped lang="scss">
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  background-image: radial-gradient(circle, #FE0007 10%, #963E9B 50%, #6395FC 90%);
  background-size: 300% 100%;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;

  animation: animatedgradient 30s ease infinite;


  @media (max-height: 650px) {
    position: static;
  }

  h2 {
    text-align: center;
    color: #fff;
  }


  @-webkit-keyframes animatedgradient{
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes animatedgradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @keyframes animatedgradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

}


</style>