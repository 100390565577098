import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2940a97a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"15%"} }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "text-left font-semibold",
  style: {"width":"65%","word-break":"break-word"}
}
const _hoisted_4 = { class: "mt-1 acon-grey" }
const _hoisted_5 = { class: "font-normal whitespace-normal" }
const _hoisted_6 = { class: "text-left font-semibold" }
const _hoisted_7 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfChip = _resolveComponent("PdfChip")!
  const _component_pdf_row_base = _resolveComponent("pdf-row-base")!

  return (_openBlock(), _createBlock(_component_pdf_row_base, null, {
    default: _withCtx(() => [
      _createElementVNode("td", _hoisted_1, [
        (
          _ctx.mangel.eingabeMedien &&
          _ctx.mangel.eingabeMedien.length > 0 &&
          _ctx.getPhotoUrl(_ctx.mangel.eingabeMedien[0])
        )
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: 
          _ctx.mangel.eingabeMedien &&
          _ctx.mangel.eingabeMedien.length > 0 &&
          _ctx.getPhotoUrl(_ctx.mangel.eingabeMedien[0])
        ,
              class: "mt-0",
              style: {"width":"120px","height":"120px"}
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("td", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.mangel.titel()) + " ", 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getNestedBreadcrumbTitle(_ctx.mangel.parentPath)), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.mangel.eingabeFreitext), 1)
      ]),
      _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.mangel.gewerkLabel()), 1),
      _createElementVNode("td", _hoisted_7, [
        _createVNode(_component_PdfChip, {
          value: _ctx.mangel.maluspunkte(),
          color: _ctx.mangel.getColor(),
          "pdf-mode": ""
        }, null, 8, ["value", "color"])
      ])
    ]),
    _: 1
  }))
}