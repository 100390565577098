<template>
  <BaseModal
  data-cy="iframesGalleryModal"
    :title="title"
    hide-save
    go-back-mode
    @close="() => cancel()"
  >
    <ion-content data-cy="iframesGalleryContent">
      <div
        class="iframe-wrapper"
      >
        <div
          v-for="data in iframes"
          :key="data.url"
          class="photo-container p-4"
          @click.stop="() => openModal(data.url)"
        >
          <!--  Autoplay etc as params:      -->
          <!--   https://support.matterport.com/hc/en-us/articles/209980967-URL-Parameters#visibility-0-3     -->
          <iframe
            frameborder="0"
            allow="xr-spatial-tracking"
            :src="data.url"
            width="100%"
            height="400"
            allowfullscreen
          />
        </div>
      </div>
    </ion-content>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal";
import useBaseModal from "@/composables/useModal";
import { IonImg, IonContent } from "@ionic/vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import {openBaseModal} from "@/utilities/modal-helper";

export default {
  name: "IframeGalleryModal",
  components: { BaseModal, IonImg, IonContent },
  props: {
    title: {
      type: String,
      default: ''
    },
    iframes: {
      type: Object,
      required: true
    }
  },
  emits: ['close', 'save'],
  setup() {

    const { defaultCancel } = useBaseModal();


    function openModal(url) {
      openBaseModal('gallery.iframe', { iframe: url, title: '3D Aufnahme' })
    }

    return {
      cancel: defaultCancel,
      getPhotoUrl,
      openModal
    }
  }
}
</script>

<style scoped>

iframe {
  pointer-events: none;
}

</style>