
export default interface StrapiMedia {
    id: string;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: Formats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    providerMetadata: string;
    related: string;
    createdBy: string;
    updatedBy: string;
}

interface Formats {
    small: Format;
    medium: Format;
    thumbnail: Format;
}

interface Format {
    ext: string;
    hash: string;
    height: number;
    width: number;
    mime: string;
    name: string;
    path: string;
    size: string;
    url: string;
}

export function instanceOfStrapiImage(object: any): object is StrapiMedia {
    if (object) {
        return 'url' in object;
    } else {
        return false;
    }
}