import {useStore as useS} from "vuex";
import {storeTypes} from "@/store/store";

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
export class Store {
    private static instance: Store;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor(public store: any) {
        //
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): Store {
        if (!Store.instance) {
            Store.instance = new Store(useS(storeTypes));
        }

        return Store.instance;
    }

}


export function useStore() {
    return Store.getInstance().store;
}
