<template>
  <ion-item
    lines="none"
    data-cy="base-input-item"
  >
    <ion-grid
      class="p-0"
      :class="separator ? 'border-bottom-1-light' : ''"
    >
      <ion-row
        class="ion-wrap d-flex items-center mb-1 px-0"
        :data-cy="`input-row-${label && label.replace(/\s+/g, '-').toLowerCase()}`"
      >
        <!-- <input-label class="flex-grow-10">
          {{ label }}
        </input-label> -->
        <ion-col class="md">
          <p class="flex-1">
            {{ label }}
          </p>
        </ion-col>


        <ion-col
          class="ion-float-right p-0 pl-0 flex justify-end"
        >
          <ion-input
            v-if="!type || (type.toLowerCase() !== 'date' && type.toLowerCase() !== 'time')"
            :key="key"
            :value="modelValue"
            data-cy="base-input"
            :placeholder="placeholder ? placeholder : (type === 'number' ? '2.2' : t('hzba.wertPlatzhalter'))"
            class="inline-block text-right"
            mode="md"
            :class="[
              flatMode ? 'flat' : '',
              type === 'integer' || type === 'number' ? 'number-input' : '',
            ]"
            style="min-width: 80px"
            :type="type === 'integer' ? 'number' : type"
            :pattern="type === 'integer' ? '[0-9]*' : ''"
            :disabled="readonly"
            :min="min"
            autocomplete="off"
            @ionBlur="onBlur"
            @input="onChanged"
            @keypress="bouncer"
          />
          <input
            v-else
            :type="type.toLowerCase() === 'date' ? 'date' : 'time'"
            class="inline-block text-right border-none"
            :class="[ flatMode ? 'flat' : '', !modelValue ? 'empty-content' : '' ]"
            style="padding-bottom: 8px; padding-top: 8px; min-width: 80px"
            :value="modelValue"
            :placeholder="type.toLowerCase() === 'date' ? 'TT.MM.YYYY' : 'HH:MM'"
            @ionBlur="onBlur"
            @input="(e) => onChanged(e)"
            @keypress="bouncer"
          >
        </ion-col>
        <unit-chip
          v-if="unit"
          :show-unit="true"
          :unit="unit"
          style="text-align: right"
        />
      </ion-row>

      <ion-row
        v-if="dependentReason"
        class="ion-wrap d-flex items-center"
      >
        <ion-col>
          <ion-text class="text-gray-600 text-sm">
            {{ dependentReason }}
          </ion-text>
        </ion-col>
      </ion-row>

      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script>
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { IonItem, IonCol, IonRow, IonGrid, IonText, IonInput, IonLabel } from "@ionic/vue";
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import { ref } from "vue";
export default {
  name: "HzbaBaseInput",
  components: {
    InputError,
    InputLabel,
    UnitChip,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel
  },
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    hideUnitSpace: {
      type: Boolean,
      default: false
    },
    unit: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: undefined
    },
    dependentReason: {
      type: String,
      default: undefined
    },
    flatMode: {
      type: Boolean,
      default: false
    },
    separator: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change', 'updateValue'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const key = ref(0);

    const changeValue = (e) => {
      const startLength = e.target.value.length;

      // if (props.min >= 0) {
      //   e.target.value = e.target.value.replace(/-/g, '');
      // }

      if (props.type === 'integer' && e.target.value && e.target.value.length > 0) {
        // e.target.value = e.target.value.replace('.','')
        // e.target.value = e.target.value.replace(',','')
      }

      if (startLength !== e.target.value.length) {
        key.value ++;
      }

      let val = e.target.value;
      try {
        if (props.type === 'integer') {
          val = Number.parseInt(val);
        }
        if (props.type === 'number' && val.length > 0) {
          val = Math.round(Number.parseFloat(val.replace(',', '.'))*10000) / 10000;
        }
      } catch (err) {
        console.error("Error parsing number: ", val, props.label)
      }


      if (!e.target.value || !props.modelValue || (props.modelValue && (e.target.value.toString() !== props.modelValue.toString()))) {
        const v = val ? val.toString() : val;
        emit('update:modelValue', v);
        emit('updateValue', v);  // For some reason I have to emit a new event instead of using change because change is emitted before the if statement already
      }
    }

    const onChanged = _.debounce((e) => {
      console.log("on changes...")
      changeValue(e);
    }, 600)

    function onBlur(e) {
      changeValue(e);
    }

    // const replaceComma = (e) => {
    //   e.target.value = e.target.value.replace(',', '.');
    // }


    const bouncer = (e) => {

      const a1 = props.type === 'number' && e.key === ',';
      const a2 = props.min >= 0 && e.key === '-'
      // const a3 = props.type === 'number' && e.key === ',';

      if (a1 || a2) {
        e.preventDefault();
      } else {
        return true;
      }
    }

    return {
      onChanged,
      key,
      onBlur,
      bouncer,
      t
    }
  }
}
</script>

<style scoped lang="scss">

ion-label {
  max-width: unset !important;
}

ion-input {
  float: right;
  max-width: 250px;
  //background: $grey-200;
  padding: 4px !important;
  padding-right: 8px !important;
  padding-left: 16px !important;
}

.flat {
  background: none;
}

.error-text {
  color: $aconRed;
}

.info-text {
  color: rgb(82, 81, 81);
  font-size: 14px;
}

.number-input {
  //margin-right: -16px; // Two arrows (lower and upper) will appear if input field is type of number
}
</style>

<style>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>