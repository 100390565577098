
import {computed, defineComponent, PropType} from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import PdfRowBase from "@/components/Pdf/Components/PdfRowBase.vue";
import {CustomInputData} from "@/components/Pdf/Bericht_Heizung/useHzbaPdfReport";
export default defineComponent({
  name: "PdfRowCustomInput",
  components: {
    PdfRowBase,
    AImage,
    PdfChip,
  },
  props: {
    customInputData: {
      type: Object as PropType<CustomInputData>,
      required: true,
    },
  },
  setup(props) {

    const imgSrc = computed(() => {
      return props.customInputData.bilder &&
          props.customInputData.bilder.length > 0 &&
          getPhotoUrl(props.customInputData.bilder[0])
    })
    return {
      getPhotoUrl,
      imgSrc,
    };
  },
});
