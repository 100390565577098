import User from "@/models/user";
// import Immobilie from "@/models/immobilie.model";

import {computed, ComputedRef, } from "vue";
import useAuth from "@/composables/useAuth";
import { logger } from "@/utilities/logging";
import {uploadFile, uploadPhoto} from "@/api/UploadApi";
import {useRouter} from "vue-router";
import CachedPhoto from "@/models/photo/cached-photo.model";

export default function useUser() {

    const user: ComputedRef<User | null> = computed(() => User.query().first());

    function isImmobilienverwalter(): boolean {
        return user.value?.role?.id === 2;
    }
    function isGutachter(): boolean {
        return user.value?.role?.id === 3;
    }
    function isProjektverantwortlicher(): boolean {
        return user.value?.role?.id === 1;
    }

    async function fetchUser() {
        try {
            const res = await User.api().get(`/users/me`);
            User.dispatch('$replaceLocally', { data: res.getDataFromResponse() }); 
            logger.defaultMeta.userId = (res.getDataFromResponse() as any).id;
        } catch (error) {
            if (error.message === "Network Error") {
                await User.dispatch("$fetchFromLocal");
                logger.error(`Couldn't fetch user: Network Error`);
            } else if(error.response.status === 401) {
                const router = useRouter();
                logger.error(`Couldn't fetch user: HTTP error 401`);
                router.push('/logout')
            } else {
                console.error(error);
                logger.error(`Couldn't fetch user: ${error}`);
            }
        }
    }

    async function updateProfileImage(photo: CachedPhoto) {
        if (!user.value || !user.value?.id) {
            return;
        }

        const res = await User.api().post(`/other/resetProfilbild`, {
            profilbild: undefined,
        });

        await uploadPhoto(photo, 'plugin::users-permissions.user', user.value?.id.toString(), 'profilbild', user.value.username + ".jpeg", 'users-permissions');

        await fetchUser();
        logger.defaultMeta.userId = user.value.id;

        logger.info(`User ${user.value.id} updated profile image`);
    }

    async function isLoggedIn() {
        const auth = useAuth();

        if (!auth.hasToken()) return false;

        if (auth.hasToken() && !user.value) {
            try {
                await fetchUser();
            } catch (error) {
                console.error(error);
            }
        }

        return !!user.value;
    }

    const isApproved = computed(() => {
        return user.value?.role?.id !== 4;
    })

    return {
        user,
        isLoggedIn,
        updateProfileImage,
        // addImmoPhoto,
        fetchUser,
        isGutachter,isProjektverantwortlicher,
        isApproved,
        isImmobilienverwalter
    };
}