
import AButton from "@/components/Base/AButton";
import licenseReport from "@/components/Impressum/licenseReport.json";
import { arrowBack } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import Toolbar from "@/components/Navigation/Toolbar";
import useUser from "@/composables/useUser";
import { useRouter } from "vue-router";

// import Bibliothek from "@/models/bibliothek.model";
import {
  IonContent,
  IonPage,
  onIonViewWillEnter,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import { useStore } from "@/composables/useTypedStore";

export default defineComponent({
  name: "ImpressumPage",
  components: {
    AButton,
    Toolbar,
    IonPage,
    IonContent,
    UserDialogButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonText,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
  },
  // props: {
  //   bibliothek: {
  //     type: Object as PropType<Bibliothek>,
  //       required: true
  //   }
  // },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { user } = useUser();
    const store = useStore();

    onIonViewWillEnter(() => {
      store.commit("app/showBottomNavigation");
    });

    const router = useRouter();
    const goBackToLandingPage = () => {
      router.push("/properties");
    };

    return {
      user,
      t,
      licenseReport,
      arrowBack,
      goBackToLandingPage,
    };
  },
});
