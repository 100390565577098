<template>
  <ion-row class="ion-wrap d-flex items-center">
    <ion-col class="pt-0 pb-2">
      <ion-text
        v-if="errors && errors.length > 0 && errors[0]?.$message"
        class="error-text"
      >
        {{ showAll ? showAllText : errors[0]?.$message }}
      </ion-text>
    </ion-col>
  </ion-row>
</template>

<script>
import { IonRow, IonText, IonCol } from "@ionic/vue";
import {computed} from "vue";
export default {
  name: "InputError",
  components: {
    IonRow,
    IonText,
    IonCol
  },
  props: {
    errors: {
      type: Object,
      default: undefined
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {

    const showAllText = computed(() => {
      let sumText = "";
      const errors = props.errors;

      if (errors && errors.length > 0){
        for (let i = 0; i < errors.length; i++) {
          if (errors[i]?.$message) {
            if (sumText.length > 0) {
              sumText += ", ";
            }
            sumText += errors[i]?.$message
          }
        }
      }
      return sumText
    })
    return {
      showAllText
    }
  }
}
</script>

<style scoped>

</style>