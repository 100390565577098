
import { useI18n } from 'vue-i18n';
import {defineComponent} from "vue";
import { IonPage, IonButton } from "@ionic/vue";
import AButton from "@/components/Base/AButton.vue";
import useAuth from "@/composables/useAuth";
import FooterComp from "@/components/FooterComp.vue";

export default defineComponent({
  name: "WaitingRoom",
  components: {
    FooterComp,
    AButton,
    IonPage,
    IonButton,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const logout = () => {
      useAuth().logout();
    }

    return {
      logout,
      t
    }
  }
})
