<template>
  <ion-header>
    <!-- Mobile toolbar -->
    <ion-toolbar
      color="red"
      class="mobile-header acon-gradient hzba-toolbar text-white flex items-end"
      :class="isDesktopNavEnabled ? 'lg:hidden' : ''"
    >
      <slot name="leading" />
      <ion-title v-if="title" class="title tracking-wide md:tracking-normal text-lg capitalize">
        {{ title ?? t("toolbar.kurzTitel") }} 
      </ion-title>
      <slot name="trailing" />
    </ion-toolbar>

    <!-- Desktop toolbar -->
    <ion-toolbar
      v-if="isDesktopNavEnabled"
      color="red"
      class="header acon-gradient hzba-toolbar hidden items-center text-white"
      :class="isDesktopNavEnabled ? 'lg:flex' : ''"
    >
      <div class="container flex items-end">
        <router-link
          class="flex items-center cursor-pointer title flex-1"
          to="/properties"
        >
          <img
            src="../../../public/assets/img/svg/acon_logo_colored.svg"
            alt="acon_icon"
            class="logo mr-4"
          />
          <ion-title class="tracking-wide"> 360° Inventory </ion-title>
        </router-link>

        <div slot="secondary" class="desktop-links flex">
          <router-link to="/properties" class="desktop-link">
            <ion-icon :icon="home" size="medium" />
            <ion-label>{{ t("toolbar.immobilien") }}</ion-label>
          </router-link>

          <router-link
            v-if="!user.isImmobilienverwalter()"
            to="/bas"
            class="desktop-link"
            routerDirection="back"
            data-cy="desk-toolbar-bas"
          >
            <ion-icon :icon="reader" />
            <ion-label>{{ t("toolbar.bestandsaufnahmen") }}</ion-label>
          </router-link>

<!--          <router-link-->
<!--            to="/settings"-->
<!--            class="desktop-link"-->
<!--            style="margin-right: 0"-->
<!--          >-->
<!--            <ion-icon :icon="settings" />-->
<!--            <ion-label></ion-label>-->
<!--          </router-link>-->

          <UserDialogButton></UserDialogButton>
        </div>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { home, settings, reader } from "ionicons/icons";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonButton,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonIcon,
  IonSplitPane,
  IonImg,
} from "@ionic/vue";
import AButton from "@/components/Base/AButton";
import { popoverHelper } from "@/utilities/popover-helper";
import useUser from "@/composables/useUser";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { isPlatform } from '@ionic/vue';
import {computed} from "vue";
import UserDialogButton from '../User/UserDialogButton.vue';

export default {
  name: "Toolbar",
  components: {
    AButton,
    IonIcon,
    IonButton,
    IonLabel,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonImg,
    IonTitle,
    IonToolbar,
    IonSplitPane,
    UserDialogButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    enableDesktopNav: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const user = useUser();

    const isDesktopToolbarForbidden = computed(() => {
      return !(isPlatform("ios") || isPlatform("ipad"));
    });

    const isDesktopNavEnabled = computed(() => {
      return isDesktopToolbarForbidden.value && props.enableDesktopNav;
    });

    return {
      home,
      settings,
      reader,
      user: user,
      openPopover: popoverHelper,
      getPhotoUrl,
      isDesktopNavEnabled,
      t
    };
  },
};
</script>

<style>

ion-toolbar {
  --padding-top: 8px;
  --padding-bottom: 10px;
}

</style>

<style scoped lang="scss">

.acon-gradient {
  background: linear-gradient(101deg, $aconRed, $aconBlue);
}

.hzba-toolbar {
  //height: $navigationHeight;
}

//@supports(padding: unquote('max(0px)')) {
  ion-toolbar {
    padding-top: unquote('constant(safe-area-inset-top))') !important;
    padding-top: env(safe-area-inset-top) !important;
  }
//}


.logo {
  height: 36px;
  filter: brightness(0) invert(1);
}

.title,
ion-title {
  font-weight: 550;
  // text-transform: uppercase;
}
.desktop-link,
.title {
  color: white;
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize !important;
}
.desktop-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 24px;
  // text-transform: uppercase;
  font-weight: 550;
  font-size: 16px;
  line-height: 39px;
  padding: 0 8px;

  &.router-link-active {
    border-bottom: 2px solid white;
  }

  ion-icon {
    margin-right: 6px;
    padding-bottom: 3px;
  }
}
</style>