<template>
  <div class="section-wrapper pt-2 pb-2">
    <div class="m16">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionWrapper"
}
</script>

<style scoped>

.section-wrapper {
  background: #f3f5f9;
}

</style>