
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import PdfChip from "@/components/Pdf/Components/PdfChip";
import {defineComponent} from "vue";
export default defineComponent({
  name: "GenericPdfMgtSummaryTable",
  components: {PdfChip},

  props: {
          data: {
            type: Array,
            required: true
          },
          valueTitle: {
            type: String,
            default: undefined
          },
          name: {
            type: String,
            default: 'Position'
          },
          lang: {
            type: String,
            default: 'de'
          },
          isTableOfContent: {
            type: Boolean,
          }
        },

  setup(props, { emit }) {

     const { t } = useI18n({ useScope: 'global' })
     const positionTranslated = computed(()=>{
     return props.name === 'Position'? t('hzba.pdf.position') : props.name
    })

    console.log('data:', props.data)

    const dummyData = {
      maengel:[
      {
        titel:"Abwasser: Fehlende Rohrschottung",
        kategorie:"Sicherheitsgefährdung",
        malus:"12",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"3000",
      },
      {
        titel:"Trinkwasser: Fehlende Dämmung",
        kategorie:"Sicherheitsgefährdung",
        malus:"5",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"500",
      },
      {
        titel:"Trinkwasser: Stagnation durch Stichleitungen",
        kategorie:"Sicherheitsgefährdung",
        malus:"20",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"2500",
      },
      {
        titel:"Abwasser: Fehlende Rohrschottung",
        kategorie:"Sicherheitsgefährdung",
        malus:"12",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"3000",
      },
      {
        titel:"Trinkwasser: Fehlende Dämmung",
        kategorie:"Sicherheitsgefährdung",
        malus:"5",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"500",
      },
      {
        titel:"Trinkwasser: Stagnation durch Stichleitungen",
        kategorie:"Sicherheitsgefährdung",
        malus:"20",
        status:"Akut",
        gewerk:"Sanitär",
        kostenprognose:"2500",
      }
    ]
    }

    return {
        dummyData,
        t
      }
  },

})
