
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent } from "vue";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { Fragenblock } from "@/models/ba/Fragenblock";
export default defineComponent({
  name: "PdfMSEDataTable",
  components: { PdfChip },

  props: {
    data: {
      type: Array as PropType<ExtendedMSERow[]>,
      required: true,
    },
    valueTitle: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "Position",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    shortTitle: {
      type: Boolean,
      default: false,
    },
    hideMangelAnzahl: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: "de",
    },
    hasDescription1Enabled: {
      type: Boolean,
      default: false,
    },
    maxShownItems: {
      type: Number,
      default: undefined,
    },
    // sortedData: {
    //   type: Array,
    //   default: undefined
    // }
  },

  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const positionTranslated = computed(() => {
      return props.name === "Position" ? t("hzba.pdf.position") : props.name;
    });

    // const sortData = (data: Array) => {
    // let newArray = data;
    // let el = data.splice(-2, 1);
    // console.log('return el: ', el);
    // return props.sortedData = newArray.push(el);
    // };

    return {
      positionTranslated,
      t,
      // sortData
    };
  },
});
