
import {defineComponent, PropType, ref, watch} from "vue";
import AButton from "@/components/Base/AButton";
import {Frage} from "@/models/ba/Frage";

export default defineComponent({
  name: "DebugFrage",
  components: {AButton},
  props: {
    frage: {
      type: Object as PropType<Frage>,
      required: true,
    },

  },
  setup(props) {
    const showJson = ref();

    const frageJson = ref();
    watch(() => props.frage, async (newFrage) => {
      frageJson.value = await newFrage.toClassJson()
    }, { immediate: true })

    return {
      showJson,
      frageJson,
    }
  }
})
