import {createRouter, createWebHistory} from "@ionic/vue-router";
import {RouteRecordRaw} from "vue-router";

import Login from '../pages/login.vue';
import Logout from '../pages/logout.vue';
import Signup from '../pages/signup.vue';
import Settings from '../pages/settings.vue';
import Demo from '../pages/demo.vue';
import Matterport from '../pages/hzba/_id/matterport.vue';
import HzbasPage from "@/pages/hzbas.vue"

import propertyRouter from './property/index';
import hzbaRouter from './hzba/index';
import useUser from "@/composables/useUser";
import PropertiesPage from "@/pages/properties.vue";
import FaqPage from "@/pages/faq.vue";
import ImpressumPage from "@/pages/impressum.vue";
import LogsPage from "@/pages/logs.vue";
import ViewHzba from "@/pages/hzba/_id/index.vue";
import WaitingRoom from "@/pages/WaitingRoom.vue";
import { store } from '@/store/store';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: "/properties"
    },
    {
        path: '/bas',
        name: 'bas',
        component: HzbasPage,
        meta: { requiresLoggedIn: true }
    },
    ...hzbaRouter,
    ...propertyRouter,
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { requiresLoggedOut: true }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: { requiresLoggedOut: true}
    },
    {
        path: '/waiting-room',
        name: 'waitingRoom',
        component: WaitingRoom,
        meta: { requiresLoggedIn: true}
    },
    {

        path: '/properties',
        name: 'propertiesList',
        component: PropertiesPage,
        meta: { requiresLoggedIn: true},
        children: [
            {
                path: 'map',
                name: 'propertiesMap',
                component: PropertiesPage,
                meta: { requiresLoggedIn: true }
            }
            ]
    },
    {
        path: '/demo',
        name: 'demo',
        component: Demo,
    },
    {
        path: '/matterport',
        name: 'matterport',
        component: Matterport,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/faq',
        name: 'faq',
        component: FaqPage,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: ImpressumPage,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: { requiresLoggedIn: true}
    },
    {
        path: '/logs',
        name: 'logs',
        component: LogsPage,
        meta: { requiresLoggedIn: true}
    },
];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeResolve(async (to, from, next) => {
    const user = useUser();
    const loggedIn = await user.isLoggedIn();
    const userRole = 'gutachter'; // TODO extra field for user role

    const routeMeta = ((to.matched && to.matched.length > 0 && to.matched[0].meta) || {}) as any;

    store.commit('app/setRecentRoute', from);

    const requiresAuthorizedUserGroup = routeMeta.authorizedUserGroups;
    const isAuthorizedUserGroup =
        loggedIn && (!routeMeta.authorizedUserGroups || routeMeta.authorizedUserGroups.find((el: string) => el === userRole));

    if (user?.user?.value?.role?.id === 4 && to.fullPath !== '/waiting-room') {
        next('/waiting-room')
    }

    if ((routeMeta.requiresLoggedIn || routeMeta.authorizedUserGroups) && !loggedIn) {
        next('/login')
    } else if ((routeMeta.requiresLoggedOut && loggedIn) || (!isAuthorizedUserGroup && requiresAuthorizedUserGroup)) {
        next('/')
    } else {
        next()
    }
});

export default router