
import { useI18n } from 'vue-i18n';
import {computed, PropType} from "vue";
import {store} from "@/store/store";
import {IonButton} from "@ionic/vue";
import {Fragenblock} from "@/models/ba/Fragenblock";

export default {
  name: "HzbaDeleteDuplicateItem",
  components: { IonButton },
  props: {
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true
    }
  },
  emits: ['duplicate', 'delete'],
  setup() {
const { t } = useI18n({ useScope: 'global' })
    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      isReadonly,
      t
    }
  }
}
