import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90d7c04e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"15%"} }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "text-left font-normal",
  style: {"width":"65%","word-break":"break-word"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pdf_row_base = _resolveComponent("pdf-row-base")!

  return (_openBlock(), _createBlock(_component_pdf_row_base, null, {
    default: _withCtx(() => [
      _createElementVNode("td", _hoisted_1, [
        (_ctx.imgSrc)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imgSrc,
              class: "mt-0",
              style: {"height":"120px","width":"90px"}
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.customInputData.bezeichnung), 1)
    ]),
    _: 1
  }))
}