import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05530f00"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "parentRef",
  class: "w-full relative",
  style: {"padding-top":"33%","position":"relative","margin-top":"16px"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "absolute bottom-0 right-0 p-4" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex mx-2" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("canvas", {
        ref: "canvasRef",
        class: _normalizeClass(_ctx.signature.id && _ctx.signature.bild ? 'hidden absolute' : ''),
        style: _normalizeStyle([{"background":"#efefef","position":"absolute","top":"0","left":"0"}, {
        width: `${_ctx.canvasDim.width}px`,
        height: `${_ctx.canvasDim.height}px`,
      }])
      }, null, 6),
      (_ctx.signature.id && _ctx.signature.bild)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            style: _normalizeStyle([{"background":"#efefef","position":"absolute","top":"0","left":"0"}, {
        width: `${_ctx.canvasDim.width}px`,
        height: `${_ctx.canvasDim.height}px`,
      }]),
            src: 
        _ctx.signature.bild && _ctx.signature.bild.length !== undefined
          ? _ctx.getPhotoUrl(_ctx.signature.bild[0])
          : _ctx.getPhotoUrl(_ctx.signature.bild)
      
          }, null, 12, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.isReadonly)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              slot: "icon-only",
              icon: _ctx.refreshOutline,
              class: "cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetSignature()))
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ])
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_input, {
          modelValue: _ctx.signature.name,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signature.name) = $event)),
          label: "Name",
          readonly: _ctx.isReadonly,
          errors: [],
          "data-cy": "input-name"
        }, null, 8, ["modelValue", "readonly"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (!_ctx.isReadonly)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              slot: "icon-only",
              icon: _ctx.trashOutline,
              class: "cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteSignature()))
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.signature.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Diese Unterschrift wurde bereits synchronisiert. Resetten, um neu zu unterschreiben. "))
        : _createCommentVNode("", true)
    ])
  ], 64))
}