
import { getPhotoUrl } from "@/utilities/get-media-url";
import moment from "moment";
import { useI18n } from "vue-i18n";

import Heizkreise from "@/components/Pdf/Bericht_Ensimimav/Heizkreise.vue";
import PdfEnsimimavOverviewPage from "@/components/Pdf/Bericht_Ensimimav/PdfEnsimimavOverviewPage.vue";
import Waermeverteilung from "@/components/Pdf/Bericht_Ensimimav/Waermeverteilung.vue";
import useEnsimimavPdfReport from "@/components/Pdf/Bericht_Ensimimav/useEnsimimavPdfReport";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import PdfFMDataTable from "@/components/Pdf/Components/PdfFMDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralDataEnsimimav.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import PdfMSEDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfMangelInputPageEnsimimav from "@/components/Pdf/Components/PdfMangelInputPageEnsimimav.vue";
import PdfPageBreak from "@/components/Pdf/Components/PdfPageBreak.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeaderEnsimimav.vue";
import {
useIdentifierFrageFinder,
useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import Immobilie from "@/models/immobilie.model";
import { PropType, defineComponent } from "vue";
import PdfGeneralInfo from "./PdfEnsimimavOverviewGeneralInfo.vue";

import PageCounter from "@/components/Pdf/Components/PageCounter.vue";
import PageCounterEnd from "@/components/Pdf/Components/PageCounterEnd.vue";
import PageCounterStart from "@/components/Pdf/Components/PageCounterStart.vue";


export default defineComponent({
  name: "PdfEnsimimavTemplate",
  components: {
    PdfMangelInputPageEnsimimav,
    // PdfMangelInputPage,
    PdfEnsimimavOverviewPage,
    PdfPageBreak,
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    PdfMSEDataTable,
    PdfFMDataTable,
    // PdfColorCodes,
    PdfMSEValues,
    PdfImageRow,
    // PdfDataSectionSummary,
    PdfA4Page,
    PdfPageHeader,
    PdfAconLogo,
    PdfGeneralInfo,
    PdfChip,
    Heizkreise,
    Waermeverteilung,
    PageCounter,
    PageCounterStart,
    PageCounterEnd
  },
  computed: {
    umsetzungsfrist(){
      let anzahl = 0;
      if( this.immobilie.wohneinheiten !== undefined){
        anzahl = this.immobilie.wohneinheiten;
      }

      if(anzahl < 6){
        return "-";
      }else if(anzahl >= 6 && anzahl < 10){
        return "15.09.2024";
      }else if(anzahl >= 10){
        return "30.09.2024";
      }

      return "(-)";
    }
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props: any) {
    
    const { t } = useI18n({ useScope: "global" });

    const useHzbaPdf = useEnsimimavPdfReport(props.lang);
    const uUser = useUser();


    return {
      getPhotoUrl,
      appointments: useHzbaPdf.termine,
      moment,
      tableOfContentFragenblocks: useHzbaPdf.tableOfContentFragenblocks,
      detailPages: useHzbaPdf.detailPages,
      // customInputs: useHzbaPdf.customInputs,
      mangelInputs: useHzbaPdf.mangelInputs,
      mangelInputsEnsimimav: useHzbaPdf.mangelInputsEnsimimav,
      aufmassInputsEnsimimav: useHzbaPdf.aufmassInputsEnsimimav,
      user: uUser.user,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,
      t
    };
  },
});
