
import { useI18n } from 'vue-i18n';
import {
  IonButton,
  IonPage,
  IonIcon, isPlatform,
} from "@ionic/vue";
import { ref, computed, defineComponent, nextTick } from "vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import AButton from "@/components/Base/AButton.vue";
import Vue3Html2Pdf from "vue3-html2pdf";
import { useProperties } from "@/composables/Property/useProperties";

import {
  arrowBack,
} from 'ionicons/icons';
import { useStore } from "@/composables/useTypedStore";
import html2pdf from "html2pdf.js";
import { logger } from "@/utilities/logging";


export default defineComponent({
  name: "PdfViewer",
  components: {
    AButton,
    Toolbar,
    IonPage,
    IonIcon,
    IonButton,
    Vue3Html2Pdf,
  },
  props: {
    downloadName: {
      type: String,
      required: true
    },
    goBackUrl: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: true
    },
    isUploadMode: {
      type: Boolean,
      default: false
    },
    lang: {
      type: String,
      default: 'de'
    },
    immobilieId: {
      type: Number,
      default: undefined
    },
  },
  emits: ['pdfAsBlob', 'downloadCompleted'],
  setup(props, { emit }) {
    const { t, locale } = useI18n({ useScope: 'global' })
    const pdfPreview = ref();
    const store = useStore();
    const isPropertyUpdated = ref(false);
    const isDownloading = ref(false);

    const hzba = computed(() => {
      return store.state.currentHzba.currentHzba;
    });


    const isMobile = computed(() => {
      return (isPlatform("ios") || isPlatform("ipad") || isPlatform("android"));
    });

    const controlValue = {
      showLayout: false,
      floatLayout: props.show,
      enableDownload: !props.isUploadMode,
      previewModal: false,
      paginateElementsByHeight: 1100,
      manualPagination: false,
      filename: props.downloadName,
      pdfQuality: 2,
      pdfFormat: 'a4',
      pdfOrientation: 'portrait',
      pdfContentWidth: '800px',
      jsPdf: {
        putOnlyUsedFonts: true,
      }
    }

    const htmlToPdfOptions = computed(() => {
      return {
        margin: 0,
        filename: controlValue.filename,
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: controlValue.pdfFormat,
          orientation: controlValue.pdfOrientation,
        },
      }
    }
    );
    updateBa();
    function updateBa() {
      const { fetchFullProperty } = useProperties();
      fetchFullProperty(props.immobilieId as number).then(() => {
        isPropertyUpdated.value = true;
      });
    }
    /**
     * Works only on browser and android.
     * Creates a pdf using the "normal" function for it instead of the ios workaround below.
     * Triggered from Vue3Html2Pdf template.
     */
    async function beforeDownload({ html2pdf, options, pdfContent }: { html2pdf: any; options: any; pdfContent: any }) {
      try {
        console.log("html2pdf start")
        html2pdf().from(pdfContent).set(options).toPdf().output('bloburl').then(async function (blobUrl: any) {
          const response = await fetch(blobUrl);
          const blob: Blob = await response.blob();
          emit('pdfAsBlob', blob)

          console.log("html2pdf end")
        })
      } catch (err) {
        console.error('Error transforming html 2 pdf', err)

        logger.error(`Error converting html for BA ${hzba.value.id} to pd: ${err}`);
      }
    }

    /**
     * Workaround for ios.
     * Creates pdf page per page, takes way longer (30sec/1 min)
     */
    const generatePdfForIos = () => {

      const elements = Array.from(document.querySelectorAll(`.pdf-container-page-${props.lang}`))
      const options = htmlToPdfOptions.value;

      let worker = html2pdf().set(options).from(elements[0])

      if (elements.length === 0) {
        console.error(`Could not find any '.pdf-container-page-${props.lang} in template. Process will stuck tho.`);
        logger.error(`generatePdfForIos for BA ${hzba.value.id} failed. Could not find any '.pdf-container-page-${props.lang} in template.`);
      }

      if (elements.length > 1) {
        worker = worker.toPdf() // worker is now a jsPDF instance

        // add each element/page individually to the PDF render process
        elements.slice(1).forEach((element: any, index: number) => {
          worker = worker
            .get('pdf')
            .then((pdf: any) => { pdf.addPage() })
            .from(element)
            .toContainer()
            .toCanvas()
            .toPdf()
            .output('bloburl')

          console.log("Worker foreach page", worker);
        })
      }

      worker.output('bloburl').then(async function (blobUrl: any) {
        const response = await fetch(blobUrl);
        const blob: Blob = await response.blob();
        emit('pdfAsBlob', blob)

        if (!props.isUploadMode) {
          // todo download or share in this case.
          // But first we have to debug the problem that the app is reloading when the pdf is generated.
        }
        console.log("Worker BLOB url", blobUrl)

        //to download pdf:
        // worker.save();

      })
    }

    async function downloadPdf() {
      isDownloading.value = true;
      locale.value = locale.value === 'de' ? 'en' : 'de';

      await nextTick();
      setTimeout(async () => {
        const workaroundNeeded = isPlatform("ios") || isPlatform("ipad");

        if (workaroundNeeded) {
          await generatePdfForIos()
        } else {
          await pdfPreview.value.generatePdf();
        }

        isDownloading.value = false;
      }, 100)
    }

    function afterDownload() {
      console.log("hook called afterDownload");
      logger.info(`PDF for BA ${hzba.value.id} downloaded sucessfully`);
      emit('downloadCompleted');
    }


    return {
      pdfPreview,
      objectCountHeight: 62,
      controlValue,
      htmlToPdfOptions,
      downloadPdf,
      beforeDownload,
      arrowBack,
      afterDownload,
      isMobile,
      isDownloading,
      t,
      isPropertyUpdated
    };
  },
});
