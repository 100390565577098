
import { useI18n } from "vue-i18n";
import { computed, defineComponent, PropType, watch, ref } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonLabel,
  IonBadge,
  IonIcon,
  IonChip,
} from "@ionic/vue";
import {
  alertCircleOutline,
  checkmarkCircle,
  ellipseOutline,
  ellipsisHorizontalCircle,
  cloudOfflineOutline,
  calendarOutline,
  timeOutline,
  locationOutline,
} from "ionicons/icons";
import { getPhotoUrl } from "@/utilities/get-media-url";
import Immobilie from "@/models/immobilie.model";
import { openBaseModal } from "@/utilities/modal-helper";
import AImage from "@/components/Base/AImage.vue";
import moment from "moment";
import useUser from "@/composables/useUser";
import { useRouter } from "vue-router";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
  HzbaStatus,
  translatedHzbaStatus,
} from "@/models/ba/interfaces/IBestandsaufnahme";
import CloudOfflineIcon from "@/components/Base/CloudOfflineIcon.vue";
import {logger} from "@/utilities/logging";
import AFlatButton from "@/components/Base/AFlatButton.vue";

export default defineComponent({
  name: "HzbaCard",
  components: {
    AFlatButton,
    CloudOfflineIcon,
    PdfChip,
    AImage,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonIcon,
    IonLabel,
    IonBadge,
    IonChip,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const user = useUser();
    const router = useRouter();
    const immobilie = computed(() => {
      return Immobilie.getters('getPropOrFallbackProp')(props.ba.immobilie);
    });
    const ba = ref(props.ba);
    watch(ba, () => {
      console.log('+++ ba changed +++,', props.ba);
    })
    const openBericht = (e: any, pdf: any) => {
      console.log('event from openBericht: ', e);
      e.stopImmediatePropagation();
      openBaseModal("pdf.view", { pdf: pdf });
    };

    const openMaengel = (e: any) => {
      e.stopImmediatePropagation();
      openBaseModal("maengel.uebersicht", { ba: props.ba });
    };

    const relevantMaengellist = computed(() => {
      return props.ba.mangellists && props.ba.mangellists.filter((el: any) => el.eingabeRelevant);
    })

    const openMaengelInImmobilie = (e: any) => {
      console.log('event from openMaengelInImmobilie: ', e);
      e.stopImmediatePropagation();
      openBaseModal("maengel.immobilie.uebersicht", { ba: props.ba });
    };

    const recommendedBericht = (ba: any) => {
      if (ba.berichts && ba.berichts.length > 0) {
        const bericht = ba.berichts.find((el: any) =>
          el.kennzeichen.endsWith("-de")
        );
        return bericht && bericht.data;
      }
      return ba.bericht;
    };

    const recommendedMangelliste = (ba: any) => {
      if (ba.mangellists && ba.mangellists.length > 0) {
        // console.log('ba.mangellists: ', ba.mangellists[0].mangelvorlage.kategorie);
      }
      return ba.mangellists;
    };

    const goToBa = async (e: any) => {
      e.stopImmediatePropagation();
      if (!user.isImmobilienverwalter()) {
        await router.push("/ba/" + props.ba.id + "/uebersicht-tab");
        logger.defaultMeta.currentBa = props.ba.id
        logger.info(`User opened BA ${props.ba.id}`);
      }
    };

    return {
      immobilie,
      getPhotoUrl,
      openBericht,
      alertCircleOutline,
      checkmarkCircle,
      relevantMaengellist,
      ellipseOutline,
      ellipsisHorizontalCircle,
      cloudOfflineOutline,
      HzbaStatus,
      calendarOutline,
      moment,
      locationOutline,
      timeOutline,
      recommendedBericht,
      recommendedMangelliste,
      translatedHzbaStatus,
      t,
      openMaengel,
      openMaengelInImmobilie,
      goToBa,
      user
    };
  },
});
