import {ref} from "vue";

export default function useEnvVar() {


    const getVar =  (name: String) => {
      // @ts-ignore
      return process.env[name];
    }


    return {
      getVar,
    }

}