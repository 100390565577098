import {toastController} from "@ionic/vue";
import {useI18n} from "vue-i18n";

export default function useToasts() {

    const { t } = useI18n({ useScope: 'global' });

    async function changesSavedLocally() {

        const toast = await toastController
            .create({
                message: t('toasts.savedChangesLocally'),
                duration: 2000
            })
        return toast.present();
    }

    return {
        changesSavedLocally,
    }
}