
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { IonButton } from "@ionic/vue";
import SignatureField from "@/components/Signature/SignatureField";
import { APhoto } from "@/models/photo/a-photo.model";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";

export default defineComponent({
  name: "SignatureWrapper",
  components: { SignatureField, IonButton },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSignaturesComplete"],
  setup(props, { emit }) {
    onMounted(() => {
      if (!props.ba.unterschrifts || props.ba.unterschrifts?.length === 0) {
        props.ba.unterschrifts = [{ name: "", bild: undefined }];
      }
    });

    const signaturesComplete = computed(() => {
      const counted = props.ba.unterschrifts?.filter(
        (signature) =>
          signature.bild && signature.name && signature.name.length > 0
      ).length;
      return counted === props.ba.unterschrifts?.length;
    });

    emit("onSignaturesComplete", signaturesComplete.value);

    watch(signaturesComplete, (newVal) => {
      emit("onSignaturesComplete", newVal);
    });

    function addSignature() {
      props.ba.unterschrifts?.push({ name: "", bild: undefined });
    }

    function deleteSignature(index: any) {
      props.ba.unterschrifts?.splice(index, 1);
    }

    const updateSignature = async (signature: any, signaturePhoto: APhoto) => {
      signature.bild = signaturePhoto;
    };

    return {
      updateSignature,
      signaturesComplete,
      addSignature,
      deleteSignature,
    };
  },
});
