
import PdfChip from "@/components/Pdf/Components/PdfChip";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues";
import { defineComponent, PropType } from "vue";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
export default defineComponent({
  name: "PdfDataSectionSummary",
  components: { PdfMSEValues, PdfChip },
  props: {
    data: {
      type: Object as PropType<ExtendedMSERow>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
