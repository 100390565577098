import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-041178a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container absolute bottom-12",
  "data-cy": "section-summary"
}
const _hoisted_2 = { class: "section-summary py-0 flex flex-row justify-between" }
const _hoisted_3 = { class: "m-1" }
const _hoisted_4 = { class: "my-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfMSEValues = _resolveComponent("PdfMSEValues")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, "Gesamtergebnis " + _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", {
          class: _normalizeClass([[
            _ctx.data.description1?.color === 'red' ? 'red' : '',
            _ctx.data.description1?.color === 'yellow' ? 'yellow' : '',
            _ctx.data.description1?.color === 'blue' ? 'blue' : '',
          ], "text-xs m-1"])
        }, _toDisplayString(_ctx.data?.description1?.value), 3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PdfMSEValues, { data: _ctx.data }, null, 8, ["data"])
      ])
    ])
  ]))
}