

import {
  IonPage,
  IonLabel,
  IonButtons,
  IonIcon,
  IonButton,
  IonContent,
  onIonViewWillEnter,
    onIonViewWillLeave,
  IonSegmentButton,
  IonSegment,
  IonBackButton,
  loadingController,
} from "@ionic/vue";
import { useI18n } from 'vue-i18n';
import { arrowBack, close } from "ionicons/icons";
import { useStore } from "@/composables/useTypedStore";
import { ref, computed, defineComponent } from "vue";
import Toolbar from "@/components/Navigation/Toolbar";
import ProgressIndicator from "@/components/ProgressIndicator";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import { Network } from "@capacitor/network";
import { useRouter } from "vue-router";
import AButton from "@/components/Base/AButton";
import Tabs from "@/components/hzba/BaTabs.vue"
import HzbaSummary from "@/components/hzba/Views/BaSummary.vue";
import HzbaMainView from "@/components/hzba/Views/HzbaMainView";
import PdfPreview from "@/pages/hzba/_id/PdfPreview";
import useBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSubpage";
import {HzbaStatus, translatedHzbaStatus} from "@/models/ba/interfaces/IBestandsaufnahme";
import getLocalization from "@/utilities/get-localization";
import Skeleton from "@/components/Skeleton.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";

export default defineComponent({
  name: "ViewHZBA",
  components: {
    Skeleton,
    PdfPreview,
    HzbaMainView,
    HzbaSummary,
    Tabs,
    IonIcon,
    AButton,
    HzbaGroup,
    ProgressIndicator,
    Toolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonLabel,
    IonContent,
    IonSegment,
    IonSegmentButton
  },
  setup(_, params) {

    const { t } = useI18n({ useScope: 'global' })

    const store = useStore();
    const router = useRouter();
    const lastRoute = ref()

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const { ba, immobilie, baSubpageName: baSubpage, localSubpage } = useBestandsaufnahmeSubpage({onIonViewWillEnter, onIonViewWillLeave });

    const showSummary = () => {
      lastRoute.value = baSubpage.value;
      // router.replace(`/ba/${ba.value!.id}/summary`)
      history.replaceState({}, 'title', `/ba/${ba.value!.id}/summary`)
      localSubpage.value = 'summary';
    }

    const goBackFromSummary = () => {

      const rec = store.state.app.recentRoute;

      // console.log("goBackFromSummary", rec, baSubpage);
      if (baSubpage.value === "summary") {
        if (lastRoute.value) {
          changeTab(lastRoute.value)
          // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
        } else {
          changeTab('')
          // router.replace(`/ba/${ba.value!.id}`)
        }
      } else if (rec.name === 'bas') {
        router.go(-1);
      } else {
        router.replace("/bas");
      }
      // router.replace(`/ba/${ba.value!.id}/${lastRoute.value}`)
    }

    const changeTab = (val?: string) => {
      if (val) {
        history.replaceState({}, 'title', `/ba/${ba.value!.id}/${val}`)
      } else {
        history.replaceState({}, 'title', `/ba/${ba.value!.id}`)
      }

      localSubpage.value = val;
    }

    const networkStatus = ref();

    Network.addListener("networkStatusChange", (status) => {
      console.log('network status: ', status, 'connection type: ', status.connectionType);
      networkStatus.value = status.connectionType;
    });

    const closeHzba = () => {
      router.replace(`/bas`)
    }

    return {
      showSummary,
      baSubpage,
      immobilie,
      objectCountHeight: 62,
      isReadonly,
      ba,
      HzbaStatus,
      closeHzba,
      arrowBack,
      close,
      goBackFromSummary,
      translatedHzbaStatus,
      t,
      getLocalization,
      changeTab,
      networkStatus
    };
  },
});
