<template>
  <span v-if="displayCounter">
    {{ pageCounter }}
  </span>
  <span v-if="displayMaxPages">
    / {{ maxPages }}
  </span>
</template>

<script setup>

import { ref } from "vue";

const props = defineProps({
  value: {
    type: [String, Number],
    default: undefined,
  },
  displayCounter: {
    type: Boolean,
    default: true
  },
  displayMaxPages: {
    type: Boolean,
    default: true
  }
});


if(window.maxPdfPages == undefined){
  window.maxPdfPages = 0;
}

const num = window.maxPdfPages + 1;
window.maxPdfPages = num;

const maxPages = ref(num);
const pageCounter = maxPages.value;

function updateMaxPages(){
  
  maxPages.value = window.maxPdfPages;
  console.log(maxPages);

}

console.log("Setup called");
console.log(maxPages);

window.pdfPageCounters.push(updateMaxPages);


</script>

<style scoped lang="scss">
</style>