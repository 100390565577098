import { APhoto } from '@/models/photo/a-photo.model';
import StrapiMedia from '@/models/photo/strapi-media.model';
import ErweitertesMedium from '@/models/erweitertes-medium.model';
import Unterschrift from '@/models/hzba/unterschrift.model';
import User from '@/models/user';
import { FragenblockJson } from '@/models/ba/interfaces/IFragenblock';
import Localization from '@/models/localization.model';

export interface BestandsaufnahmeFlatJson {
  id: number;
  name: Localization;
  nameMehrzahl: Localization;
  nameBericht: Localization;
  identifier: string;
  bilder: APhoto[];
  malus: string;
  malusColor: string;
  kommentar: string;
  begehungsdatum: string;
  berichts: ErweitertesMedium[];
  mangellists: any;
  archive: StrapiMedia;
  matterportLink?: string;
  immobilie: number;
  status: HzbaStatusKey;
  gutachter: any;
  isLocal: boolean;
  hasLocalImages: boolean;
  isDownloaded: boolean;
  unterschrifts?: Unterschrift[];
  auftraggeber: string;
  technProjektsteuerungName: string;
  reihenfolge:string;
  technProjektsteuerungUnterschrift: APhoto[];
  freigabeDatum: string;
  fazit: string;
  createdBy: string;
  updatedBy?: User;
  createdAt: string;
  updatedAt: string;
  bearbeitenderNutzer?: User;
  bearbeitendesGeraetId?: string;
}

export interface BestandsaufnahmeJson extends BestandsaufnahmeFlatJson {
  fragenblocks?: FragenblockJson[];
}

export enum HzbaStatus {
  ANGELEGT = 'Angelegt',
  GEPLANT = 'Geplant',
  IN_DURCHFUEHRUNG = 'In Durchführung',
  ABGESCHLOSSEN = 'Abgeschlossen',
  FREIGEGEBEN = 'Freigegeben',
}

export const translatedHzbaStatus = (key: any, t: any) => {
  switch (key) {
    case 'ANGELEGT':
      return t('hzba.vorschau.statusAngelegt');
    case 'GEPLANT':
      return t('hzba.vorschau.statusGeplant');
    case 'IN_DURCHFUEHRUNG':
      return t('hzba.vorschau.statusInDurchführung');
    case 'ABGESCHLOSSEN':
      return t('hzba.vorschau.statusAbgeschlossen');
    case 'FREIGEGEBEN':
      return t('hzba.vorschau.statusFreigegeben');
  }
};

export type HzbaStatusKey = keyof typeof HzbaStatus;
export const HzbaStatusKeys = new Set(Object.keys(HzbaStatus));
