import { strapiApiUrl, strapiUrl } from "@/api/api";
import { APhoto } from "@/models/photo/a-photo.model";
import CachedPhoto from "@/models/photo/cached-photo.model";
import StrapiMedia, { instanceOfStrapiImage } from "@/models/photo/strapi-media.model";

export function instanceOfPhoto(object: any): object is CachedPhoto {
    if (object) {
        return 'webPath' in object;
    } else {
        return false;
    }
}

export function isStrapiPhoto(object: any): boolean {
    if (object) {
        return object.url && object.id;
        // return object.url && object.mime.split("/")[0] === "image";
    } else {
        return false;
    }
}

export const getPhotoUrl = (photo: APhoto, options?: any) => {
    if (instanceOfPhoto(photo)) {
        return (photo as CachedPhoto).webPath;
    } else if (instanceOfStrapiImage(photo)) {
        const p = (photo as StrapiMedia);
        if (options?.thumbnail && p.formats?.thumbnail) {
            return strapiUrl + (photo as StrapiMedia).formats.thumbnail.url
        }

        if (options?.small && p.formats?.small) {
            return strapiUrl + (photo as StrapiMedia).formats?.small?.url
        }

        return strapiUrl + (photo as StrapiMedia).url;
    }
};

export const getPdfUrl = (pdf: APhoto): string | undefined => {
    // if (pdf.mime !== "application/pdf") {
    //     console.error("getPdfUrl: strapi media is not a pdf", pdf)
    //     throw "Not a PDF";
    // }

    if (isStrapiPhoto(pdf)) {
        const p = pdf as StrapiMedia;
        return strapiUrl + p.url;
    }

    const p = pdf as CachedPhoto;
    return p.webPath;
}