
import { useI18n } from 'vue-i18n';
import {  IonPage } from '@ionic/vue';
import {defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
  name: 'WelcomeAnimation',
  components: {
    IonPage
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const headerText = ref(t("einloggen.360Inventory"));
    const descText = ref(t("einloggen.anlagentechnikTitel"));
    onMounted(async () => {
      // setTimeout(() => {
      //   headerText.value = t("einloggen.willkommenTitel");
      //   descText.value = '';
      // }, 3000);
      // setTimeout(() => {
      //   emit('done')
      // }, 5000);
    });
    return { headerText, descText, t };
  },
})
