import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65593114"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "mx-4"
}
const _hoisted_4 = { class: "m-4" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "flex-1" }
const _hoisted_8 = { class: "m-4 block md:flex" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_progress = _resolveComponent("status-progress")!
  const _component_hzba_group = _resolveComponent("hzba-group")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_HzbaBaseInput = _resolveComponent("HzbaBaseInput")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_progress_indicator = _resolveComponent("progress-indicator")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_PdfTemplates = _resolveComponent("PdfTemplates")!
  const _component_PdfPreviewerAndCreator = _resolveComponent("PdfPreviewerAndCreator")!

  return (_ctx.ba)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_hzba_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_status_progress, {
              "current-status": _ctx.ba?.status,
              onSaveHzba: _ctx.syncBestandsaufnahme
            }, null, 8, ["current-status", "onSaveHzba"])
          ]),
          _: 1
        }),
        (
        !_ctx.ba.isDownloaded &&
        (_ctx.ba.isLockableByGutachter() || !_ctx.user.isGutachter()) &&
        _ctx.ba?.status !== 'ABGESCHLOSSEN' &&
        !_ctx.ba.bearbeitendesGeraetId
      )
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 0,
              "data-cy": "offline",
              "hide-progress": "",
              title: _ctx.t('hzba.uebersicht.activateEditMode')
            }, {
              cta: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  shape: "round",
                  expand: _ctx.isMaxSmall ? 'full' : 'block',
                  class: _normalizeClass(_ctx.isMaxSmall ? 'mx-4' : ''),
                  onClick: _cache[0] || (_cache[0] = () => _ctx.lockAndEditBaAlert(_ctx.ba, _ctx.t, true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("hzba.uebersicht.bearbeiten")), 1)
                  ]),
                  _: 1
                }, 8, ["expand", "class"])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.ba.isDownloaded)
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 1,
              "data-cy": "offline",
              title: _ctx.t('hzba.uebersicht.dismissLocalChanges'),
              "hide-progress": ""
            }, {
              cta: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  shape: "round",
                  expand: _ctx.isMaxSmall ? 'full' : 'block',
                  class: _normalizeClass(_ctx.isMaxSmall ? 'mx-4' : ''),
                  fill: "flat",
                  onClick: _cache[1] || (_cache[1] = () => _ctx.unlockBaAndDeleteLocalDataAlert(_ctx.ba, _ctx.t))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("hzba.uebersicht.loeschen")), 1)
                  ]),
                  _: 1
                }, 8, ["expand", "class"])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.immobilie)
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 2,
              title: _ctx.t('hzba.uebersicht.titel'),
              "hide-progress": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_HzbaBaseInput, {
                  "model-value": _ctx.immobilie?.externeObjektNr,
                  label: _ctx.t('hzba.objektdaten.objektnummerTitel'),
                  "hide-unit-space": "",
                  readonly: "",
                  "flat-mode": ""
                }, null, 8, ["model-value", "label"]),
                _createVNode(_component_HzbaBaseInput, {
                  "model-value": _ctx.immobilie?.strasse,
                  label: _ctx.t('hzba.objektdaten.straßeTitel'),
                  "hide-unit-space": "",
                  readonly: "",
                  "flat-mode": ""
                }, null, 8, ["model-value", "label"]),
                _createVNode(_component_HzbaBaseInput, {
                  "model-value": _ctx.translatedHzbaStatus(_ctx.ba?.status, _ctx.t),
                  label: "Status",
                  "hide-unit-space": "",
                  readonly: ""
                }, null, 8, ["model-value"]),
                _createVNode(_component_HzbaBaseInput, {
                  label: _ctx.t('hzba.uebersicht.durchgefuehrtVon'),
                  "hide-unit-space": "",
                  readonly: "",
                  "flat-mode": "",
                  "model-value": _ctx.ba?.bearbeitenderNutzer?.username
                }, null, 8, ["label", "model-value"]),
                _createVNode(_component_HzbaBaseInput, {
                  label: _ctx.t('hzba.uebersicht.zugeordneterGutachter'),
                  "hide-unit-space": "",
                  readonly: "",
                  "flat-mode": "",
                  "model-value": _ctx.ba?.gutachter?.username
                }, null, 8, ["label", "model-value"])
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        _createVNode(_component_hzba_group, {
          title: _ctx.t('hzba.vorschau.fortschrittTitel'),
          "hide-progress": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_HzbaBaseInput, {
              label: _ctx.t('hzba.vorschau.felderAusgefuellt'),
              "model-value": `${_ctx.totalProgress}%`,
              "hide-unit-space": "",
              readonly: "",
              "flat-mode": ""
            }, null, 8, ["label", "model-value"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_hzba_group, {
          title: _ctx.t('hzba.vorschau.maengelTitel'),
          "hide-progress": ""
        }, {
          cta: _withCtx(() => [
            (!_ctx.isMaxSmall)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "ghost-button",
                  disabled: _ctx.maengelReport?.countedMaengel() === 0,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openBaseModal('maengel.uebersicht', { ba: _ctx.ba })))
                }, _toDisplayString(_ctx.maengelReport?.countedMaengel() === 0
              ? _ctx.t("hzba.buttons.keineMaengel")
              : _ctx.t("hzba.buttons.alleAnzeigen") +
                ` (${_ctx.maengelReport?.countedMaengel()})`), 9, _hoisted_2))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_HzbaBaseInput, {
              label: _ctx.t('hzba.vorschau.maengelErkannt'),
              "model-value": _ctx.maengelReport?.countedMaengel(),
              "hide-unit-space": "",
              readonly: "",
              "flat-mode": ""
            }, null, 8, ["label", "model-value"]),
            _createVNode(_component_HzbaBaseInput, {
              label: _ctx.t('hzba.vorschau.relevanterMalusWert'),
              "model-value": _ctx.maengelReport?.getCalculatedRelevantMalus(),
              "hide-unit-space": "",
              readonly: "",
              "flat-mode": ""
            }, null, 8, ["label", "model-value"]),
            _createVNode(_component_HzbaBaseInput, {
              label: _ctx.t('hzba.vorschau.nichtRelevanterMalusWert'),
              "model-value": _ctx.maengelReport?.getCalculatedNotRelevantMalus(),
              "hide-unit-space": "",
              readonly: "",
              "flat-mode": ""
            }, null, 8, ["label", "model-value"]),
            _createVNode(_component_HzbaBaseInput, {
              label: _ctx.t('hzba.vorschau.summeMalusWert'),
              "model-value": _ctx.maengelReport?.getCalculatedMalus(),
              "hide-unit-space": "",
              readonly: "",
              "flat-mode": ""
            }, null, 8, ["label", "model-value"]),
            (_ctx.isMaxSmall)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  shape: "round",
                  class: "mt-4 mx-4",
                  "data-cy": "button-alleMaengelAnzeigen",
                  expand: "full",
                  fill: "flat",
                  disabled: _ctx.maengelReport?.countedMaengel() === 0,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openBaseModal('maengel.uebersicht', { ba: _ctx.ba })))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.maengelReport?.countedMaengel() === 0
            ? _ctx.t("hzba.buttons.keineMaengel")
            : _ctx.t("hzba.buttons.alleAnzeigen") +
              ` (${_ctx.maengelReport?.countedMaengel()})`), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["title"]),
        (_ctx.ba?.isInProgress())
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 3,
              title: _ctx.t('hzba.vorschau.pdfVorschauTitel'),
              "hide-progress": ""
            }, {
              cta: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  disabled: !_ctx.ba?.hasPdfSupport(),
                  shape: "round",
                  fill: "flat",
                  expand: _ctx.isMaxSmall ? 'full' : 'block',
                  class: _normalizeClass(_ctx.isMaxSmall ? 'mx-4' : ''),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.replace(`/ba/${_ctx.ba?.id}/pdf-preview`)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.anzeigen")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "expand", "class"])
              ]),
              default: _withCtx(() => [
                (!_ctx.ba?.hasPdfSupport())
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.t("hzba.pdf.pdfErstellungNichtSupported")), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.isReadonly)
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 4,
              title: _ctx.t('hzba.vorschau.pdfAnzeigenTitel'),
              "data-cy": "group-pdf-report",
              "hide-progress": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list, { class: "bg-white boxed-container m-4 p-0" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ba?.berichts, (doc, key) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: key,
                        class: "cursor-pointer",
                        "data-cy": "button-previewPdf",
                        onClick: ($event: any) => (_ctx.openBaseModal('pdf.view', { pdf: doc.data }))
                      }, {
                        default: _withCtx(() => [
                          (doc.kennzeichen && doc.kennzeichen.endsWith('-de'))
                            ? (_openBlock(), _createBlock(_component_ion_label, {
                                key: 0,
                                "data-cy": "pdf-report-item"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(doc.titel && doc.titel.de), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          (doc.kennzeichen && doc.kennzeichen.endsWith('-en'))
                            ? (_openBlock(), _createBlock(_component_ion_label, {
                                key: 1,
                                "data-cy": "pdf-report-item"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(doc.titel && doc.titel.en), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.chevronForward,
                            size: "large"
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true),
        (_ctx.ba?.archive)
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 5,
              "data-cy": "group-zip-report",
              title: "ZIP Heizungsbestandsaufnahme",
              "hide-progress": ""
            }, {
              cta: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  shape: "round",
                  href: _ctx.getPhotoUrl(_ctx.ba?.archive),
                  "data-cy": "btn-download-zip-report"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.downloaden")), 1)
                  ]),
                  _: 1
                }, 8, ["href"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_hzba_group, {
          title: _ctx.t('hzba.vorschau.hzbaAbschliessen'),
          "hide-progress": ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_grid, { class: "p-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "flex-nowrap d-flex items-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        class: "flex-grow-0 mr-2",
                        "data-cy": "fortschritt"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_progress_indicator, {
                            progress: _ctx.totalProgress === 100 ? 100 : 0
                          }, null, 8, ["progress"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t("hzba.vorschau.felderAusgefuellt")), 1),
                      _createElementVNode("div", null, _toDisplayString(_ctx.totalProgress) + "%", 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, { class: "flex-nowrap d-flex items-center mt-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "flex-grow-0 mr-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_progress_indicator, {
                            progress: ((_ctx.ba && _ctx.ba.fazit?.length) ?? 0) > 0 ? 100 : 0
                          }, null, 8, ["progress"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t("hzba.vorschau.fazit")), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_button, {
                          "data-cy": "fazit-hinzufuegen",
                          shape: "round",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (
                  _ctx.openBaseModal('conclusion', {
                    parent: () => _ctx.ba,
                    maxFazitLength: 1000,
                  })
                ))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(!_ctx.isReadonly
                    ? _ctx.ba && _ctx.ba.fazit && _ctx.ba.fazit.length > 0
                      ? _ctx.t("hzba.buttons.aendern")
                      : _ctx.t("hzba.buttons.hinzufuegen")
                    : _ctx.t("hzba.buttons.anzeigen")), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, {
                    class: _normalizeClass(
              !_ctx.isMaxSmall
                ? 'flex-nowrap d-flex items-center mt-4'
                : 'flex items-center mt-4 justify-between'
            )
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "flex-grow-0 mr-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_progress_indicator, {
                            progress: (_ctx.ba.unterschrifts?.length ?? 0) > 0 ? 100 : 0
                          }, null, 8, ["progress"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t("hzba.vorschau.unterschrift")), 1),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_button, {
                          class: _normalizeClass(_ctx.isMaxSmall ? 'flex order-last' : ''),
                          "data-cy": "unterschreiben",
                          shape: "round",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openBaseModal('signandsubmit', { ba: _ctx.ba })))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(!_ctx.isReadonly
                    ? _ctx.t("hzba.buttons.hinzufuegen")
                    : _ctx.t("hzba.buttons.anzeigen")), 1)
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ion_button, {
                shape: "round",
                disabled: !_ctx.isSubmittable,
                expand: _ctx.isMaxSmall ? 'full' : 'block',
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.finishHzba()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("hzba.buttons.abschliessen")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "expand"]),
              (_ctx.isSubmittable || _ctx.isUploading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uploadLanguages, (lang) => {
                      return (_openBlock(), _createBlock(_component_PdfPreviewerAndCreator, {
                        key: lang,
                        ref_for: true,
                        ref: (el) => _ctx.setupPdfViewerRef(el, lang),
                        lang: lang,
                        "download-name": `hzba-${lang}.pdf`,
                        "go-back-url": _ctx.ba && `/ba/${_ctx.ba.id}`,
                        show: false,
                        "is-upload-mode": true,
                        immobilieId: _ctx.ba.immobilie,
                        onPdfAsBlob: (blob) => _ctx.setGeneratedPdfFile(blob, lang)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PdfTemplates, {
                            ba: _ctx.ba,
                            immobilie: _ctx.immobilie,
                            lang: lang
                          }, null, 8, ["ba", "immobilie", "lang"])
                        ]),
                        _: 2
                      }, 1032, ["lang", "download-name", "go-back-url", "immobilieId", "onPdfAsBlob"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]))
    : _createCommentVNode("", true)
}