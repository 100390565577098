import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MangelItemTemplate = _resolveComponent("MangelItemTemplate")!

  return (_ctx.mangel.isFired(_ctx.frage.isShown()))
    ? (_openBlock(), _createBlock(_component_MangelItemTemplate, {
        key: 0,
        collapsed: _ctx.mangel.collapsed,
        "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mangel.collapsed) = $event)),
        mangel: _ctx.mangel,
        errors: _ctx.mangel.errors(_ctx.t),
        "is-readonly": _ctx.isReadonly,
        "mangel-config": _ctx.mangel.config
      }, null, 8, ["collapsed", "mangel", "errors", "is-readonly", "mangel-config"]))
    : _createCommentVNode("", true)
}