
import { defineComponent, PropType } from "vue";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButton,
  IonLabel,
  IonBadge,
  IonChip,
} from "@ionic/vue";
import HzbaCard from "./HzbaCard.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";

export default defineComponent({
  name: "HzbaList",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonLabel,
    IonBadge,
    IonChip,
    HzbaCard
  },
  props: {
    hzbas: {
      type: Object as PropType<Bestandsaufnahme[]>,
      required: true,
    },
    propertyView: {
      type: Boolean,
      default: false
    }
  },
});
