<template>
  <div :class="imageClass">

      
        <canvas 
          :id="id" 
          class="photo object-cover rounded-lg"
          :url="url"
          :style="setToMax? 'height: 100%; width: 100%' : 'cursor: pointer'"
          data-cy="immobilie-dokument-canvas"
        />
  </div>
</template>

<script>
import { onMounted} from "vue";
import "pdfjs-dist/web/pdf_viewer.css";
const pdfjsLib = require("pdfjs-dist/build/pdf");
import { openBaseModal } from "@/utilities/modal-helper";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import BaseModal from './BaseModal.vue';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: 'PdfThumbnail',
  components: { BaseModal },
  props: {
    imageClass: {
      type: String,
      default: 'w-full h-auto'
    },
    url: {
      type: String,
      default: ''
    },
    file: {
      type: Object,
      default: undefined
    },
    width: {
      type: Number,
      default: 210,
    },
    height: {
      type: Number,
      default: 297,
    },
    id: {
      type: String,
      default: undefined
    },
    setToMax: {
      type: Boolean,
      default: false
    }

  },
  setup(props) {
    const pdfThumbnail = async () => {
      pdfjsLib.getDocument(props.url).promise.then(async function(pdf) {

        const page = await pdf.getPage(1);
        const canvas = document.getElementById(props.id);
        if (canvas) {
          let viewport;
          if (props.setToMax) {
            viewport = page.getViewport({scale: 0.1});
            viewport = page.getViewport({scale: props.height / viewport.height});
            canvas.height = viewport.height;
            canvas.width = viewport.width;
          }
          else {
            viewport = page.getViewport({scale: 1});
            viewport = page.getViewport({scale: props.width / viewport.width});
            canvas.width = 210-1;
            canvas.height = 297;
          }
          const context = canvas.getContext('2d')
          page.render({ canvasContext: context, viewport: viewport })
        }
      })
    }

    onMounted(() => {
      pdfThumbnail();
    })

    return {
      openBaseModal,
    }
  }
}
</script>

<style>
</style>