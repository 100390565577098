
import { useI18n } from 'vue-i18n';
import {getPhotoUrl} from "@/utilities/get-media-url";
import moment from "moment";

import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import {computed, defineComponent, PropType} from "vue";
import { useStore } from "@/composables/useTypedStore";
import Immobilie from "@/models/immobilie.model";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import GenericPdfDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralData.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {Fragenblock} from "@/models/ba/Fragenblock";
import useUser from "@/composables/useUser";
import GenericPdfMgtSummaryTable from '../Components/GenericPdfMgtSummaryTable.vue';
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";



export default defineComponent({
  name: "PdfTechnikzentraleTemplate",
  components: {
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    GenericPdfDataTable,
    PdfColorCodes,
    PdfMSEValues, PdfImageRow, PdfDataSectionSummary, PdfA4Page, PdfPageHeader, PdfAconLogo,
    GenericPdfMgtSummaryTable,
    PdfChip,
},
  props: {
    hzba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true
    },
    // genericHzba: {
    //   type: Object as PropType<Bestandsaufnahme>,
    //   required: true
    // },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  setup(props: any) {
    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })
    const maxPages = 7;

    const uUser = useUser();
    const dummyData = {
    fragenblocks:[
      {
        titel:'Abwasser-Installation',
        malus:'5',
        malusColor:'green',
        frages:[{
        titel:"Entwässerung",
        value:"Mischentwässerung",
      },
      {
        titel:"Dämmung",
        value:"Ja",
      },
      {
        titel:"Leitungsmaterial",
        value:"SML, PP, HT ",
      },
      {
        titel:"Hebeanlage",
        value:"Fäkalienhebeanlage Doppelpumpe mit Pumpensumpf im U2",
      }]
      },
      {
        titel:'Technikraum',
        malus:'10',
        malusColor:'yellow',
        frages:[{
        titel:"Entwässerung",
        value:"Mischentwässerung",
      },
      {
        titel:"Dämmung",
        value:"Ja",
      },
      {
        titel:"Leitungsmaterial",
        value:"SML, PP, HT ",
      },
      {
        titel:"Hebeanlage",
        value:"Fäkalienhebeanlage Doppelpumpe mit Pumpensumpf im U2",
      }]
      },
      {
        titel:'Trinkwasser Installation',
        malus:'15',
        malusColor:'red',
        frages:[{
        titel:"Entwässerung",
        value:"Mischentwässerung",
      },
      {
        titel:"Dämmung",
        value:"Ja",
      },
      {
        titel:"Leitungsmaterial",
        value:"SML, PP, HT ",
      },
      {
        titel:"Hebeanlage",
        value:"Fäkalienhebeanlage Doppelpumpe mit Pumpensumpf im U2",
      }]
      }
    ]
    }


    // const hzba = new Bestandsaufnahme(useNewHzbaJson().data);
    // console.log('hzba mocked',hzba)

    const genericHzbaFromStore = computed<Bestandsaufnahme | undefined>(() => {
      console.log('store.state.currentHzba',store.state)
      return store.state.currentHzba.currentBa;
    });

    const fragenBlocks = computed<Fragenblock[] | undefined>(() => {
      console.log('typeof hzba.value?.fragenBlocks', genericHzbaFromStore.value?.fragenblocks)
      return genericHzbaFromStore.value?.fragenblocks
    })

    function techData(){
      if(!fragenBlocks.value) return
      const copyOfFragenBlocks = [...fragenBlocks.value]
      console.log('copyOfFragenBlocks',copyOfFragenBlocks)
      copyOfFragenBlocks.shift()
      console.log('techdatatest',techData)
    return copyOfFragenBlocks
    }


    return {
      // hzba,
      // immobilie,
      dummyData,
      getPhotoUrl,
      techData,
      fragenBlocks,
      maxPages,
      moment,
      user: uUser.user,
      genericHzbaFromStore,
      t
    }
  }
})
