import ViewHzba from '@/pages/hzba/_id/index.vue';
import PdfPreview from '@/pages/hzba/_id/PdfPreview.vue';
import HzbaMatterport from '@/pages/hzba/_id/matterport.vue';
export default [
    {
        path: '/ba/:baId/:subpage',
        name: 'hzbaViewSubpage',
        component: ViewHzba,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']},
    },
    {
        path: '/ba/:baId/pdf-preview',
        name: 'hzbaPdfPreview',
        component: PdfPreview,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']}
    },
    {
        path: '/ba/:baId/tags-3d',
        name: 'hzbaMatterportTags',
        component: HzbaMatterport,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']}
    },
    {
        path: '/ba/:baId',
        name: 'hzbaView',
        component: ViewHzba,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']},
    },
];