<template>
  <ion-page
    v-if="!animationDone"
    style="background: white"
  >
    <WelcomeAnimation @done="() => animationDone = true" />
  </ion-page>
  <ion-page
    v-else
    style="background: white"
  >
    <ion-content>
      <img
        src="../../public/assets/img/svg/acon_logo_colored.svg"
        alt="acon_icon"
        class="m-4 md:m-8 w-48 "
      >

      <div class="login m-4 lg:m-0">
        <div class="form_group">
          <h1 class="acon-red">
            {{ t("einloggen.loginTitel") }}
          </h1>
          <form
            novalidate
            @submit.prevent="onSubmit"
          >
            <AInput
              v-model="loginForm.emailAddress.$model"
              data-cy="email"
              :errors="loginForm.emailAddress.$errors"
              :label="t('registrierung.email')"
              :placeholder="'mac@acon-energie.de'"
              input-type="email"
            />

            <AInput
              v-model="loginForm.password.$model"
              :errors="loginForm.password.$errors"
              class="mt-4"
              :label="t('registrierung.passwort')"
              :placeholder="'Testpasswort01!'"
              input-type="password"
              data-cy="passwort"
            />

            <AButton
              type="submit"
              data-cy="button-login"
              style="margin-top: 32px !important; margin-left: 0"
              blue-solid
              :disabled="loading"
            >
              <span v-if="!loading">{{t("buttons.login") }}</span>
              <ion-spinner v-if="loading" color="light" size="small"></ion-spinner>
            </AButton>

             <p>{{ t("einloggen.nurFuerRegistrierteNutzerInfo") }}</p>

            <!-- <router-link
              to="/signup"
              style="text-decoration: none"
            >
              <ion-button
                fill="clear"
                class="block"
                style="margin-top: 32px !important; margin-left: 0"
              >
                {{ t("einloggen.keinAccount") }}
              </ion-button>
            </router-link> -->

            <offline-hint>{{ t("alerts.keinInternet") }}</offline-hint>
          </form>
        </div>
      </div>

      <FooterComp />
    </ion-content>
  </ion-page>
</template>

<script>
  import { useI18n } from 'vue-i18n';
  import {
    alertController,
      IonSpinner,
    IonContent,
    IonPage,
    IonButton,
    onIonViewWillEnter, isPlatform,
  } from "@ionic/vue";
  import AInput from "@/components/Base/AInput";
  import {defineComponent, reactive, toRef, ref, watch} from "vue";
  import {email, helpers, required} from "@vuelidate/validators";
  import {useVuelidate} from "@vuelidate/core";
  import {useRouter} from "vue-router";
  import useUser from "@/composables/useUser";
  import useAuth from "@/composables/useAuth";
  import useAlert from "@/composables/useAlert";
  import AButton from "@/components/Base/AButton";
  import FooterComp from "@/components/FooterComp";
  import Toolbar from "@/components/Navigation/Toolbar";
  import { useStore } from "@/composables/useTypedStore";
  import WelcomeAnimation from "@/components/Other/WelcomeAnimation";
  import useFetchData from "@/composables/useFetchData";
  import OfflineHint from "@/components/Other/OfflineHint";
  import {logger} from "@/utilities/logging";

  export default defineComponent ({
    name: "Login",
    components: {
      OfflineHint,
      WelcomeAnimation,
      Toolbar,
      FooterComp,
      AButton,
      AInput,
      IonButton,
      IonPage,
      IonSpinner,
      IonContent
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' })
      const router = useRouter();
      const auth = useAuth();
      const alert = useAlert();
      const store = useStore();
      const animationDone = ref(!isPlatform('hybrid'));
      const useData = useFetchData();
      const loading = ref(false);

      const loginData = reactive({
        emailAddress: '',
        password: '',
        save: true,
      });

      const rules = {
        emailAddress: {
          required: helpers.withMessage('Email erforderlich', required),
          email: helpers.withMessage('Email ungültig', email),
        },
        password: {
          required: helpers.withMessage('Passwort erforderlich', required),
        },
        save: {},
      };

      const loginForm = useVuelidate(rules, {
        emailAddress: toRef(loginData, 'emailAddress'),
        password: toRef(loginData, 'password'),
        save: toRef(loginData, 'save'),
      });


      const onSubmit = async () => {
        loginForm.value.$touch();

        if (loginForm.value.$invalid) {
          console.log('Login form invalid:', loginForm.value);
          return;
        }

        try {
          loading.value = true;
          await auth.login(loginData.emailAddress, loginData.password)
          await useData.fetch();
          loading.value = false;
          await router.push({ path: '/' });
        } catch (err) {
          loading.value = false;
          if (err.status === 'Network Error') {
            await alert.show(t("alerts.keinInternetHead"),t("alerts.keinInternetText"));
          } else if (err.response?.status === 400) {
            await alert.show(t("alerts.loginFehlgeschlagenHead"), t("alerts.loginFehlgeschlagenText"));
            loginData.password = '';
          } else {
            await alert.show(t("alerts.unerwarteterFehlerHead"), t("alerts.unerwarteterFehlerText"));
            console.error('Login fehler', JSON.stringify(err))
            logger.error(`Login failed: ${JSON.stringify(err)}`);
          }
        }
      };

      const debugSubmit = async () => {
        loginData.emailAddress = 'mac@gisolutions.at';
        loginData.password = 'Testpasswort01!';
        await onSubmit();
      };

      onIonViewWillEnter(() => {
        store.commit('app/hideBottomNavigation')
      });

      watch(() => animationDone.value, (newValue, oldValue) => {
        store.commit('app/setWelcomeAnimationDone', newValue)
      }, { immediate: true })

      return {
        loginForm,
        loginData,
        onSubmit,
        debugSubmit,
        animationDone,
        t,
        loading
      }
    }
  })
</script>

<style scoped lang="scss">

ion-page {
  --background: white;
}

  ion-content {

    @media (max-width: 728px) {
      --padding-top: 24px !important;
      --padding-top: unquote('constant(safe-area-inset-top))') !important;
      --padding-top: env(safe-area-inset-top) !important;

    }

    --padding-top: unquote('constant(safe-area-inset-top))') !important;
    --padding-top: env(safe-area-inset-top) !important;

  }

  .login {
    min-height: 90vh;
    margin-top: -90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media (max-height: 650px) {
      margin-top: 0;
      min-height: 75vh;
    }
  }

  .form_group {
    top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

</style>