
import { openHzbaModal } from "@/utilities/modal-helper";
import {computed, PropType} from "vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup.vue";
import {useStore} from "@/composables/useTypedStore";
import HzbaModalItemBlock from "@/components/hzba/Base/Input/HzbaModalItemBlock.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput.vue";
import RecursiveForm from "@/components/hzba/RecursiveForm.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
export default {
  name: "HzbaMainView",
  components: {
    RecursiveForm,
    HzbaBaseInput,
    HzbaModalItemBlock, HzbaGroup
  },
  props: {
    routeName: {
      type: String,
      default: undefined
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true
    }
  },
  setup(props: any) {

    const section = computed(() => {
      const section = props.ba.findSectionByIdentifier(props.routeName);
      return section;
    })

    return {
      section,
      openHzbaModal,
    }
  }
}
