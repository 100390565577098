
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import PdfChip from "@/components/Pdf/Components/PdfChip";
import {defineComponent} from "vue";
export default defineComponent({
  name: "PdfDataTable",
  components: {PdfChip},

  props: {
          data: {
            type: Array,
            required: true
          },
          valueTitle: {
            type: String,
            default: undefined
          },
          name: {
            type: String,
            default: 'Position'
          },
          lang: {
            type: String,
            default: 'de'
          }
        },
        
  setup(props, { emit }) {
 
     const { t } = useI18n({ useScope: 'global' })
     const positionTranslated = computed(()=>{
     return props.name === 'Position'? t('hzba.pdf.position') : props.name
    })
      
    return {
        positionTranslated,
        t
      }
  },
  
})
