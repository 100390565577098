import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70d899ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex text-sm font-medium border" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { "data-cy": "generalData-objektNr" }
const _hoisted_4 = { "data-cy": "generalData-adresse" }
const _hoisted_5 = { "data-cy": "generalData-datum" }
const _hoisted_6 = { "data-cy": "generalData-auftraggeber" }
const _hoisted_7 = { "data-cy": "generalData-eigentuemer" }
const _hoisted_8 = { "data-cy": "generalData-energietraeger" }
const _hoisted_9 = { class: "ml-4" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(_ctx.t("hzba.pdf.objektnummer", 1, { locale: _ctx.lang })) + ":", 1),
        _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.immobilie.externeObjektNr), 1)
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(_ctx.t("hzba.pdf.objekt", 1, { locale: _ctx.lang })) + ":", 1),
        _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.immobilie.strasse) + ", " + _toDisplayString(_ctx.immobilie.plz) + " " + _toDisplayString(_ctx.immobilie.stadt), 1)
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(_ctx.t("hzba.pdf.datum", 1, { locale: _ctx.lang })) + ":", 1),
        _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.moment(_ctx.hzba.begehungsdatum).format("DD.MM.YYYY")), 1)
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(_ctx.t("hzba.pdf.auftraggeber", 1, { locale: _ctx.lang })) + ":", 1),
        _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.getVerwalterLabel()), 1)
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(_ctx.t("hzba.pdf.eigentuemer", 1, { locale: _ctx.lang })) + ":", 1),
        _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.immobilie?.eigentuemer), 1)
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("td", null, _toDisplayString(_ctx.t("hzba.pdf.energietraeger", 1, { locale: _ctx.lang })) + ":", 1),
        _createElementVNode("td", _hoisted_8, _toDisplayString(// To do -> noch wird wert angezeigt, nicht label
            _ctx.useIdentifierFrageFinder(
              _ctx.hzba,
              "hzba.uebersicht-tab.heizungsdaten.energietraeger"
            )?.getSelectedAuswahlLabelsString()), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("img", {
        src: _ctx.getPhotoUrl(_ctx.immobilie?.vorschaubild),
        style: {"max-height":"130px","max-width":"180px"},
        height: "130",
        width: "180",
        "data-cy": "generalData-vorschaubild"
      }, null, 8, _hoisted_10)
    ])
  ]))
}