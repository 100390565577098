import ManagementSummary from '@/pages/property/_id/PdfPreviewMgtSum.vue';
import Anlagenschema from '@/pages/property/_id/PdfPreviewAnlagenschema.vue';
import Technikzentrale from '@/pages/property/_id/PdfPreviewTechnikzentrale.vue';

export default [
    {
        path: '/property/:propertyId',
        name: 'property-detail',
        component: () => import('@/pages/property/_id/index.vue'),
    },
    {
        path: '/property/:propertyId/pdf-preview-mgt-summary',
        name: 'propertyPdfPreviewMgtSummary',
        component: ManagementSummary,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']}
    },
    {
        path: '/property/:propertyId/pdf-preview-anlagenschema',
        name: 'propertyPdfPreviewAnlagenschema',
        component: Anlagenschema,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']}
    },
    {
        path: '/property/:propertyId/pdf-preview-technikzentrale',
        name: 'propertyPdfPreviewTechnikzentrale',
        component: Technikzentrale,
        meta: { requiresLoggedIn: true, authorizedUserGroups: ['gutachter', 'kunde']}
    }
];