
import { useI18n } from 'vue-i18n';
import HzbaBaseModal from "@/components/Base/BaseModal";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import PhotoViewModal from "@/components/hzba/Base/HzbaPhotoViewModal.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import SignatureWrapper from "@/components/Signature/SignatureWrapper.vue";
import useAlert from "@/composables/useAlert";
import { IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "@/composables/useTypedStore";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";

export default defineComponent({
  name: "SignAndSubmitModal",
  components: {
    SignatureWrapper,
    HzbaChooseInput,
    HzbaPhotoInput,
    PhotoViewModal,
    IonButtons,
    IonButton,
    IonChip,
    Toolbar, HzbaBaseInput, HzbaGroup, HzbaBaseModal, SectionWrapper, IonIcon, IonContent, IonHeader },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  emits: ["close", "save"],
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();

    const unterschriftsBackup = JSON.parse(JSON.stringify(props.ba.unterschrifts));

    const isSignatureComplete = ref(false);
    const signatureWrapper = ref()

    // const numberOfSignatures = ref();

    // function onDeleteSignature(num: Number) {
    //   numberOfSignatures.value = num;
    // }

    function onSignaturesComplete(bool: boolean) {
      isSignatureComplete.value = bool;
    }

    const submitHzba = async () => {
      // props.hzba.status = "ABGESCHLOSSEN";
      store.dispatch('currentHzba/saveHzbaLocally');

      await modalController.dismiss();
    }

    const addSignature = () => {
      signatureWrapper.value.addSignature()
    }

    const close = async () => {
      props.ba.unterschrifts = unterschriftsBackup;
      await modalController.dismiss();
    };

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    return {
      addSignature,
      close,
      submitHzba,
      onSignaturesComplete,
      isSignatureComplete,
      // signatureComplete,
      signatureWrapper,
      isReadonly,
      arrowBack,
      t
    }
  },
});
