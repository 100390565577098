import {computed} from "vue";
import {store} from "@/store/store";

export const SCREEN_XL_START = 1400;
export const SCREEN_LG_START = 1024;
export const SCREEN_MEDIUM_START = 786;
export const SCREEN_SMALL_START = 486;

export default function useScreenSize() {

    const screenWidth = computed(() => {
        return store.state.app.screenWidth;
    });

    const screenHeight = computed(() => {
        return store.state.app.screenHeight;
    });

    function reportWindowSize() {
        store.commit("app/setScreenSize", {
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    /**
     * Call this function once, e.g. in App.vue
     */
    function mountMe() {
        reportWindowSize();
        window.addEventListener('resize', reportWindowSize);
    }

    function unmountMe() {
        window.removeEventListener('resize', reportWindowSize);
    }


    const currentWidthType = computed(() => {
        if (screenWidth.value >= 1400) {

            return 'xl';
        }
        if (screenWidth.value >= 1024) {
            return 'lg';
        }
        return 'tbd';
    })

    const screenTypes = {
        SCREEN_LG_START,
        SCREEN_XL_START,
        SCREEN_MEDIUM_START,
        SCREEN_SMALL_START
    }

    const isMinLarge = computed(() => {
        return screenWidth.value >= screenTypes.SCREEN_LG_START
    })

    const isMaxMedium = computed(() => {
        return screenWidth.value < screenTypes.SCREEN_LG_START
    })

    const isMaxSmall = computed(() => {
        return screenWidth.value < screenTypes.SCREEN_MEDIUM_START
    })
    const isMaxXSmall = computed(() => {
        return screenWidth.value < screenTypes.SCREEN_SMALL_START
    })



    return {
        mountMe,
        unmountMe,
        screenWidth,
        screenHeight,
        currentWidthType,
        screenTypes,
        isMinLarge,
        isMaxMedium,
        isMaxSmall,
        isMaxXSmall
    }

}