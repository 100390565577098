<template>
  <button
    :type="type"
    :class="[
      acon ? 'acon' : '',
      blueSolid ? 'blueSolid' : '',
      flatWhite ? 'flat-white' : ''
    ]"
    @click="() => $emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "AButton",
  props: {
    type: {
      type: String,
      default: 'Button'
    },
    acon: { type: Boolean, default: false},
    flatWhite: { type: Boolean, default: false},
    blueSolid: { type: Boolean, default: false}
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">

button.acon {
  margin-left: 1.5rem;
  margin-top: 1rem;
  border-radius: 100rem;
  padding: 1rem;
  max-width: 350px;
  font-size: 1rem;
  color: $aconRed;
  border: solid 2px transparent;
  background-image: $aconGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

button.acon:hover {
  box-shadow: none;
  color: white;
}

button.flat-white {
  background: white;
  padding: 16px 8px;
}

button.blueSolid {
  background: #145aff;
  color: white;
  font-weight: 550;
  padding: 12px 8px;
  border-radius: 36px;
  width: 100%;
  max-width: 350px;
}

</style>