import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d30161d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-1 w-full" }
const _hoisted_2 = { class: "text-left pl-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-left value-col"
}
const _hoisted_4 = { class: "numberCol" }
const _hoisted_5 = { class: "numberCol" }
const _hoisted_6 = { class: "numberCol" }
const _hoisted_7 = {
  style: {"min-width":"120px"},
  class: "pl-2"
}
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfChip = _resolveComponent("PdfChip")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.positionTranslated), 1),
      (_ctx.valueTitle !== undefined)
        ? (_openBlock(), _createElementBlock("th", _hoisted_3, _toDisplayString(_ctx.valueTitle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.t("hzba.pdf.maluswert", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.t("hzba.pdf.safety", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.t("hzba.pdf.effizienz", 1, { locale: _ctx.lang})), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (entry, key) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: key,
        class: "data-tr items-center"
      }, [
        _createElementVNode("td", _hoisted_7, _toDisplayString(entry.position), 1),
        (entry.value)
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: _normalizeClass(["value-col text-left", entry.value.color === 'red' ? 'red' : ''])
            }, _toDisplayString(entry.value.value), 3))
          : _createCommentVNode("", true),
        _createElementVNode("td", {
          class: _normalizeClass(["text-right", [
          entry.hint && entry.hint.color === 'red' ? 'red' : '',
          entry.hint && entry.hint.color === 'yellow' ? 'yellow' : '',
          entry.hint && entry.hint.color === 'green' ? 'green' : '',
        ]])
        }, _toDisplayString(entry.hint && entry.hint.value), 3),
        _createElementVNode("td", _hoisted_8, [
          (entry.malus)
            ? (_openBlock(), _createBlock(_component_PdfChip, {
                key: 0,
                value: entry.malus.value,
                color: entry.malus.color,
                "pdf-mode": ""
              }, null, 8, ["value", "color"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("td", _hoisted_9, [
          (entry.safety)
            ? (_openBlock(), _createBlock(_component_PdfChip, {
                key: 0,
                value: entry.safety.value,
                color: entry.safety.color,
                "pdf-mode": ""
              }, null, 8, ["value", "color"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("td", _hoisted_10, [
          (entry.efficiency)
            ? (_openBlock(), _createBlock(_component_PdfChip, {
                key: 0,
                value: entry.efficiency.value,
                color: entry.efficiency.color,
                "pdf-mode": ""
              }, null, 8, ["value", "color"]))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}