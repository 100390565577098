import axios from 'axios';
import apiClient, {createNewAxios, strapiApiUrl} from "@/api/api";
import { stringify } from "qs";

const query = stringify({
    populate: "*"
})

async function loginByEmailPassword(email: string, pass: string) {
    // We create new axios here because we don't want an interceptor that set's (invalid) auth token here.
    const axiosLogin = createNewAxios();

    return axiosLogin.post(`${strapiApiUrl}/auth/local?${query}`, {
        identifier: email,
        password: pass
    });
}

async function signup(userData: any) {
    return apiClient.post(`${strapiApiUrl}/auth/local/register?${query}`, userData);
    // return apiClient.post(`/auth/local/register?${query}`, userData);
}

async function changePassword(data: any) {
    return apiClient.post(`other/change-password?${query}`, data);
}


export default {
    loginByEmailPassword,
    signup,
    changePassword
}