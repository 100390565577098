
export function isEmptyObject(obj: any) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}


/**
 * @param t
 * @param minMax
 * @param currentPhotoLength skip this parameter if you want the label regardless of the current input
 */
export function getPhotoMinMaxLabel(t: any, minMax?: { min?: any, max?: any}, currentPhotoLength?: number): string | undefined {
    if (!minMax || (minMax.min === undefined && minMax.max === undefined)) {
        return undefined;
    }
    if (currentPhotoLength && (currentPhotoLength >= minMax.min || currentPhotoLength <= minMax.max)) {
        return undefined;
    }

    const min = minMax.min;
    const max = minMax.max;

    if (!max && min !== 1) { return t('hzba.benoetigtMinXMedien', { amount: min }); }
    if (!max && min === 1) { return t('hzba.benoetigtMax1Medien') }

    if (!min && max !== 1) { return t('hzba.benoetigtMaxXMedien', { amount: max }); }
    if (!min && max === 1) { return t('hzba.benoetigtMax1Medien') }

    if (min !== max) { return t('hzba.benoetigtZwischenXYMedien', { min, max })}
    if (min === max && min !== 1) { return t('hzba.benoetigtGenauXMedien', { amount: min })}
    if (min === max && min === 1) { return t('hzba.benoetigtGenau1Medium');}
    return `${min}/${max}`
}