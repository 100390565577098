

import { close, download, share } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import HzbaBaseModal from "@/components/Base/BaseModal";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import {computed, defineComponent, PropType} from "vue";
import { arrowBack } from "ionicons/icons";
import {IonIcon, IonContent, IonHeader, IonChip, IonButton, IonButtons, IonRow, IonCol, IonText, IonInput} from "@ionic/vue";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import PhotoViewModal from "@/components/hzba/Base/HzbaPhotoViewModal.vue";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput.vue";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import useBaseModal from "@/composables/useModal";
import useDownloadHelper from "@/composables/useDownloadHelper";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {MangelGewerk, MangelKategorie, MangelZeitpunkt} from "@/models/ba/Mangelvorlage";
import useDownloadMaengel from "@/composables/useDownloadMaengel";
import { getNestedBreadcrumbTitles } from "@/composables/Bestandsaufnahme/useIdentifierFinder";

export default defineComponent({
  name: "MaengelUebersichtModal",
  components: {
    BaseModal,
    HzbaChooseInput,
    HzbaPhotoInput,
    PhotoViewModal,
    IonChip,
    Toolbar, HzbaBaseInput, HzbaGroup, HzbaBaseModal, SectionWrapper, IonIcon, IonContent, IonHeader, IonButton, IonButtons, IonRow, IonCol, IonText, IonInput },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  emits: ["close", "save"],
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' })
    const { defaultCancel } = useBaseModal();


    const maengel = computed(() => {
      return props.ba && props.ba.getFiredMaengel();
    })

    const { canShare, downloadFile } = useDownloadHelper();

    const downloadResource = () => { useDownloadMaengel(props.ba, downloadFile, t); }


    /** TODO: Refactor code, getNestedBreadcrumbTitle is a duplicate */

    const getNestedBreadcrumbTitle = (path: string) => {
      const temp = getNestedBreadcrumbTitles(props.ba, path).split(' > ');
      let title = " ";
      temp.forEach(el => {
        if (title.includes(el)) { return }
        else { 
          title.length > 1 ? title = title.concat(' > ', el) : title = title.concat('', el);
        }
      })
      return title;
    }

    return {
      arrowBack,
      closeModal: defaultCancel,
      maengel,
      MangelKategorie,
      MangelZeitpunkt,
      MangelGewerk,
      canShare,
      t,
      share,
      close,
      downloadResource,
      download,
      getNestedBreadcrumbTitle
    }
  },
});
