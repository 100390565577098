<template>
  <ion-page>
    <Toolbar
      :title="t('hzba.pdf.vorschauTitel')"
      show-back-button
      @onBackButtonClicked="() => {}"
    >
      <template #leading>
        <ion-buttons slot="start">
          <ion-button @click="$router.replace(goBackUrl)">
            <ion-icon
              slot="icon-only"
              :icon="arrowBack"
            />
          </ion-button>
        </ion-buttons>
      </template>

      <template #trailing>
        <ion-buttons
          slot="primary"
        >
          <ion-button
            slot="end"
            color="light"
            @click="downloadPdf"
          >
            {{ t("hzba.buttons.download") }}
          </ion-button>
        </ion-buttons>
      </template>
    </Toolbar>
    <ion-content>
      <!-- <PdfPageSkeleton v-if="!isHzbaLoaded" /> -->
      <PdfPreviewerAndCreator
        ref="pdfViewer"
        download-name="property.pdf"
        :go-back-url="goBackUrl"
        :immobilieId="immobilie.id"
      >
      <pdf-mgt-summary-template
          :hzba="hzba"
          :immobilie="immobilie"
        />
        <!-- <pdf-mgt-summary-template
          v-if="genericHzba && immobilie"
          :hzba="hzba"
          :genericHzba="genericHzba"
          :immobilie="immobilie"
        /> -->
      </PdfPreviewerAndCreator>
    </ion-content>
  </ion-page>
</template>

<script>
import { useI18n } from 'vue-i18n';
import {arrowBack} from "ionicons/icons";
import {IonPage, IonIcon, IonButton, IonButtons, IonContent} from "@ionic/vue";
import PdfPreviewerAndCreator from "@/components/Pdf/PdfPreviewerAndCreator";
import PdfHzbaPreview from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate";
import { useStore } from "@/composables/useTypedStore";
import {computed, ref} from "vue";
import PdfHzbaTemplate from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate";
import PdfMgtSummaryTemplate from "@/components/Pdf/Bericht_ManagementSummary/PdfMgtSummaryTemplate";
import Immobilie from "@/models/immobilie.model";
import Toolbar from "@/components/Navigation/Toolbar";
import PdfPageSkeleton from "@/components/PdfPageSkeleton";

export default {
  name: "PdfPreviewMgtSummary",
  components: {
    PdfPageSkeleton,
    Toolbar,
    IonIcon,
    IonButtons,
    IonButton,
    PdfHzbaTemplate,
    PdfHzbaPreview,
    IonContent,
    PdfPreviewerAndCreator,
    IonPage,
    PdfMgtSummaryTemplate,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: 'global' })
    const pdfViewer = ref();
    const store = useStore();

    // const { hzbaId, isHzbaLoaded, hzba, propertyId, goBackUrl, genericHzba } = useBestandsaufnahmeSubpage({
    //   onIonViewWillEnter
    // onIonViewWillLeave
    // });

    const goBackUrl='/properties/'
    // const hzba = new Bestandsaufnahme(useNewHzbaJson().data);

    // TODO!
    const propertyIdFromUrl = 4
    // const propertyIdFromUrl = this.$route.params.propertyId
    // console.log('The id is: ' + this.$route.params.propertyId);
    const immobilie = computed(() => {
      if (propertyIdFromUrl){
        console.log('propertyIdFromUrl:',propertyIdFromUrl, 'findImmobilie:',Immobilie.find(propertyIdFromUrl))
        return Immobilie.find(propertyIdFromUrl);
      }
      return undefined;
    });

    const downloadPdf = () => {
      pdfViewer.value.downloadPdf();
    }

    return {
      // hzba,
      // arrowBack,
      immobilie,
      // propertyId,
      // genericHzba,
      // isHzbaLoaded,
      downloadPdf,
      pdfViewer,
      goBackUrl,
      t
    }
  },
};
</script>

<style scoped>

</style>