

//TODO add page and max-pages values
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeaderEnsimimav.vue";
import { useIdentifierFrageFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { getPhotoUrl } from '@/utilities/get-media-url';
import moment from 'moment';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import UniversalPdfImageRow from '../Components/UniversalPdfImageRow.vue';
import useEnsimimavPdfReport from './useEnsimimavPdfReport';

export default defineComponent( {
  name: 'Waermeverteilung',
  components: {
    PdfA4Page,
    PdfPageHeader,
    UniversalPdfImageRow
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup ( props: any ) {
    const { t } = useI18n( { useScope: 'global' } );
    const uUser = useUser();
    const useEnsimimavPdf = useEnsimimavPdfReport('de');

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      useEnsimimavPdf,
      
    };
  },
} );
