
import { useI18n } from "vue-i18n";
import moment from "moment";
import { computed, defineComponent, PropType } from "vue";
import Immobilie from "../../../models/immobilie.model";
import { Fragenblock } from "@/models/ba/Fragenblock";
import useNewHzbaJson from "@/composables/useNewHzbaJson";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";

export default defineComponent({
  name: "PdfPageHeader",
  props: {
    hzba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
    isMangelUebersicht: {
      type: Boolean,
    },
    isHinweisUebersicht: {
      type: Boolean,
    },
    page: {
      type: Number,
      required: true,
    },
    maxPages: {
      type: Number,
      required: true,
    },
    active: {
      type: String,
      default: "",
    },
    showChapters: {
      type: Boolean,
      default: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const { getVerwalterLabel } = useImmobilieHelper(props.immobilie);

    return {
      moment,
      t,
      getVerwalterLabel,
    };
  },
});
