
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent } from "vue";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { Fragenblock } from "@/models/ba/Fragenblock";
export default defineComponent({
  name: "PdfFMDataTable",
  components: { PdfChip },

  props: {
    freieMaengel: {
      type: Array as PropType<ExtendedMSERow[]>,
      required: true,
    },
    valueTitle: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: "Position",
    },
    lang: {
      type: String,
      default: "de",
    },
    isTableOfContent: {
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const positionTranslated = computed(() => {
      return props.name === "Position" ? t("hzba.pdf.position") : props.name;
    });

    const customMaengel = computed(() => {
      console.log(
        "props.freieMaengel",
        props.freieMaengel.length,
        props.freieMaengel
      );
      return props.freieMaengel;
    });

    return {
      positionTranslated,
      customMaengel,
      t,
    };
  },
});
