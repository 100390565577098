<template>
  <div
    v-if="!hidden"
    class="devbox bottom-16 lg:bottom-8"
    :class="isOpened ? 'opened' : ''"
  >
    <div
      v-if="!isOpened"
      class="text-right"
    >
      <IonButton
        type="button"
        data-cy="entwicklermodus"
        fill="solid"
        color="dark"
        size="small"
        :class="isOpened ? 'fixed' : ''"
        @click="toggle"
      >
        ENTWICKLERMODUS
      </IonButton>
      <div class="opacity-50 hidden lg:block">
        Diese Umgebung dient ausschließlich der Entwicklung und Qualitätssicherung.
      </div>
    </div>
    
    <div
      v-if="isOpened"
      class="wrapper m-4 mt-8"
      style="max-height: 70vh"
    >
      <div class="fixed">
        <IonButton
          type="button"
          fill="solid"
          color="dark"
          size="small"
          @click="toggle"
        >
          Schließen
        </IonButton>

        <IonButton
          type="button"
          data-cy="ausblenden"
          fill="solid"
          color="dark"
          size="small"
          @click="hide"
        >
          Ausblenden
        </IonButton>
      </div>

      
      <div class="content">
        <div>Backend url: {{ backendUrl }}</div>

        <h1 @click="showGenericHzba = !showGenericHzba">
          Generic Test Hzba
        </h1>
        <pre v-if="showGenericHzba">
          {{ genericHzbaJson }}
        </pre>

        <h1 @click="showMangelzuordnung = !showMangelzuordnung">
          Mangelzuordnung (Current Hzba)
        </h1>
        <pre v-if="showMangelzuordnung">
        {{ hzba.mangelzuordnungs }}
        <ion-button @click="resetMangelzuordnungen">Reset mangelzuordnungen</ion-button>
     </pre>

        <h1 @click="showHzbas = !showHzbas">
          Hzbas
        </h1>
        <pre v-if="showHzbas">
        {{ hzbas }}
     </pre>

        <h1 @click="showImmobilien = !showImmobilien">
          Immobilien
        </h1>
        <pre v-if="showImmobilien">
        {{ immobilien }}
     </pre>

        <h1 @click="showUser = !showUser">
          User
        </h1>
        <pre v-if="showUser">
        {{ user }}
     </pre>

        <h1 @click="showMangelVorlage = !showMangelVorlage">
          Mangelvorlage
        </h1>
        <pre v-if="showMangelVorlage">
<!--        {{ mangelVorlagen }}-->
     </pre>

      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
    import useAuth from "../composables/useAuth";
    import { IonButton } from "@ionic/vue";
import { useStore } from "@/composables/useTypedStore";
import Immobilie from "@/models/immobilie.model";
import useUser from "@/composables/useUser";
import {strapiApiUrl} from "@/api/api";
import {storeTypes} from "@/store/store";

export default {
  name: "DevBox", 
  components: {
        IonButton,
      },
        setup() {
            const auth = useAuth();
            const isOpened = ref(false);
            const store = useStore();

            const showHzba = ref(false);
            const showGenericHzba = ref(false);
            const showHzbas = ref(false);
            const showMangelzuordnung = ref(false);
            const showUser = ref(false);
            const showMangelVorlage = ref(false);
            const showImmobilien = ref(false);
            const hidden = ref(false);

          const hzba = computed(() => {
            return store.state.currentHzba.currentHzba;
          });

          const genericHzba = computed(() => {
            return store.state.currentHzba.currentBa;
          });

          const genericHzbaJson = ref();
          watch(() => genericHzba.value, async () => {
            genericHzbaJson.value = await genericHzba.value?.toClassJson();
          })

          const immobilien = computed(() => {
            return Immobilie.all();
          });

          // const mangelVorlagen = computed( () => {
          //   return Mangelvorlage.all();
          // })

          const user = useUser().user;
            
            function toggle() {
                isOpened.value = !isOpened.value;
            }

            const resetMangelzuordnungen = () => {
              hzba.value.mangelzuordnungs = [];
            }
            
            const hide = () => {
              hidden.value = true;
            }

            return {
                logout: auth.logout,
                toggle,
              hzba,
              genericHzba,
              showHzbas,
                isOpened,
              showMangelzuordnung,
              immobilien,
              showHzba,
              showImmobilien,
              genericHzbaJson,
              user,
              showUser,
              // mangelVorlagen,
              showMangelVorlage,
              resetMangelzuordnungen,
              backendUrl: strapiApiUrl,
              hidden,
              showGenericHzba,
              hide,
            }
        }
    }
</script>

<style scoped lang="scss">

    .devbox {
        position: fixed;
        right: 32px;
      user-select: text !important;
    }

    .opened {
    }
    .content {
      padding-top: 64px;
      height: 90vh;
      top: 80px;
      max-width: 50vw;
      bottom: 0;
      min-width: 300px;
      margin-right: -32px;
      max-width: 50vw;
      background: white;
      overflow-y: scroll;


    @media(min-width: 600px) {
      min-width: 500px;
    }

    }

</style>