import {store} from "@/store/store";
import util from "util";


function sanitizeLogArguments(args: any) {
    const arr = Array.from(args);
    const resArr = arr.map(el => {
        const str = util.inspect(el).replace(/\n/gi,'');
        try {
            return JSON.parse(str)

        } catch (err) {
            return str;
        }
    })

    return resArr;
}

class AppLogger {

    private store: any;

    public setup(store: any) {
        this.store = store;
    }

    public log(...args: any[]) {

        // log("test");
        // console.log("[Log]", ...args, _getCallerFile());
        
        if (this.store) {
            store.commit('app/addConsoleLog', { logs: sanitizeLogArguments(args), stage: 'log' })
        }
    }
}

const appLogger = new AppLogger();

export default appLogger
