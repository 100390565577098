
import { isEmptyObject } from '@/utilities/helper';
import {defineComponent, PropType, ref, watch} from "vue";
import AButton from "@/components/Base/AButton";
import {Fragenblock} from "@/models/ba/Fragenblock";

export default defineComponent({
  name: "DebugFragenblock",
  components: {AButton},
  props: {
    fragenblock: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
  },
  setup(props) {
    const showJson = ref();


    const fragenblockJson = ref();
    watch(() => props.fragenblock, async () => {
      fragenblockJson.value = await props.fragenblock.toClassJson();
    }, { immediate: true })

    return {
      showJson,
      fragenblockJson,
      isEmptyObject,
    }
  }
})

