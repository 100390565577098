<template>
  <div class="html2pdf__page-break" />
</template>

<script>
export default {
  name: "PdfPageBreak"
}
</script>

<style scoped>

</style>