
import { useI18n } from "vue-i18n";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import { defineComponent, PropType } from "vue";
import { MSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
export default defineComponent({
  name: "PdfMSEValues",
  components: { PdfChip },
  props: {
    data: {
      type: Object as PropType<MSERow>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(_, params) {
    const { t } = useI18n({ useScope: "global" });

    return {
      t,
    };
  },
});
