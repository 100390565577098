<template>
  <ion-item
    lines="none"
    data-cy="choose-input-item"
  >
    <ion-grid
      class="py-2 px-0"
      :class="separator ? 'border-bottom-1-light' : ''"
    >
      <ion-row class="block md:flex items-center">
        <input-label class="block md:inline">
          {{ label }}
        </input-label>


        <ion-col class="block p-0">
          <ion-grid class="p-0">
            <ion-row class="items-center">
              <ion-col class="p-0 ion-float-right">
                <div class="item-content">
                  <ion-segment
                    mode="ios"
                    :value="modelValue"
                    :disabled="readonly"
                    @ionChange="onChanged"
                  >
                    <ion-segment-button
                      :value="leftValue"
                      data-cy="true"
                    >
                      <ion-label>{{ leftTextTranslated }}</ion-label>
                    </ion-segment-button>
                    <ion-segment-button
                      :value="rightValue"
                      data-cy="false"
                    >
                      <ion-label>{{ rightTextTranslated }}</ion-label>
                    </ion-segment-button>
                  </ion-segment>
                </div>
              </ion-col>

              <unit-chip
                :show-unit="showUnit"
                :unit="unit"
              />
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>


      <ion-row
          v-if="dependentReason"
          class="ion-wrap d-flex items-center"
      >
        <ion-col>
          <ion-text class="text-gray-600 text-sm">
            {{ dependentReason }}
          </ion-text>
        </ion-col>
      </ion-row>


      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>


<script>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { IonItem, IonCol, IonRow, IonGrid, IonText, IonInput, IonLabel, IonToggle, IonSegmentButton, IonSegment } from "@ionic/vue";
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "HzbaChooseInput",
  components: {
    InputError,
    InputLabel,
    UnitChip,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel,
    IonToggle,
    IonSegment,
    IonSegmentButton
  },
  props: {
    modelValue: {
      type: [String, Boolean],
      default: undefined
    },
    errors: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      default: ''
    },
    // HOW to import i18n here??
    leftText: {
      type: String,
      default: 'Ja'
    },
    leftValue: {
      type: [String, Boolean],
      default: true
    },
    // HOW to import i18n here??
    rightText: {
      type: String,
      default: 'Nein'
    },
    rightValue: {
      type: [String, Boolean],
      default: false
    },
    showUnit: {
      type: Boolean,
      default: false
    },
    dependentReason: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    separator: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: 'global' })
    const leftTextTranslated = computed(()=>{
     return props.leftText === 'Ja'? t('hzba.buttons.ja') : props.leftText
    })
    const rightTextTranslated = computed(()=>{
    return props.rightText === 'Nein'? t('hzba.buttons.nein') : props.rightText
    })

    function onChanged(e) {
      let value;
      if (!e.target.value) {
        value = undefined;
      } else if (e.target.value === "true" || e.target.value === true) {
        value = true;
      } else {
        value = false;
      }
      emit('update:modelValue', value);
      if (value !== props.modelValue) {
        emit('change', e.target.value);
      }
    }

    return {
      onChanged,
      leftTextTranslated,
      rightTextTranslated,
      t,
    }
  }
})
</script>

<style scoped lang="scss">

ion-label {
  max-width: unset !important;
}

ion-select {
  max-width: unset;
  text-align: right;
}

ion-segment, ion-segment-button {
  --border-radius: 36px;
  border-radius: 36px;
}

</style>

<style>

.select-placeholder {
  text-align: right;
}

</style>