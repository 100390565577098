
import { defineComponent } from "vue";

export default defineComponent({
  name: "PdfChip",
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    color: {
      type: String,
      default: "default",
    },
    mangelAmountMode: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    /**
     * html2pdf cannot handle rounded corners, so in pdf mode we have to use images instead of styled divs
     */
    pdfMode: {
      type: Boolean,
      default: false,
    },
  },
});
