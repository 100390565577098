import {computed, onMounted, onUnmounted, ref} from "vue";
import {store} from "@/store/store";
import {Network} from "@capacitor/network";
import { useStore } from "@/composables/useTypedStore";

export const SCREEN_XL_START = 1400;
export const SCREEN_LG_START = 1024;

export default function useNetworkState() {

    const store = useStore();

    /**
     * Call this function once, e.g. in App.vue
     */
    async function mountMe() {
        const networkStatus = await Network.getStatus();
        store.commit("app/setNetworkState", networkStatus.connected);

        Network.addListener("networkStatusChange", (status) => {
            store.commit("app/setNetworkState", status.connected);
        });
    }

    async function unmountMe() {
        await Network.removeAllListeners();
    }



    return {
        mountMe,
        unmountMe,
    }

}