
import { useI18n } from 'vue-i18n';
import HzbaBaseModal from "@/components/Base/BaseModal";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import PhotoViewModal from "@/components/hzba/Base/HzbaPhotoViewModal.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import { IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { computed, defineComponent, PropType, ref } from "vue";
import { useStore } from "@/composables/useTypedStore";
import HzbaTextareaInput from "@/components/hzba/Base/Input/HzbaTextareaInput.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";

export default defineComponent({
  name: "SignAndSubmitModal",
  components: {
    BaseModal,
    HzbaTextareaInput,
    HzbaChooseInput,
    HzbaPhotoInput,
    PhotoViewModal,
    IonButtons,
    IonButton,
    IonChip,
    Toolbar, HzbaBaseInput, HzbaGroup, HzbaBaseModal, SectionWrapper, IonIcon, IonContent, IonHeader },
  props: {
    parent: {
      type: Function,
      required: true,
    },
    maxFazitLength: {
      type: Number,
      required: true,
    }
  },
  emits: ["close", "save"],
  setup(props: any) {

    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })

    // const fazitBackup = JSON.parse(JSON.stringify(props.hzba.fazit));
    const currentFazit = ref(props.parent().fazit);

    const currentFazitLength = computed( () => {
      return currentFazit.value?.trim().length
    })

    const fazitTooLong = computed(() => {
      return currentFazitLength.value > props.maxFazitLength
    });


    const submit = async () => {
      if (currentFazitLength.value > 0 ) {
        props.parent().fazit = currentFazit.value.trim();

        store.dispatch('currentHzba/saveHzbaLocally');
        await modalController.dismiss();
      }
    }

    const close = async () => {
      // props.hzba.fazit = fazitBackup;
      await modalController.dismiss();
    };

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );


    return {
      close,
      currentFazit,
      submit,
      isReadonly,
      arrowBack,
      fazitTooLong,
      currentFazitLength,
      t
    }
  },
});
