import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01deacea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mx-4 mb-4 grey-800" }
const _hoisted_2 = { class: "px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_hzba_photo_input = _resolveComponent("hzba-photo-input")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_hzba_choose_input = _resolveComponent("hzba-choose-input")!
  const _component_hzba_group = _resolveComponent("hzba-group")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_toolbar, {
          title: _ctx.t('hzba.vorschau.mangellisteVorschauTitel'),
          "enable-desktop-nav": false
        }, {
          leading: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  "data-cy": "photo-preview-close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.close
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          trailing: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  "data-cy": "button-downloadResource",
                  onClick: _ctx.downloadResource
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.canShare ? _ctx.share : _ctx.download
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maengel, (mangel) => {
          return (_openBlock(), _createBlock(_component_hzba_group, {
            key: mangel,
            "hide-progress": "",
            title: `${mangel.titel()} (${mangel.maluspunkte() } MP)`,
            class: "mangel-item",
            "data-cy": "mangelItem"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getNestedBreadcrumbTitle(mangel.parentPath)), 1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_chip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.MangelKategorie[mangel.kategorie()] || '-'), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ion_chip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(mangel.handlungsbedarfTaetigkeit() || '-'), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ion_chip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(mangel.handlungsbedarfZeitpunkt()), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_ion_chip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.MangelGewerk[mangel.gewerk()] || '-'), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              (mangel.eingabeMedien)
                ? (_openBlock(), _createBlock(_component_hzba_photo_input, {
                    key: 0,
                    modelValue: mangel.eingabeMedien,
                    "onUpdate:modelValue": ($event: any) => ((mangel.eingabeMedien) = $event),
                    readonly: "",
                    label: _ctx.t('hzba.bilder.titel')
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_row, { class: "ion-wrap d-flex items-center px-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { class: "ion-float-right p-0 pl-0 flex justify-end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, { class: "flex-grow-10" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('hzba.mangel.freitext')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, {
                class: "ion-wrap d-flex items-center mb-1 px-0",
                "data-cy": "input-row-freitext"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { class: "ion-float-right p-0 pl-0 flex justify-end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        class: "inline-block text-right input-text",
                        "data-cy": "base-input"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(mangel.eingabeFreitext), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              (mangel.eingabeRelevant === false || mangel.eingabeRelevant === true)
                ? (_openBlock(), _createBlock(_component_hzba_choose_input, {
                    key: 1,
                    modelValue: mangel.eingabeRelevant,
                    "onUpdate:modelValue": ($event: any) => ((mangel.eingabeRelevant) = $event),
                    label: _ctx.t('hzba.mangel.relevant'),
                    readonly: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}