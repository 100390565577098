<template>
  <div class="grid grid-cols-1 p-2 lg:p-0 sm:grid-cols-1 lg:grid-cols-2 gap-0">
    <ion-card
      button="true"
      data-cy="property-list-item"
      class="m-2"
      v-for="card in [0, 1, 2, 3, 4, 5, 6, 7]"
      :key="card"
    >
      <div class="p-0 m-0">
        <div class="p-0 m-0 h-32 md:h-36 flex flex-row">
          <ion-avatar slot="start">
            <ion-skeleton-text animated />
          </ion-avatar>
          <ion-item class="flex-1">
            <ion-label>
              <h3>
                <ion-skeleton-text
                  animated
                  style="width: 50%"
                />
              </h3>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 80%"
                />
              </p>
              <p>
                <ion-skeleton-text
                  animated
                  style="width: 60%"
                />
              </p>
            </ion-label>
          </ion-item>
        </div>
      </div>
    </ion-card>
  </div>
</template>

<script>
import {
  IonAvatar,
  IonLabel,
  IonItem,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonCard
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Skeleton",
  components: {
    IonAvatar,
    IonSkeletonText,
    IonLabel,
    IonItem,
    IonList,
    IonListHeader,
    IonCard,
  },
});
</script>

<style>
ion-avatar {
  --border-radius: 0;
  height: 100%;
  min-width: 120px;
}
</style>