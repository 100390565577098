<template>
  <div class="icon-button">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AIconButton'
}


</script>

<style>

.icon-button ~ .icon-button {
  margin-left: 10px;
}

</style>