
import { defineComponent, PropType } from "vue";
import Property from "../../models/immobilie.model";
import PropertyCard from "./PropertyCard.vue";

export default defineComponent({
  name: "PropertyList",
  components: {
    PropertyCard,
  },
  props: {
    properties: {
      type: Object as PropType<Property[]>,
      required: true,
    },
  },
});
