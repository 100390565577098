<template>
  <div :class="imageClass">

      <!-- Placeholder -->
      <div
        v-show="status === 'error' || status === 'null' || status === 'loading' "
        style="background: #efefef"
        class="flex md:flex items-center content-center justify-center"
        :class="imageClass"
      >
        <ion-skeleton-text
          v-if="status === 'loading'"
          animated
        />

        <div 
          v-if="status === 'error'"
        >
          offline
        </div>

        <div
          v-if="status === 'null'"
        >
          -
        </div>

      </div>
    
      <img
        v-if="status === 'loaded' || status === 'loading'"
        v-show="status === 'loaded' "
        :class="imageClass.replace( 'hairline', '')"
        :style="setToMax ? 'height: 100%; width: 100%' : 'cursor: pointer'"
        :src="src + (reloadNr ? `?reload=${reloadNr}` : '')"
        @load="loaded"
        @error="error"
      >
  </div>
</template>

<script>
import {computed, ref, watch} from "vue";
import { IonSkeletonText} from "@ionic/vue";
import {useStore} from "@/composables/useTypedStore";

export default {
  name: "AImage",
  components: {
    IonSkeletonText,
  },
  props: {
    imageClass: {
      type: String,
      default: 'w-full h-auto'
    },
    src: {
      type: String,
      default: ''
    },
    setToMax: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const status = ref('loading');

    watch(() => props.src, (newVal) => {

      if (!props.src) {
        status.value = 'null';
      } else {
        status.value = 'loading';
      }
    })

    const reloadNr = ref(0);

    const loaded = () => {
      status.value = 'loaded';
    }

    const error = () => {
      status.value = 'error';
    }

    const store = useStore();
    const isOffline = computed(() => {
      return store.getters["app/isOffline"];
    });

    watch(isOffline, (newVal, oldVal) => {
      console.log("ISOFFLINE update", newVal, oldVal)
      if (newVal !== oldVal && status.value !== 'loaded') {
        status.value = 'loading';
        reloadNr.value ++;
      }
    })


    return {
      loaded,
      error,
      reloadNr,
      status
    }
  }
}
</script>

<style scoped>

.h-200 {
  height: 200px;
}
.w-300 {
  width: 300px;
}

</style>