import Immobilie from '@/models/immobilie.model';
import { Coordinates, GeoJSONSource, LngLat, LngLatBounds, Map, NavigationControl, } from 'maplibre-gl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
// import mapStyleJson from '../../public/assets/geodata/style_7550_5150_AB2.json';

export function usePropertyMap(mapRootId: string, properties: Immobilie[]) {

  const map = ref();
  let propertySource: any;

  function setPropertySource( props: Immobilie[], propertyId?: number ): void {
    const workItemsGeoJSONPoints = props.filter(prop => prop.geolocationLon && prop.geolocationLat).map((property: Immobilie) => {
      if (property.geolocationLat !== null) {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [property.geolocationLon, property.geolocationLat],
          },
          properties: {
            itemData: property.id,
            selected: propertyId === property.id
          },
        };
      }
    });

    propertySource = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: workItemsGeoJSONPoints,
      }
    };
  }

  function addPropertySource(): void{
    map.value.addSource('propertySource', propertySource)
  }


  function addPropertyLayer(): void {
    map.value.addLayer({
      id: 'property-points',
      type: 'circle',
      source: 'propertySource',
      paint: {
        'circle-radius': 5,
        "circle-stroke-width": 1,
        'circle-color': [
            'case',
            ['==', ['get', 'selected'], true], '#ED2F2F', '#6395fc'
            
        ],
        "circle-opacity": 1,
        'circle-stroke-color': [
          'case',
          ['==', ['get', 'selected'], true], 'gray', 'gray'
          
      ]
      }
    });
  }

  function getBounds(): LngLatBounds {
    const coordinates: LngLat[] = propertySource.data.features.map((feature: any) => {
      const [lng, lat] = feature.geometry.coordinates;
      if (lat < -90 || lat > 90) {
        console.log('Invalid latitude value: ${lat} for property ', feature, ' , must be between -90 and 90.');
        return;
      }
      if (lng < -180 || lng > 180) {
        console.log('Invalid longitude value: ${lng} for property ', feature, ' , must be between -180 and 180.');
        return;
      }
      return new LngLat(lng, lat);
    });
  
    const bounds = coordinates.reduce((bounds: LngLatBounds, coord: LngLat) => {
      return bounds.extend(coord);
    }, new LngLatBounds(coordinates[0], coordinates[0])); 
  
    return bounds;
  }


  function createMap(): void {
    map.value = new Map({
      container: mapRootId,
      center: [	13.381777, 52.531677],
      zoom:6.5,
      // style: "https://static.maptoolkit.net/rapidapi/styles/terrain.json?rapidapi-key=0f7287bfe8msh616baa53e45e2a9p1f8d28jsn12d87829bff3"
      style: "https://static.maptoolkit.net/styles/toursprung/light.json?api_key=movinglayers"
  });
   
    map.value.addControl( new NavigationControl({}), 'top-left' );
    // map.value.addControl( new GeolocateControl({}), 'top-left' );
  }

  async function animateToProperty(property: Immobilie): Promise<void> {
    const source: GeoJSONSource = map.value.getSource('propertySource') as GeoJSONSource;
    setPropertySource(properties, property.id);
    source.setData(propertySource.data); 
    map.value.flyTo({
      center: [property.geolocationLon, property.geolocationLat],
      zoom: 17.5,
      minZoom: 13,
      speed: 0.5,
      essential: true
    });
  }

  // const setPropertyPointClickedListener = (fctn: Function) => {
  //   propertyPointClickedListener = fctn;
  // }
  // const setPropertyPointHoveredListener = (fctn: Function) => {
  //   propertyPointHoveredListener = fctn;
  // }



  function updateProperties(props: Immobilie[]): void {
    setPropertySource(props);
    
    ( map.value.getSource('propertySource') as GeoJSONSource).setData(propertySource.data);
  }

  function reload(): void {
    map.value.resize();
  }

  onBeforeUnmount(() => {
    if ((document.getElementById(mapRootId)?.children?.length ?? 0) > 0) {
      document.getElementById(mapRootId)!.firstChild!.remove();
    }
  });

  onMounted(() => {
    setPropertySource( properties );
    createMap();

    map.value.once("load", () => {
      map.value.fitBounds(getBounds(), {
        padding: 100
      });
      addPropertySource();
      addPropertyLayer();
    });
  });

  return {
    animateToProperty,
    updateProperties,
    reload,
    map
  };
}
