import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLinkBanner = _resolveComponent("AppLinkBanner")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_dev_box = _resolveComponent("dev-box")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.showAppLink)
        ? (_openBlock(), _createBlock(_component_AppLinkBanner, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_tabs, {
        class: _normalizeClass(_ctx.showAppLink ? 'app-link-shown' : '')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, { animated: true }),
          _withDirectives(_createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: _normalizeClass( _ctx.isDesktopToolbarForbidden ? 'bottomNavigation' : '')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "tab_properties",
                href: "/properties",
                "data-cy": "toolbar-immobilien"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.home }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("toolbar.immobilien")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (!_ctx.user.isImmobilienverwalter())
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 0,
                    tab: "tab_main",
                    href: "/bas"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.reader }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("toolbar.bestandsaufnahmen")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_tab_button, {
                tab: "tab_setting",
                href: "/settings",
                "data-cy": "toolbar-einstellungen"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.settings }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("toolbar.einstellungen")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]), [
            [_vShow, _ctx.showBottomNavigation]
          ])
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.isDev)
        ? (_openBlock(), _createBlock(_component_dev_box, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}