
import { useI18n } from "vue-i18n";
import {
  IonPage,
  IonContent,
  onIonViewWillEnter,
  IonButtons,
  IonButton,
  IonIcon,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
} from "@ionic/vue";
import { useStore } from "@/composables/useTypedStore";
import Toolbar from "@/components/Navigation/Toolbar";
import { computed, onMounted, Ref, ref, watch } from "vue";
import HzbaList from "@/components/hzba/HzbaList.vue";

import { getPhotoUrl } from "@/utilities/get-media-url";
import useUser from "@/composables/useUser";
import { popoverHelper } from "@/utilities/popover-helper";
import Immobilie from "@/models/immobilie.model";
import useFetchData from "@/composables/useFetchData";
import Skeleton from "../components/Skeleton.vue";
import { getLocalMetaData } from "@/models/local-meta-data";
import { swapVerticalOutline } from "ionicons/icons";
import moment from "moment";
import Searchbar from "@/components/hzba/Base/Searchbar.vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import AButton from "@/components/Base/AButton";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import Filters from "@/components/Base/Filters.vue";
import SortBy from "@/components/Base/SortBy.vue";
import {SortTermActive} from "@/composables/Sort/SortTerms";

export default {
  name: "HzbasPage",
  components: {
    SortBy,
    Filters,
    OfflineHint,
    Searchbar,
    Skeleton,
    Toolbar,
    IonPage,
    IonContent,
    HzbaList,
    IonButton,
    IonButtons,
    IonImg,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    UserDialogButton,
    AButton
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const uUser = useUser();
    const useData = useFetchData();

    const { bestandsaufnahmes, loadBestandsaufnahmens, filterOptions, supportedSortTerms } = useBestandsaufnahmens();


    const searchTerm = ref<string | undefined>("");
    const sortTerm = ref<SortTermActive | undefined>(BestandsaufnahmeModel.getters("sortBy"));

    onIonViewWillEnter(
      () => { 
        store.commit("app/showBottomNavigation"); 
        loadBestandsaufnahmens();
      }
);



    const bas = computed(() => {
      return bestandsaufnahmes.value.map((el) => new Bestandsaufnahme(el.copyJson()));
    });
    const loaded = computed(() => BestandsaufnahmeModel.getters("loaded"));

    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData("lastBackendFetch"));
      return `${date.format("DD.MM.YYYY")} / ${date.format("HH:mm:ss")} Uhr`;
    });

    const selectedFilters = ref([]);

    watch(() => bas, () => {
      console.log('** BA changed***')
      // return bestandsaufnahmes.value.map((el) => new Bestandsaufnahme(el.copyJson()));
    })
    watch([searchTerm, sortTerm, selectedFilters], () => {
      BestandsaufnahmeModel.dispatch("setSearchTerm", searchTerm.value);
      BestandsaufnahmeModel.dispatch("setSortBy", sortTerm.value);
      BestandsaufnahmeModel.dispatch("setStateFilter", selectedFilters);
      BestandsaufnahmeModel.dispatch("setPage", 0);
      BestandsaufnahmeModel.deleteAll();
      loadBestandsaufnahmens();
    });

    const showLoadMore = computed(() => {
      return (
        BestandsaufnahmeModel.getters("pageCount") &&
        BestandsaufnahmeModel.getters("page") <
        BestandsaufnahmeModel.getters("pageCount")!
      );
    });

    const loadMore = () => {
      loadBestandsaufnahmens();
    };

    return {
      bas,
      user: uUser.user,
      getPhotoUrl,
      openPopover: popoverHelper,
      loaded,
      doRefresh: useData.doRefresh,
      searchTerm,
      supportedSortTerms,
      sortTerm,
      lastFetch,
      swapVerticalOutline,
      t,
      filterOptions,
      selectedFilters,
      showLoadMore,
      loadMore,
    };
  },
};
