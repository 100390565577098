<template>
  <ion-col>
    <ion-label :style="noPreWrap ? '' : 'white-space: pre-wrap;'">
      <div class="text-base md:text-md">
        <slot />
      </div>
    </ion-label>
  </ion-col>
</template>

<script>
import {IonLabel, IonCol} from "@ionic/vue";

export default {
  name: "InputLabel",
  components: {
    IonLabel,
    IonCol,
  },
  props: {
    noPreWrap: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>