

import { useI18n } from 'vue-i18n';
import moment from "moment";
import {defineComponent} from "vue";

export default defineComponent({
  name: 'PdfAppointmentTable',
  props: {
    appointments: {
      type: Object,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    return { moment, t }
  }
})
