import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-cy"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hzba_choose_input = _resolveComponent("hzba-choose-input")!
  const _component_hzba_textarea_input = _resolveComponent("hzba-textarea-input")!
  const _component_hzba_photo_input = _resolveComponent("hzba-photo-input")!
  const _component_hzba_select_input = _resolveComponent("hzba-select-input")!
  const _component_hzba_base_input = _resolveComponent("hzba-base-input")!
  const _component_DebugFrage = _resolveComponent("DebugFrage")!
  const _component_mangel_item = _resolveComponent("mangel-item")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formFrages, (frage, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass([(frage.separator === undefined || frage.separator) && frage.type && frage.isShown() ? 'border-bottom-1-light' : '', "relative"]),
      "data-cy": `frage-${frage.identifier}`
    }, [
      (frage.eingabeTyp?.toLowerCase() === 'boolean' && frage.isShown())
        ? (_openBlock(), _createBlock(_component_hzba_choose_input, {
            key: 0,
            modelValue: frage.eingabeBoolean,
            "onUpdate:modelValue": ($event: any) => ((frage.eingabeBoolean) = $event),
            "data-cy": `input-choose-${frage.identifier}`,
            errors: frage.errors(_ctx.t),
            label: frage.getTitle(),
            "dependent-reason": frage.config?.beschreibung,
            readonly: _ctx.isReadonly
          }, null, 8, ["modelValue", "onUpdate:modelValue", "data-cy", "errors", "label", "dependent-reason", "readonly"]))
        : _createCommentVNode("", true),
      (frage.eingabeTyp?.toLowerCase() === 'textblock' && frage.isShown())
        ? (_openBlock(), _createBlock(_component_hzba_textarea_input, {
            key: 1,
            modelValue: frage.eingabeText,
            "onUpdate:modelValue": ($event: any) => ((frage.eingabeText) = $event),
            "data-cy": `input-textarea-${frage.identifier}`,
            errors: frage.errors(_ctx.t),
            label: frage.getTitle(),
            type: frage.inputType,
            placeholder: frage.platzhalter,
            readonly: _ctx.isReadonly,
            "hide-title": frage.hideTitle,
            limit: _ctx.textblockLimit
          }, null, 8, ["modelValue", "onUpdate:modelValue", "data-cy", "errors", "label", "type", "placeholder", "readonly", "hide-title", "limit"]))
        : _createCommentVNode("", true),
      (frage.eingabeTyp?.toLowerCase() === 'medien' && frage.isShown())
        ? (_openBlock(), _createBlock(_component_hzba_photo_input, {
            key: 2,
            modelValue: frage.eingabeMedien,
            "onUpdate:modelValue": ($event: any) => ((frage.eingabeMedien) = $event),
            errors: frage.errors(_ctx.t),
            "data-cy": `input-photo-${frage.identifier}`,
            class: "flex py-2",
            label: frage.getTitle(),
            "min-max": frage.config?.allowedNumberOfMedien,
            "min-max-label": frage.getPhotoMinMaxLabel(_ctx.t),
            "is-pdf-upload-type": frage.isPdfUploadType()
          }, null, 8, ["modelValue", "onUpdate:modelValue", "errors", "data-cy", "label", "min-max", "min-max-label", "is-pdf-upload-type"]))
        : _createCommentVNode("", true),
      (frage.eingabeTyp?.toLowerCase() === 'auswahl' && frage.isShown())
        ? (_openBlock(), _createBlock(_component_hzba_select_input, {
            key: 3,
            modelValue: frage.eingabeAuswahlWert,
            "onUpdate:modelValue": ($event: any) => ((frage.eingabeAuswahlWert) = $event),
            "data-cy": `input-select-${frage.identifier}`,
            errors: frage.errors(_ctx.t),
            multiple: false,
            label: frage.getTitle(),
            options: frage.getAuswahlOptionValues(),
            labels: frage.getAuswahlOptionLabels(),
            readonly: _ctx.isReadonly,
            "dependent-reason": frage.config?.beschreibung
          }, null, 8, ["modelValue", "onUpdate:modelValue", "data-cy", "errors", "label", "options", "labels", "readonly", "dependent-reason"]))
        : _createCommentVNode("", true),
      (frage.eingabeTyp?.toLowerCase() === 'mehrfachauswahl' && frage.isShown())
        ? (_openBlock(), _createBlock(_component_hzba_select_input, {
            key: 4,
            modelValue: frage.eingabeMehrfachAuswahlWerts,
            "onUpdate:modelValue": ($event: any) => ((frage.eingabeMehrfachAuswahlWerts) = $event),
            "data-cy": `input-select-${frage.identifier}`,
            errors: frage.errors(_ctx.t),
            multiple: true,
            label: frage.getTitle(),
            options: frage.getAuswahlOptionValues(),
            labels: frage.getAuswahlOptionLabels(),
            readonly: _ctx.isReadonly,
            "dependent-reason": frage.config?.beschreibung
          }, null, 8, ["modelValue", "onUpdate:modelValue", "data-cy", "errors", "label", "options", "labels", "readonly", "dependent-reason"]))
        : _createCommentVNode("", true),
      ((
        frage.eingabeTyp.toLowerCase() === 'text'
        || frage.eingabeTyp.toLowerCase() === 'zahl'
        || frage.eingabeTyp.toLowerCase() === 'uhrzeit'
        || frage.eingabeTyp.toLowerCase() === 'datum'
      ) && frage.isShown())
        ? (_openBlock(), _createBlock(_component_hzba_base_input, {
            key: 5,
            modelValue: frage.eingabeText,
            "onUpdate:modelValue": ($event: any) => ((frage.eingabeText) = $event),
            "data-cy": `input-text-${frage.identifier}`,
            errors: frage.errors(_ctx.t),
            label: frage.getTitle(),
            type: _ctx.getInputType(frage.eingabeTyp.toLowerCase()),
            unit: frage.einheit,
            min: frage.min,
            readonly: !!(frage.readonly || _ctx.isReadonly || frage.berechnunglogik),
            placeholder: frage.platzhalter,
            "dependent-reason": frage.config?.beschreibung
          }, null, 8, ["modelValue", "onUpdate:modelValue", "data-cy", "errors", "label", "type", "unit", "min", "readonly", "placeholder", "dependent-reason"]))
        : _createCommentVNode("", true),
      (_ctx.enabledDevMode)
        ? (_openBlock(), _createElementBlock("div", {
            key: 6,
            class: "absolute top-0 right-2 z-10",
            onClick: ($event: any) => (_ctx.activeDebug = _ctx.activeDebug === index ? undefined : index)
          }, " o ", 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.enabledDevMode && _ctx.activeDebug === index)
        ? (_openBlock(), _createBlock(_component_DebugFrage, {
            key: 7,
            frage: frage,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeDebug = undefined))
          }, null, 8, ["frage"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(frage.mangels, (mangel, mangelIndex) => {
        return (_openBlock(), _createElementBlock("div", { key: mangelIndex }, [
          mangel
            ? (_openBlock(), _createBlock(_component_mangel_item, {
                key: 0,
                mangel: mangel,
                frage: frage
              }, null, 8, ["mangel", "frage"]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 10, _hoisted_1))
  }), 128))
}