
import { useI18n } from "vue-i18n";
import { defineComponent, PropType } from "vue";
import { getPhotoUrl } from "../../../utilities/get-media-url";
import moment from "moment";

import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import Immobilie, { immobilie } from "@/models/immobilie.model";
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";

export default defineComponent({
  name: "PdfGeneralData",
  props: {
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    hzba: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const { getVerwalterLabel } = useImmobilieHelper(props.immobilie);

    return {
      getPhotoUrl,
      moment,
      t,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,
      getVerwalterLabel,
    };
  },
});
