
import { defineComponent, PropType } from "vue";
import Markdown from "vue3-markdown-it";
import { IonCard } from "@ionic/vue";
import Faq from "@/models/faq.model";
import { strapiUrl } from "@/api/api";

export default defineComponent({
  name: "FaqItem",
  components: {
    IonCard,
    Markdown,
  },
  props: {
    faq: {
      type: Object as PropType<Faq>,
      required: true,
    },
  },
  setup(props) {
    const getCleanedFaq = () => {
      const cleanedFaq = props.faq;
      // NOTE: because strapi markdown adds the image url without the host, we need to concatenate every img url with the backend url
      cleanedFaq.antwort = cleanedFaq.antwort?.replace(
        /(!\[.*?\]\()(.+?)(\))/g,
        (whole: string, a: string, b: string, c: string) => {
          if (!b.startsWith("http")) {
            return a + strapiUrl + b + c;
          }
          return a + b + c;
        }
      );
      return cleanedFaq;
    };

    const headerClicked = (_event: any) => {
      _event.currentTarget.classList.toggle("active");
      /* Toggle close all panels, except on that was clicked */
      const allPanels: any = document.getElementsByClassName("panel");
      allPanels.forEach((panel: any) => {
        if (_event.currentTarget.nextElementSibling !== panel) {
          panel.style.maxHeight = null;
        }
        panel.previousElementSibling.classList.remove("active");
      });
      /* Toggle between hiding and showing the active panel */
      const panel = _event.currentTarget.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }

    return {
      getCleanedFaq,
      headerClicked
    };
  },
});
