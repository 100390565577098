import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = ["data-cy"]
const _hoisted_2 = { class: "mx-6 flex items-center" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = {
  key: 0,
  class: "warning mb-0 text-base"
}
const _hoisted_5 = {
  key: 1,
  class: "mb-0 text-xl"
}
const _hoisted_6 = { class: "mt-0 text-xl" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "p-4"
}
const _hoisted_11 = {
  key: 2,
  class: "p-4"
}
const _hoisted_12 = {
  key: 3,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_hzba_base_input = _resolveComponent("hzba-base-input")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_hzba_photo_input = _resolveComponent("hzba-photo-input")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_hzba_choose_input = _resolveComponent("hzba-choose-input")!
  const _component_hzba_textarea_input = _resolveComponent("hzba-textarea-input")!
  const _component_hzba_select_input = _resolveComponent("hzba-select-input")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["mangel-item-wrapper", $props.collapsed ? 'collapsed' : '']),
        "data-cy": "mangel-item-wrapper"
      }, [
        _createElementVNode("div", {
          class: "m-1 md:mx-6 mangel-item",
          "data-cy": `${$props.mangel.uid()}`
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (!$props.isCustomMangelAddMode)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(!$props.isCustomMangel
                  ? $setup.t("hzba.mangel.mangelErkannt")
                  : $setup.t("hzba.mangel.mangelHinzugefuegt")) + " (" + _toDisplayString($props.mangel.maluspunkte()) + " " + _toDisplayString($setup.t("hzba.mangel.mp")) + ") ", 1))
                : (_openBlock(), _createElementBlock("h3", _hoisted_5, "Mangel hinzufügen")),
              _createElementVNode("h2", _hoisted_6, _toDisplayString($props.mangel.titel()), 1)
            ]),
            (!$props.collapsed && $setup.showBodyEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_ion_button, {
                    fill: "clear",
                    color: "dark",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:collapsed', !$props.collapsed)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.chevronUpOutline,
                        size: "large"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            ($props.collapsed && $setup.showBodyEnabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_ion_button, {
                    "data-cy": "button-expand-mangel",
                    fill: "clear",
                    color: "dark",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:collapsed', !$props.collapsed)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: $setup.chevronDownOutline,
                        size: "large"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          (!$props.collapsed && $setup.showBodyEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_base_input, {
                      key: 0,
                      modelValue: $props.mangel.custom.titel,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.mangel.custom.titel) = $event)),
                      "data-cy": "input-text-bezeichnung",
                      label: $setup.t('hzba.mangel.bezeichnung'),
                      readonly: $props.isReadonly,
                      "hide-unit-space": "",
                      placeholder: $setup.t('hzba.mangel.bezeichnungPlaceholder')
                    }, null, 8, ["modelValue", "label", "readonly", "placeholder"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.customTitelErrors
                }, null, 8, ["errors"]),
                (!$props.mangelConfig?.hideBilder)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 1,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_grid, { "data-cy": "input-photo-bilder" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_row, { class: "d-flex items-center" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($props.mangel.getBilderLabel($setup.t)), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_hzba_photo_input, {
                                      modelValue: $props.mangel.eingabeMedien,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($props.mangel.eingabeMedien) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.imageErrors
                }, null, 8, ["errors"]),
                (!$props.mangelConfig?.hideIstRelevant)
                  ? (_openBlock(), _createBlock(_component_hzba_choose_input, {
                      key: 2,
                      modelValue: $props.mangel.eingabeRelevant,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($props.mangel.eingabeRelevant) = $event)),
                      label: $props.mangel.getIstRelevantLabel($setup.t),
                      "data-cy": "input-choose-relevanz",
                      readonly: $props.isReadonly,
                      "show-unit": false
                    }, null, 8, ["modelValue", "label", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.relevantErrors
                }, null, 8, ["errors"]),
                (!$props.mangelConfig?.hideFreitext)
                  ? (_openBlock(), _createBlock(_component_hzba_textarea_input, {
                      key: 3,
                      modelValue: $props.mangel.eingabeFreitext,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($props.mangel.eingabeFreitext) = $event)),
                      "data-cy": "input-textarea-freitext",
                      label: $props.mangel.getFreitextLabel($setup.t),
                      readonly: $props.isReadonly,
                      limit: $setup.freitextLimit
                    }, null, 8, ["modelValue", "label", "readonly", "limit"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.textErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_select_input, {
                      key: 4,
                      modelValue: $props.mangel.custom.gewerk,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($props.mangel.custom.gewerk) = $event)),
                      "data-cy": "input-select-gewerk",
                      multiple: false,
                      label: $setup.t('hzba.mangel.gewerk'),
                      options: $setup.mangelGewerkKeys,
                      labels: $setup.mangelGewerkValues,
                      readonly: $props.isReadonly
                    }, null, 8, ["modelValue", "label", "options", "labels", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.gewerkErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_base_input, {
                      key: 5,
                      modelValue: $props.mangel.custom.handlungsbedarfTaetigkeit,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($props.mangel.custom.handlungsbedarfTaetigkeit) = $event)),
                      "data-cy": "input-text-handlungsbedarf",
                      label: $setup.t('hzba.mangel.handlungsbedarfTaetigkeit'),
                      readonly: $props.isReadonly,
                      "hide-unit-space": ""
                    }, null, 8, ["modelValue", "label", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.handlungsbedarfTaetigkeitsErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_select_input, {
                      key: 6,
                      modelValue: $props.mangel.custom.handlungsbedarfZeitpunkt,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($props.mangel.custom.handlungsbedarfZeitpunkt) = $event)),
                      "data-cy": "input-select-handlungsbedarf-zeitpunkt",
                      multiple: false,
                      label: $setup.t('hzba.mangel.handlungsbedarfZeitpunkt'),
                      options: $setup.mangelZeitpunktKeys,
                      labels: $setup.mangelZeitpunktValues,
                      readonly: $props.isReadonly
                    }, null, 8, ["modelValue", "label", "options", "labels", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.handlungsbedarfZeitpunktErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_select_input, {
                      key: 7,
                      modelValue: $props.mangel.custom.kategorie,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($props.mangel.custom.kategorie) = $event)),
                      "data-cy": "input-select-kategorie",
                      multiple: false,
                      label: $setup.t('hzba.mangel.kategorie'),
                      options: $setup.mangelKategorieKeys,
                      labels: $setup.mangelKategorieValues,
                      readonly: $props.isReadonly
                    }, null, 8, ["modelValue", "label", "options", "labels", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.kategorieErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_select_input, {
                      key: 8,
                      modelValue: $props.mangel.custom.produktTyp,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($props.mangel.custom.produktTyp) = $event)),
                      "data-cy": "input-select-produktTyp",
                      multiple: false,
                      label: $setup.t('hzba.mangel.produktTyp'),
                      options: $setup.mangelProduktTypKeys,
                      labels: $setup.mangelProduktTypValues,
                      readonly: $props.isReadonly
                    }, null, 8, ["modelValue", "label", "options", "labels", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.produktTypErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_select_input, {
                      key: 9,
                      modelValue: $props.mangel.custom.quelle,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($props.mangel.custom.quelle) = $event)),
                      "data-cy": "input-select-quelle",
                      multiple: false,
                      label: $setup.t('hzba.mangel.quelle'),
                      options: $setup.mangelQuelleKeys,
                      labels: $setup.mangelQuelleValues,
                      readonly: $props.isReadonly
                    }, null, 8, ["modelValue", "label", "options", "labels", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.quelleErrors
                }, null, 8, ["errors"]),
                ($props.isCustomMangel)
                  ? (_openBlock(), _createBlock(_component_hzba_base_input, {
                      key: 10,
                      type: 'number',
                      modelValue: $props.mangel.custom.maluspunkte,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($props.mangel.custom.maluspunkte) = $event)),
                      "data-cy": "input-text-maluspunkte",
                      label: $setup.t('hzba.mangel.maluspunkte'),
                      readonly: $props.isReadonly,
                      "hide-unit-space": ""
                    }, null, 8, ["modelValue", "label", "readonly"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_input_error, {
                  class: "px-4",
                  errors: $setup.maluspunkteErrors
                }, null, 8, ["errors"])
              ]))
            : _createCommentVNode("", true),
          ($props.collapsed && $props.errors && $props.errors.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_input_error, {
                  errors: $props.errors,
                  "show-all": ""
                }, null, 8, ["errors"])
              ]))
            : _createCommentVNode("", true),
          (!$setup.showBodyEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString($setup.t("hzba.mangelInfoText")), 1))
            : _createCommentVNode("", true),
          (!$props.collapsed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _renderSlot(_ctx.$slots, "default")
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1)
      ], 2)
    ]),
    _: 3
  }))
}