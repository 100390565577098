import ErweitertesMedium from "@/models/erweitertes-medium.model";
import Unterschrift from "@/models/hzba/unterschrift.model";
import User from "@/models/user";
import { Model } from "@vuex-orm/core";
import { APhoto } from "../../photo/a-photo.model";
import StrapiMedia from "../../photo/strapi-media.model";
import {
  BestandsaufnahmeFlatJson,
  BestandsaufnahmeJson,
  HzbaStatusKey,
} from "@/models/ba/interfaces/IBestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Localization from "@/models/localization.model";
import Immobilie from "@/models/immobilie.model";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { computed } from "vue";
import { useStore } from "@/composables/useTypedStore";

export default class BestandsaufnahmeModel
  extends Model
  implements BestandsaufnahmeFlatJson
{
  /**
   * Vuex ORM entity name
   */
  static entity = "bestandsaufnahmes";

  /**
   * Backend variable definitions
   */
  public id!: number;
  public bilder!: APhoto[];
  public malus!: string;
  public malusColor!: string;
  public kommentar!: string;
  public begehungsdatum!: string;
  public berichts!: ErweitertesMedium[];
  public mangellists!: string;
  public archive!: StrapiMedia;
  public matterportLink?: string;
  public immobilie!: number;
  public status!: HzbaStatusKey;
  public unterschrifts?: Unterschrift[];
  public auftraggeber!: string;
  public technProjektsteuerungName!: string;
  public technProjektsteuerungUnterschrift!: APhoto[];
  public reihenfolge!: string;
  public freigabeDatum!: string;
  public fazit!: string;
  public createdAt!: string;
  public updatedAt!: string;
  public createdBy!: string;
  public gutachter!: any;
  public updatedBy!: User;
  public bearbeitenderNutzer?: User;
  public bearbeitendesGeraetId?: string;
  public fragenblocks?: Fragenblock[];
  public name!: Localization;
  public nameMehrzahl!: Localization;
  public nameBericht!: Localization;
  public identifier!: string;

  public isLocal!: boolean; // denotes BA is changed and not synchronized with Backend
  public hasLocalImages!: boolean; // denotes BA is changed and not synchronized with Backend
  public isDownloaded!: boolean; // denotes user chose BA to be downloaded locally

  /**
   * Default values
   */
  static fields() {
    return {
      id: this.attr(null),
      bilder: this.attr(null),
      malus: this.attr(null),
      malusColor: this.attr(null),
      name: this.attr(null),
      nameMehrzahl: this.attr(null),
      nameBericht: this.attr(null),
      identifier: this.attr(null),
      kommentar: this.attr(null),
      begehungsdatum: this.attr(null),
      archive: this.attr(null),
      matterportLink: this.attr(null),
      status: this.attr(null),
      unterschrifts: this.attr([]),
      auftraggeber: this.attr(null),
      technProjektsteuerungName: this.attr(null),
      technProjektsteuerungUnterschrift: this.attr(null),
      reihenfolge: this.attr(null),
      freigabeDatum: this.attr(null),
      isLocal: this.attr(false),
      hasLocalImages: this.attr(false),
      isDownloaded: this.attr(false),
      gutachter: this.attr(null),
      fazit: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      createdBy: this.attr(null),
      updatedBy: this.attr(null),
      bearbeitenderNutzer: this.attr(null),
      bearbeitendesGeraetId: this.attr(null),
      berichts: this.attr(null),
      mangellists: this.attr(null),
      immobilie: this.attr(null),
      // immobilie: this.hasMany(Immobilie, "bestandsaufnahme"),
      fragenblocks: this.attr([]),
    };
  }

  public copyJson(): BestandsaufnahmeJson {
    return this.$toJson() as BestandsaufnahmeJson;
  }
}

export const bestandsaufnahme = {
  state: {
    loaded: false,
    pageSize: 10,
    page: 0,
    pageCount: undefined,
    sortBy: { label: 'Begehungsdatum', fieldName: 'begehungsdatum', orderBy: 'desc'},
    searchTerm: undefined,
    stateFilter: [],
    persistedBestandsaufnahmes: undefined,
    persistedBestandsaufnahmesLoaded: false,
  },
  actions: {
    setLoaded: ({ state }: { state: any }) => {
      state.loaded = true;
    },
    setPage: ({ state }: { state: any }, page: number) => {
      state.page = page;
    },
    setPageCount: ({ state }: { state: any }, pageCount: number) => {
      state.pageCount = pageCount;
    },
    setSortBy: ({ state }: { state: any }, sortBy: string) => {
      console.log("setSortBy store Bestandsaufnahme", state.sortBy, sortBy);
      state.sortBy = sortBy;
    },
    setSearchTerm: ({ state }: { state: any }, searchTerm: string) => {
      state.searchTerm = searchTerm;
    },
    setStateFilter: ({ state }: { state: any }, stateFilter: string) => {
      state.stateFilter = stateFilter;
    },

    /**
     * Loads persisted Bestandsaufnahmes without side effects.
     * Meaning per default, BestandsaufnahmeModel.dispatch("$fetchFromLocal") also loads the bestandsaufnahmes in the BestandsaufnahmeModel itself.
     * Because we have a complex offline logic that should go hand in hand with the online functionality, we avoid any side effects in this method.
     * @param state
     */
    loadPersistedBestandsaufnahmes: async ({ state }: { state: any }) => {
      if (!state.persistedBestandsaufnahmesLoaded) {
        const { bestandsaufnahmes } = await BestandsaufnahmeModel.dispatch(
          "$fetchFromLocal"
        );
        state.persistedBestandsaufnahmes = bestandsaufnahmes;
        state.persistedBestandsaufnahmesLoaded = true;
        return bestandsaufnahmes;
      } else {
        return state.persistedBestandsaufnahmes || [];
      }
    },
    addToPersistedBestandsaufnahmes: async (
      { state }: { state: any },
      ba: BestandsaufnahmeModel
    ) => {
      if (state.persistedBestandsaufnahmesLoaded) {
        if (!state.persistedBestandsaufnahmes) {
          state.persistedBestandsaufnahmes = [];
        }
        state.persistedBestandsaufnahmes.push(ba);
      }
    },
    removeFromPersistedBestandsaufnahmes: async (
      { state }: { state: any },
      ba: BestandsaufnahmeModel
    ) => {
      if (
        state.persistedBestandsaufnahmesLoaded &&
        state.persistedBestandsaufnahmes
      ) {
        const index = state.persistedBestandsaufnahmes.findIndex(
          (el: Immobilie) => el.id === ba.id
        );
        if (index >= 0) {
          state.persistedBestandsaufnahmes.splice(index, 1);
        } else {
          console.warn(
            "Could not remove property from persisted Bestandsaufnahmes, index is ",
            index
          );
        }
      }
    },
  },
  getters: {
    loaded: (state: any) => state.loaded,
    page: (state: any) => state.page,
    pageCount: (state: any) => state.pageCount,
    sortBy: (state: any) => state.sortBy,
    searchTerm: (state: any) => state.searchTerm,
    stateFilter: (state: any) => state.stateFilter,
    persistedBestandsaufnahmes: (state: any) =>
      state.persistedBestandsaufnahmes || [],
  },
};
