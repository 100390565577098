import { useIdentifierFrageFinder, useIdentifierFragenblockFinder } from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { useStore } from '@/composables/useTypedStore';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { Frage } from '@/models/ba/Frage';
import { Fragenblock } from '@/models/ba/Fragenblock';
import { ExtendedMSERow } from '@/models/ba/interfaces/IGenericPdfDataRow';
import { APhoto } from '@/models/photo/a-photo.model';
import { Ref, computed, ref, watch } from 'vue';

export interface HzbaDetailPage {
  fragenblock: Fragenblock;
  fragenblockMSERows?: ExtendedMSERow[];
  zusammenfassung: string;
  bilder: APhoto[] | undefined;
  titleSuffix?: string;
  freieMaengelsMSERows?: ExtendedMSERow[];
  freieMaengelsMaxShownItems?: number;
  title?: string;
}
export interface MangelInputData {
  bezeichnung: string,
  bilder: APhoto[]
}
export interface EnsimimavMangelInputData {
  bezeichnung: string,
  bilder: APhoto[]
}
export interface AufmassInputData {
  gruppe: string,
  menge: string,
  bezeichnung: string,
  bilder: APhoto[]
}
export interface CustomInputData {
  titel: string,
  freitext: string,
  bilder: APhoto[],
  gewerk: string,
}

export default function useEnsimimavPdfReport(lang: string) {
  const store = useStore();

  const originalBa = computed<Bestandsaufnahme | undefined>(() => {
    return store.state.currentHzba.currentBa;
  });

  const localBa: Ref<Bestandsaufnahme | undefined> = ref();
  watch(
    () => originalBa.value,
    async (newVal) => {
      localBa.value = newVal && new Bestandsaufnahme(await newVal.copyJson());
    },
    { immediate: true },
  );
  const ba = computed<Bestandsaufnahme | undefined>(() => {
    return originalBa.value && localBa.value;
  });

  const heizzentraleTab = computed(() => {
    return ba.value?.findFragenblockByRelativePath('heizzentrale-tab');
  });

  const heizzentrale = computed(() => {
    return heizzentraleTab.value?.getMultipleFragenblockInstances('heizraum');
  });
  const allgemeinTab = computed(() => {
    return ba.value?.findFragenblockByRelativePath('allgemein-tab');
  });

  const liegenschaftDaten = computed(() => {
    return allgemeinTab.value?.getMultipleFragenblockInstances('liegenschaftDaten');
  });
  function getHeizraumZusammenfassung(heizraum: Fragenblock) {
    const frage = ba.value && heizraum.findFrageByRelativePath(ba.value, 'zusammenfassung');
    return frage?.getCurrentInput();
  }

  function getHeizraumBilder(liegenschaftDaten: Fragenblock) {
    const heizraumBilderFrage = ba.value && liegenschaftDaten.findFrageByRelativePath(ba.value, 'heizraum');

    const heizraumBilder = heizraumBilderFrage?.getCurrentInput() || [];

    while(heizraumBilder.length < 5) {
      heizraumBilder.push({ imageNotAvailable: 'Bild Heizraum' });
    }
    return heizraumBilder;
  }

  function getHeizzentraleMSERows(heizraum: Fragenblock): ExtendedMSERow[] {
    if (!ba.value) {
      return [];
    }
    return heizraum.fragenblocks.filter((el: Fragenblock) => !el.config?.isTemplate).map((el: Fragenblock) => {
      const titleSuffix = heizraum.fragenblocks && el.getIndexPosition(heizraum);

      return el.toPdfDataRow(lang, undefined, ' ' + titleSuffix);
    });
  }

  /**
   * Wärmeerzeuger
   */
  const waermeerzeugerTab = computed(() => {
    return ba.value?.findFragenblockByRelativePath('waermeerzeuger-tab');
  });
  const waermeerzeugers = computed(() => {
    return waermeerzeugerTab.value?.getMultipleFragenblockInstances('waermeerzeuger');
  });

  function getWaermeerzeugerZusammenfassung(waermeerzeuger: Fragenblock) {

    if (ba.value && useIdentifierFrageFinder(ba.value, 'hzba.waermeerzeuger-tab.waermeerzeugerzusammenfassung.zusammenfassung')) {
      const frage = ba.value && useIdentifierFrageFinder(ba.value, 'hzba.waermeerzeuger-tab.waermeerzeugerzusammenfassung.zusammenfassung');
      return frage?.getCurrentInput();  
    } 
    else if (ba.value && waermeerzeuger.findFrageByRelativePath(ba.value, 'zusammenfassung')) {
      const frage = ba.value && waermeerzeuger.findFrageByRelativePath(ba.value, 'zusammenfassung');
      return frage?.getCurrentInput();
    } 
    else { return 'undefined' }
  }

  function getWaermeerzeugerBilder(waermeerzeuger: Fragenblock) {

    const waermeerzeugerBilder = []

    // Push 3 images or fill with "not available"
    const kesselBilder = ba.value && waermeerzeuger.findFrageByRelativePath(ba.value, 'kessel-modal.kessel.bilder')?.getCurrentInput();
    kesselBilder && kesselBilder.length > 0 && waermeerzeugerBilder.push(...kesselBilder.splice(0,3));

    // If Wärmeerzeugerart = Wärmepumpe, pictures are available here:
    const waermepumpeBilder = ba.value && waermeerzeuger.findFrageByRelativePath(ba.value, 'allgemein-modal.allgemein.bilder')?.getCurrentInput();
    waermepumpeBilder && waermepumpeBilder.length > 0 && waermeerzeugerBilder.push(...waermepumpeBilder.splice(0,3));

    while(waermeerzeugerBilder.length < 3) {
      waermeerzeugerBilder.push({ imageNotAvailable: 'Bild Kessel'});
    }

    // Push 2 images from thermografie or fill with "not available"
    const optionalThermografieBilder = ba.value && waermeerzeuger.findFrageByRelativePath(ba.value, 'kessel-modal.kessel.bilder-themografie')?.getCurrentInput();
    optionalThermografieBilder && optionalThermografieBilder.length > 0 && waermeerzeugerBilder.push(...optionalThermografieBilder.splice(0,2));

    // If Wärmeerzeugerart = Wärmepumpe, pictures are available here:
    const thermografieBilderWaermepumpe = ba.value && waermeerzeuger.findFrageByRelativePath(ba.value, 'allgemein-modal.allgemein.bilder-thermografie')?.getCurrentInput();
    thermografieBilderWaermepumpe && thermografieBilderWaermepumpe.length > 0 && waermeerzeugerBilder.push(...thermografieBilderWaermepumpe.splice(0,2));

    while (waermeerzeugerBilder.length < 5) {
      waermeerzeugerBilder.push( { imageNotAvailable: 'Bild Thermografie' })
    }

    return waermeerzeugerBilder;
  }

  function getWaermeerzeugerMSERows(waermeerzeuger: Fragenblock): ExtendedMSERow[] {
    if (!ba.value) {
      return [];
    }
    return waermeerzeuger.fragenblocks.filter((el: Fragenblock) => !el.config?.isTemplate).map((el: Fragenblock) => {
      const titleSuffix = waermeerzeuger.fragenblocks && el.getIndexPosition(waermeerzeuger);

      return el.toPdfDataRow(lang, undefined, ' ' + titleSuffix);
    });
  }

  /**
   * Anlgenkomponenten
   */
  const anlagenKomponenten = computed(() => {
    return ba.value?.findFragenblockByRelativePath('anlagenkomponenten-tab');
  });
  function getAnlagenKomponentenMSERows(): ExtendedMSERow[] {
    if (!ba.value) {
      return [];
    }

    const arr = [];

    const anlagenKomponentenGroup = useIdentifierFragenblockFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten-group');
    const anlagenKomponentenGroup2 = useIdentifierFragenblockFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten2-group');

    const anlagenKomponentenFragenblocks2 = anlagenKomponentenGroup2?.fragenblocks.filter((el) => el.identifier !== 'fazit' && !el.config?.isTemplate);
    anlagenKomponentenFragenblocks2 && arr.push(...anlagenKomponentenFragenblocks2);

    const anlagenKomponentenFragenblocks = anlagenKomponentenGroup?.fragenblocks.filter((el) => el.identifier !== 'fazit' && !el.config?.isTemplate);
    anlagenKomponentenFragenblocks && arr.push(...anlagenKomponentenFragenblocks);

    return arr.map((el) => {
      const titleSuffix = anlagenKomponentenGroup && el.getIndexPosition(anlagenKomponentenGroup);
      return el.toPdfDataRow(lang, undefined, ' ' + titleSuffix);
    });
  }

  // TODO: restructure? useIdentifierFinder in useIdentifierFinder.ts currently always throws an error because it looks for each path
  // and only one can be found 
  function getAnlagenKomponentenBilder() {
    const anlagenKomponentenBilder = [];

    const bildSolar = 
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten-group.solar-modal.solar.bilderkollektoren')?.getCurrentInput() ||
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten2-group.solar-modal.solar.bilderkollektoren')?.getCurrentInput();

    const bildHauptregelung =
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten-group.hauptregelung-modal.hauptregelung.bildregelungvonvorne')?.getCurrentInput() ||
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten2-group.hauptregelung-modal.hauptregelung.bildregelungvonvorne')?.getCurrentInput();

    const bildStrangregulierventil =
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten-group.strangregulierventil-modal.strangregulierventil.bilder')?.getCurrentInput() ||
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten2-group.strangregulierventil-modal.strangregulierventil.bilder')?.getCurrentInput();

    // TODO: getBilder for 1toN Modale!!

    const bildHeizkreis = 
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten2-group.heizkreis-modal[FIRST_INSTANCE].heizkreis.bilder')?.getCurrentInput() ||
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten-group.heizkreis-modal[FIRST_INSTANCE].heizkreis.bilder')?.getCurrentInput();

    const bildWarmwasser =
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten2-group.warmwasser-modal[FIRST_INSTANCE].warmwasser.bilderwarmwasserbereitung')?.getCurrentInput() ||
    ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponenten-group.warmwasser-modal[FIRST_INSTANCE].warmwasser.bilderwarmwasserbereitung')?.getCurrentInput();

    if (bildHeizkreis && bildHeizkreis.length > 0) anlagenKomponentenBilder.push(bildHeizkreis[0]);
    else anlagenKomponentenBilder.push({ imageNotAvailable: 'Bild Heizkreis' });
    if (bildWarmwasser && bildWarmwasser.length > 0) anlagenKomponentenBilder.push(bildWarmwasser[0]);
    else anlagenKomponentenBilder.push({ imageNotAvailable: 'Bild Warmwasser' });
    if (bildSolar && bildSolar.length > 0) anlagenKomponentenBilder.push(bildSolar[0]);
    else anlagenKomponentenBilder.push({ imageNotAvailable: 'Bild Solar' });
    if (bildHauptregelung && bildHauptregelung.length > 0) anlagenKomponentenBilder.push(bildHauptregelung[0]);
    else
      anlagenKomponentenBilder.push({
        imageNotAvailable: 'Bild Hauptregelung',
      });
    if (bildStrangregulierventil && bildStrangregulierventil.length > 0) anlagenKomponentenBilder.push(bildStrangregulierventil[0]);
    else
      anlagenKomponentenBilder.push({
        imageNotAvailable: 'Bild Strangregulierventil',
      });
    return anlagenKomponentenBilder;
  }

  function getAnlagenKomponentenZusammenfassung() {
    const frage = ba.value && useIdentifierFrageFinder(ba.value, 'hzba.anlagenkomponenten-tab.anlagenkomponentenzusammenfassung.zusammenfassung');
    return frage?.getCurrentInput();
  }

  /**
   * Custom input Felder
   */
  const uebersichtTab = computed(() => {
    return ba.value?.findFragenblockByRelativePath('uebersicht-tab');
  });
  /**
   * Prepares the data for the custom input page that is shown right after maengels.
   */
  function groupMangelInputData(): MangelInputData[] {

    const mangelInputWrapper = ba.value && useIdentifierFragenblockFinder(ba.value, 'ensimimav.maengel-tab.custom-input-group');

    const mangelInputFragenblocks = mangelInputWrapper?.getMultipleFragenblockInstances('custom-input-modal');

    const preparedData = mangelInputFragenblocks?.map<MangelInputData>(fragenblock => {
      const mangelBlock = ba.value && fragenblock.findFragenblockByRelativePath(ba.value, 'mangel');
      if (!mangelBlock) { throw "Fragenblock 'custom-input-modal.mangel' not found but expected." }

      const bezeichnungFrage = ba.value && mangelBlock.findFrageByRelativePath(ba.value, 'custom-bezeichnung');
      const bilderFrage = ba.value && mangelBlock.findFrageByRelativePath(ba.value, 'custom-bilder');

      return {
        bezeichnung: bezeichnungFrage?.getCurrentInput(),
        bilder: bilderFrage?.getCurrentInput(),
      }
    })
    console.log("preparedData mängel",preparedData)

    return preparedData || [];
  }

  function groupEnsimimavMangelInputData(): EnsimimavMangelInputData[] {
    const ensimimavMangelInputWrapper = ba.value && useIdentifierFragenblockFinder(ba.value, 'ensimimav.maengel-ensimimav-tab.custom-input-group');

    const ensimimavMangelInputFragenblocks = ensimimavMangelInputWrapper?.getMultipleFragenblockInstances('custom-input-modal');

    const preparedData = ensimimavMangelInputFragenblocks?.map<EnsimimavMangelInputData>(fragenblock => {
      const mangelBlock = ba.value && fragenblock.findFragenblockByRelativePath(ba.value, 'mangel');
      if (!mangelBlock) { throw "Fragenblock 'custom-input-modal.mangel' not found but expected." }

      const bezeichnungFrage = ba.value && mangelBlock.findFrageByRelativePath(ba.value, 'custom-bezeichnung');
      const bilderFrage = ba.value && mangelBlock.findFrageByRelativePath(ba.value, 'custom-bilder');
      return {
        bezeichnung: bezeichnungFrage?.getCurrentInput(),
        bilder: bilderFrage?.getCurrentInput(),
      }
    })
        return preparedData || [];
  }
  function groupAufmassInputData(): AufmassInputData[] {
    const ensimimavAufmassInputWrapper = ba.value && useIdentifierFragenblockFinder(ba.value, 'ensimimav.aufmass-tab.custom-input-group');

    const ensimimavAufmassInputFragenblocks = ensimimavAufmassInputWrapper?.getMultipleFragenblockInstances('custom-input-modal');

    const preparedData = ensimimavAufmassInputFragenblocks?.map<AufmassInputData>(fragenblock => {
      const aufmassBlock = ba.value && fragenblock.findFragenblockByRelativePath(ba.value, 'aufmass');
      if (!aufmassBlock) { throw "Fragenblock 'custom-input-modal.mangel' not found but expected." }

      const gruppeFrage = ba.value && aufmassBlock.findFrageByRelativePath(ba.value, 'custom-gruppe');
      const mengeFrage = ba.value && aufmassBlock.findFrageByRelativePath(ba.value, 'custom-menge');
      const bezeichnungFrage = ba.value && aufmassBlock.findFrageByRelativePath(ba.value, 'custom-bezeichnung');
      const bilderFrage = ba.value && aufmassBlock.findFrageByRelativePath(ba.value, 'custom-bilder');

      return {
        gruppe: gruppeFrage?.getCurrentInput(),
        menge: mengeFrage?.getCurrentInput(),
        bezeichnung: bezeichnungFrage?.getCurrentInput(),
        bilder: bilderFrage?.getCurrentInput()
      }
    })
    return preparedData || [];
  }
  /**
   * Prepares the data for the custom input page that is shown right after maengels.
   */
  function groupCustomInputData(): CustomInputData[] {
    const customInputWrapper = ba.value && useIdentifierFragenblockFinder(ba.value, 'hzba.uebersicht-tab.custom-input-group');

    const customInputFragenblocks = customInputWrapper?.getMultipleFragenblockInstances('custom-input-modal');

    const preparedData = customInputFragenblocks?.map<CustomInputData>(fragenblock => {
      const hinweisBlock = ba.value && fragenblock.findFragenblockByRelativePath(ba.value, 'hinweis');
      if (!hinweisBlock) { throw "Fragenblock 'custom-input-modal.hinweis' not found but expected." }

      const titelFrage = ba.value && hinweisBlock.findFrageByRelativePath(ba.value, 'custom-title');
      const freitextFrage = ba.value && hinweisBlock.findFrageByRelativePath(ba.value, 'custom-freitext');
      const bilderFrage = ba.value && hinweisBlock.findFrageByRelativePath(ba.value, 'custom-bilder');
      const gewerkFrage = ba.value && hinweisBlock.findFrageByRelativePath(ba.value, 'custom-gewerk');

      return {
        titel: titelFrage?.getCurrentInput(),
        freitext: freitextFrage?.getCurrentInput(),
        bilder: bilderFrage?.getCurrentInput(),
        gewerk: gewerkFrage?.getCurrentInput(),
      }
    })

    return preparedData || [];
  }

  /**
   * Return a computed property for customInput's page.
   */
  const customInputs = computed(() => { return ba.value && groupCustomInputData(); })

    /**
   * Return a computed property for mangels's page.
   */
    const mangelInputs = computed(() => { return ba.value && groupMangelInputData(); })

  /**
   * Return a computed property for ensimimav mangels's page.
   */
    const mangelInputsEnsimimav = computed(() => { return ba.value && groupEnsimimavMangelInputData(); })

  /**
   * Return a computed property for aufmass's page.
   */
    const aufmassInputsEnsimimav = computed(() => { return ba.value && groupAufmassInputData(); })


  /**
   * Unterstation
   */
  const unterstationVorhanden = computed(() => {
    return ba.value && useIdentifierFrageFinder(ba.value, 'hzba.unterstation-tab.allgemein.vorhanden')?.getCurrentInput();
  })
  const unterstation = computed(() => {
    return unterstationVorhanden.value ? ba.value?.findFragenblockByRelativePath('unterstation-tab.unterstation') : null;
  });

  function getUnterstationZusammenfassung() {
    const frage = ba.value && useIdentifierFrageFinder(ba.value, 'hzba.unterstation-tab.unterstationzusammenfassung.zusammenfassung');
    return frage?.getCurrentInput();
  }
  function getUnterstationMSERows(): ExtendedMSERow[] {
    if (!ba.value) {
      return [];
    }
    const unterstation = useIdentifierFragenblockFinder(ba.value, 'hzba.unterstation-tab.unterstation');

    return unterstation?.fragenblocks.map((el) => {
      const titleSuffix = unterstation.fragenblocks && el.getIndexPosition(unterstation);
      return  el.toPdfDataRow(lang, undefined, ' ' + (Number(titleSuffix)+1));
    }) || [];
  }

  function getUnterstationBilder() {
    const unterstationBilderFrage = ba.value && useIdentifierFrageFinder(ba.value, 'hzba.unterstation-tab.allgemein.bilder');

    let bilder = unterstationBilderFrage?.getCurrentInput();
    if (!bilder) { bilder = []; }

    while (bilder.length < 5) {
      bilder.push({ imageNotAvailable: 'Bild Unterstation'})
    }
    return bilder;
  }

  /**
   * Nachspeisung
   */
  const nachspeisung = computed(() => {
    return ba.value?.findFragenblockByRelativePath('nachspeisung-tab');
  });
  function getNachspeisungZusammenfassung() {
    const frage = ba.value && useIdentifierFrageFinder(ba.value, 'hzba.nachspeisung-tab.nachspeisungzusammenfassung.zusammenfassung');
    return frage?.getCurrentInput();
  }
  function getNachspeisungMSERows(): ExtendedMSERow[] {
    if (!ba.value) {
      return [];
    }
    const nachspeisung = useIdentifierFragenblockFinder(ba.value, 'hzba.nachspeisung-tab.nachspeisung');
    return nachspeisung?.fragenblocks.map((el) => el.toPdfDataRow(lang)) || [];
  }

  function getNachspeisungBilder() {
    const nachspeisungBilderFrage = ba.value && useIdentifierFrageFinder(ba.value, 'hzba.nachspeisung-tab.nachspeisung.allgemein-modal.allgemein.bilder');
    const tempBilder = nachspeisungBilderFrage?.getCurrentInput() || [];
    let nachspeisungBilder = [];
    let odd = true;
    let count = 0;

    //TODO: refactor
    if (tempBilder.length === 0) {
      while(nachspeisungBilder.length < 5) {
        nachspeisungBilder.push({ imageNotAvailable: 'Bild Nachspeisung' });
      }
    }
     if (tempBilder.length < 4) {
      while(nachspeisungBilder.length < 5) {
        if(odd){
          nachspeisungBilder.push(tempBilder[count]);  
          count++;
        } else {
          nachspeisungBilder.push({ imageNotAvailable: 'Bild Nachspeisung' });
        }
        odd = !odd;
      }
    }
    else if (tempBilder.length === 4) {
    nachspeisungBilder = tempBilder;
    nachspeisungBilder.push({ imageNotAvailable: 'Bild Nachspeisung' });
    }
    else {
      nachspeisungBilder = tempBilder;
    }
    return nachspeisungBilder;
  }

  /**
   * Termine
   */
  const termineTab = computed(() => {
    return ba.value?.findFragenblockByRelativePath('termine-tab');
  });

  const termine = computed(() => {
    const termineFragenblocks = termineTab.value?.getMultipleFragenblockInstances('anstehender-termin');

    return (
      termineFragenblocks?.map((termin) => {
        const massnahme = termin.findFrage('massnahme') || termin.findFrage('maßnahme');

        return {
          date: termin.findFrage('datum')?.getCurrentInput(),
          topic: termin.findFrage('artdermassnahme')?.getCurrentInput(),
          description: massnahme?.getCurrentInput(),
        };
      }) || []
    );
  });

  /**
   * Table of Content
   * Heizzentrale,
   * Wärmeerzeuger,
   * Anlagenkomponenten
   * Unterstation,
   * Nachspeisung,
   * Hinweise (custom input)
   */
  const tableOfContentFragenblocks = computed(() => {
    const arr: Fragenblock[] = [];
    heizzentraleTab.value && arr.push(heizzentraleTab.value);
    waermeerzeugerTab.value && arr.push(waermeerzeugerTab.value);
    anlagenKomponenten.value && arr.push(anlagenKomponenten.value);
    unterstation.value && unterstationVorhanden && arr.push(unterstation.value);
    nachspeisung.value && arr.push(nachspeisung.value);
    return arr;
  });

  /**
   * Used to build the detailed pages:
   * Heizzentrale,
   * Waermeerzeuger 1,
   * Waermeerzeuger 2,
   * ...
   * Unterstation
   * Nachspeisung
   */
  const detailPages = computed<HzbaDetailPage[]>(() => {
    const arr: HzbaDetailPage[] = [];
   
    heizzentrale.value?.forEach((el) => {
      arr.push({
        fragenblock: el,
        fragenblockMSERows: getHeizzentraleMSERows(el),
        zusammenfassung: getHeizraumZusammenfassung(el),
        bilder: getHeizraumBilder(el)?.slice(0, 5),
        freieMaengelsMSERows: el
          ?.getFiredMaengel({
            freieMangelsOnly: true,
            relevantMangelsOnly: true,
          })
          ?.map((el) => {
            return el.getMSERow();
          }),
        freieMaengelsMaxShownItems: 2,
        titleSuffix: ' ' + el?.arrayPosition,
      });
    }) 

    waermeerzeugers.value?.forEach((el) => {
      arr.push({
        fragenblock: el,
        fragenblockMSERows: getWaermeerzeugerMSERows(el),
        zusammenfassung: getWaermeerzeugerZusammenfassung(el),
        bilder: getWaermeerzeugerBilder(el),
        freieMaengelsMSERows: el
          ?.getFiredMaengel({
            freieMangelsOnly: true,
            relevantMangelsOnly: true,
          })
          ?.map((el) => {
            return el.getMSERow();
          }),
        freieMaengelsMaxShownItems: 2,
        titleSuffix: ' ' + el?.arrayPosition,
      });
    });

    anlagenKomponenten.value &&
      arr.push({
        fragenblock: anlagenKomponenten.value,
        fragenblockMSERows: getAnlagenKomponentenMSERows(),
        zusammenfassung: getAnlagenKomponentenZusammenfassung(),
        bilder: getAnlagenKomponentenBilder()?.slice(0, 5),
        freieMaengelsMSERows: anlagenKomponenten.value
          ?.getFiredMaengel({
            freieMangelsOnly: true,
            relevantMangelsOnly: true,
          })
          ?.map((el) => {
            return el.getMSERow();
          }),
        freieMaengelsMaxShownItems: 2,
      });

    unterstation.value &&
      unterstationVorhanden.value &&
      arr.push({
        fragenblock: unterstation.value,
        fragenblockMSERows: getUnterstationMSERows(),
        zusammenfassung: getUnterstationZusammenfassung(),
        bilder: getUnterstationBilder(),
      });

    nachspeisung.value &&
      arr.push({
        fragenblock: nachspeisung.value,
        fragenblockMSERows: getNachspeisungMSERows(),
        zusammenfassung: getNachspeisungZusammenfassung(),
        bilder: getNachspeisungBilder(),
        title: 'Nachspeisung'
      });

    return arr;
  });

  const extractModalsFrom1NModals = (
    ba: Bestandsaufnahme,
    modalIdentifier: string
  ) => {
    const modal = useIdentifierFragenblockFinder(ba, modalIdentifier);

    const modale = modal?.fragenblocks?.filter(
      (block) => !block.config.isTemplate
    );

    return modale
      ?.map((modal) => {
        return modal.fragenblocks.map((fragenBlock) => {
          return useIdentifierFrageFinder(ba, fragenBlock.path);
        });
      })
      .flat(1);
  };

  function getInputFromFrage(frage: Frage){
    switch (frage.eingabeTyp) {
      case 'Text':
        return frage.getCurrentInput();
      case 'Datum':
        return frage.getCurrentInput()?.replaceAll('-', '.');
      case 'Uhrzeit':
        return frage.getCurrentInput();
      case 'Zahl':
        return frage.getCurrentInput();
      case 'Textblock':
        return frage.getCurrentInput();
      case 'Boolean':
        if(frage.eingabeBoolean){
          return 'Ja';
        }else{
          return 'Nein';
        }
      case 'Medien':
        return frage.eingabeMedien;
      case 'MehrfachAuswahl':
        return frage.getSelectedAuswahlLabelsString();
      case 'Auswahl':
        return frage.getSelectedAuswahlLabelsString();
      default:
        return frage.getCurrentInput();
    }
  }

  const getHeizkreise = (parameterBa: Bestandsaufnahme) => {

    const heizrkeiseTab = parameterBa.findFragenblockByRelativePath('heizkreis-tab');
    const heizkreise = heizrkeiseTab?.getMultipleFragenblockInstances('heizkreis');

    const res: any[] = [];

    if(heizkreise !== undefined){
      heizkreise.forEach((heizkreis: any) => {

        // Regular frages
        const regularFrages: any = {};
        heizkreis?.fragenblocks[0]?.fragenblocks[0]?.frages?.forEach((frage: any) => {
          regularFrages[frage?.identifier] = getInputFromFrage(frage);
        });

        // Custom inputs
        const customFrages: any[]= [];
        let i = 0;
        heizkreis?.fragenblocks[0]?.fragenblocks[1]?.fragenblocks.forEach((customModal: any) => {
          if(i != 0){
            const customBlock: any = {};
            const frages = customModal?.fragenblocks[0]?.frages;

            for (let j = 0; j < frages.length - 1; j++) {
              const frage = frages[j];
              customBlock[frage?.identifier as keyof any] = getInputFromFrage(frage);
            }
            customFrages.push(customBlock);
          }
          i++;
        });
        

        res.push({'regularFrages': regularFrages, 'customFrages': customFrages});
      });
    }
    
    return res;
  };
  const getHeizkreisePages = (parameterBa: Bestandsaufnahme) => {
    const res = getHeizkreise(parameterBa);

    const maxSpace = 100;
    const spaceForCustomFragerow = 10;

    const heizkreise: any[] = [];
    let currentHeizkreis: any = {
      'regularFrages': null,
      'customParametersPages': []
    };

    res?.forEach(heizkreis => {

      currentHeizkreis = {
        'regularFrages': null,
        'customParametersPages': []
      };
      currentHeizkreis['regularFrages'] = heizkreis['regularFrages'];
      let currentOccupiedSpace = 0;

      let currentCustomFragerowPage: any[] = [];
      heizkreis['customFrages'].forEach((customFragerow: any) => {

        if(currentOccupiedSpace + spaceForCustomFragerow < maxSpace){
          currentCustomFragerowPage.push(customFragerow);
          currentOccupiedSpace += spaceForCustomFragerow;
        }else{
          currentHeizkreis['customParametersPages'].push(currentCustomFragerowPage);
          currentOccupiedSpace = 0;
          currentCustomFragerowPage = [];
          currentCustomFragerowPage.push(customFragerow);
          currentOccupiedSpace += spaceForCustomFragerow;
        }
      });
      
      currentHeizkreis['customParametersPages'].push(currentCustomFragerowPage);
      heizkreise.push(currentHeizkreis);

    });
    
    return heizkreise;
  }

  function getWaermeverteilung(parameterBa: Bestandsaufnahme) {
    const waermeverteilung: any = {};

    let waermeverteilungGroup;
    if(parameterBa !== undefined){
      waermeverteilungGroup = useIdentifierFragenblockFinder(parameterBa, 'ensimimav.waermeverteilung-tab.waermeverteilung');
    }

    const rohrisolierung = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, 'waermeverteilung_rohrisulierung');
    waermeverteilung['rohrisolierung'] = getInputFromFrage(rohrisolierung as Frage);
    const rohrisolierungBilder = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, 'waermeverteilung_rohrisulierung_foto');
    waermeverteilung['rohrisolierung_foto'] = getInputFromFrage(rohrisolierungBilder as Frage);
    const armaturenisolierung = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, 'waermeverteilung_armaturenisolierung');
    waermeverteilung['armaturenisolierung'] = getInputFromFrage(armaturenisolierung as Frage);
    const armaturenisolierungBilder = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "waermeverteilung_armaturenisolierung_foto");
    waermeverteilung["armaturenisolierung_foto"] = getInputFromFrage(armaturenisolierungBilder as Frage);
    const armaturenisolierungWasser = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "waermeverteilung_armaturenisolierung_wasser");
    waermeverteilung["armaturenisolierung_wasser"] = getInputFromFrage(armaturenisolierungWasser as Frage);
    const armaturenisolierungWasserBilder = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "waermeverteilung_armaturenisolierung_wasser_foto");
    waermeverteilung['armaturenisolierung_wasser_foto'] = getInputFromFrage(armaturenisolierungWasserBilder as Frage);
    // todo: refactor
    const strangregulierventileVorhanden = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "strangregulierventile-vorhanden");
    const vorhandenFlag = getInputFromFrage(strangregulierventileVorhanden as Frage) === "Ja" ? true : false ;
    if(vorhandenFlag){
      const strangregulierventile = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "strangregulierventile-einstellbar");
      waermeverteilung["strangregulierventile"] = getInputFromFrage(strangregulierventile as Frage) === "Ja" ? "Einstellbar" : "Nicht Einstellbar" ;
    } else {
      waermeverteilung["strangregulierventile"] = "Nicht Vorhanden" ;
    }

    const strangregulierventileBilder = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "waermeverteilung_strangregulierventile_foto1");
    waermeverteilung["strangregulierventile_foto"] = getInputFromFrage(strangregulierventileBilder as Frage);
    const anzahlheizungsstraenge = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "waermeverteilung_anzahlheizungsstraenge");
    waermeverteilung['anzahlheizungsstraenge'] = getInputFromFrage(anzahlheizungsstraenge as Frage);
    const anzahlwarmwassertraenge = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "waermeverteilung_anzahlwarmwassertraenge");
    waermeverteilung['anzahlwarmwassertraenge'] = getInputFromFrage(anzahlwarmwassertraenge as Frage);
    const bemerkungen = parameterBa && waermeverteilungGroup?.findFrageByRelativePath(parameterBa, "bemerkungen");
    waermeverteilung['bemerkungen'] = getInputFromFrage(bemerkungen as Frage);

    return waermeverteilung;
  }



  return {
    ba,
    heizzentrale,
    waermeerzeugers,
    waermeerzeugerTab,
    anlagenKomponenten,
    unterstation,
    nachspeisung,
    detailPages,
    termine,
    tableOfContentFragenblocks,
    uebersichtTab,
    customInputs,
    mangelInputs,
    mangelInputsEnsimimav,
    aufmassInputsEnsimimav,
    getHeizkreisePages,
    getWaermeverteilung
  };
}
