

import { getPhotoUrl } from "@/utilities/get-media-url";
import {
  IonPage,
  IonContent,
  IonButtons,
  IonIcon,
  IonButton, onIonViewWillEnter, onIonViewDidLeave, onIonViewWillLeave,
} from "@ionic/vue";
import {computed, onMounted, watch, ref} from "vue";
import Toolbar from "@/components/Navigation/Toolbar";
import useBestandsaufnahmeSubpage from "@/composables/Bestandsaufnahme/useBestandsaufnahmeSubpage";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import useMatterportTagManagement from "@/composables/useMatterportTagManagement";
import { useStore } from "@/composables/useTypedStore";
import {Mangelzuordnung} from "@/models/ba/Mangelzuordnung";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
export default {
  name: "matterport.vue",
  components: {
    HzbaGroup,
    IonPage,
    IonContent,
    IonButton,
    Toolbar,
    IonButtons,
    IonIcon,
  },
  setup() {
    const currentMangel = ref();
    const store = useStore();
    let isMPready = false;
    let didSetup = false;

    const showOnlyUnfinished = ref(false);

    // Hzba Detail Page View Setup
    const { isHzbaLoaded, ba, goBackUrl } = useBestandsaufnahmeSubpage({ onIonViewWillEnter, onIonViewWillLeave });
    const maengel = computed(() => { return ba.value?.getFiredMaengel() })

    const mergedAndFilteredMaengel = computed(() => {
      if (showOnlyUnfinished.value) {
        return maengel.value?.filter(el => el.tag3d)
      }
      return maengel.value
    })

    // Matterport Setup
    const modelSid = "ELw3aQ5i69b";
    const mpTagManagement = useMatterportTagManagement(modelSid);

    const findEditableMangelzuordnung = (mangel: Mangelzuordnung): Mangelzuordnung | undefined => {
      return ba.value?.getFiredMaengel().find(m => {
        return mangel.id === m.id; // TODO reactivate here && mangel.uid === m.uid && mangel.itemUid === m.itemUid
      })
    }

    mpTagManagement.setTagSetListener((pos: any) => {
      const mangel = currentMangel.value;
      const mangelzuordnung = mangel && findEditableMangelzuordnung(mangel)

      if (!mangelzuordnung) {
        console.error('No mangelzuordnung found.')
        return;
      }

      if (!mangelzuordnung.tag3d) { mangelzuordnung.tag3d = {} }
      mangelzuordnung.tag3d = pos;


      store.dispatch('currentHzba/saveHzbaLocally');
    })

    function addMangelTag(mangel: Mangelzuordnung) {
      if (!mangel) { return;}

      currentMangel.value = mangel;

      console.log(currentMangel);

      mpTagManagement.startAddTag({
        label: mangel.titel(),
        description: `${mangel.maluspunkte()} MP\n${mangel.kategorie()}`,
        media: {
          type: "mattertag.media.photo",
          src: getPhotoUrl(currentMangel.value.bilder[0])
        }
      })
    }

    function removeMangelTag(mangel: Mangelzuordnung) {
      if (!mangel || !mangel.tag3d) { return; }

      mpTagManagement.removeTag(mangel.tag3d.sid)

      const m = findEditableMangelzuordnung(mangel);

      // Remove tag3d but keep the id from the strapi backend
      // const id = m.tag3d.id;
      // m.tag3d = { id };
      if (m) {
        m.tag3d = undefined;
      }

      store.dispatch('currentHzba/saveHzbaLocally');
    }


    const setupTags = () => {

      if (maengel.value && maengel.value.length > 0 && isMPready && !didSetup) {
        didSetup = true;
        const maengelWithMatterport = maengel.value.filter(m => m.tag3d);
        const tags = maengelWithMatterport.map(m => JSON.parse(JSON.stringify(m.tag3d)));

        console.log("onMPReady", tags);

        mpTagManagement.setupTags(tags, (sids: any) => {
          for (let i = 0; i < sids.length; i++) {
            const m = findEditableMangelzuordnung(maengelWithMatterport[i]);
            if (m) {
              m.tag3d.sid = sids[i]
            }
          }
        });
      }
    }

    watch(() => maengel, (newVal, oldVal) => {
      setupTags();
    })

    onMounted(() => {

      const onMPReady = () => {
        isMPready = true;
        setupTags();
      }

      mpTagManagement.mountMe(onMPReady);
    })


    const { syncBestandsaufnahme, submitBestandsaufnahme } = useBestandsaufnahmeUpload();


    return {
      addMangelTag,
      currentMangel,
      removeMangelTag,
      getPhotoUrl,
      showOnlyUnfinished,
      maengel,
      mergedAndFilteredMaengel,
      syncBestandsaufnahme,
    }
  }
}
