
import useScreenSize from "@/composables/useScreenSize";
import Property from "@/models/immobilie.model";
import { getLocalMetaData } from "@/models/local-meta-data";
import {
IonButton,
IonCard,
IonCardHeader,
IonCardSubtitle,
IonCardTitle,
IonIcon,
IonSlide,
IonSlides
} from "@ionic/vue";
import { arrowBack, locationOutline } from "ionicons/icons";
import _ from 'lodash';
import moment from "moment";
import { PropType, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { usePropertyMap } from "../../composables/usePropertyMap";
import PropertyCard from "./PropertyCard.vue";

export default defineComponent({
  name: "PropertiesMap",
  components: {
    IonSlides,
    IonSlide,
    IonCard,
    IonCardTitle,
    IonButton,
    IonCardSubtitle,
    IonCardHeader,
    IonIcon,
    PropertyCard,
  },
  props: {
    properties: {
      type: Object as PropType<Property[]>,
      required: true,
    },
  },
emits: ['goBack'],
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const propertyMap = usePropertyMap("map-root", props.properties);
    const swiper = ref();
    const activeSlide = ref();
    const hoveredProp = ref();
    const mapEl = ref();
    const router = useRouter();
    const changesLoading = ref(false);
    const propertySlideList = ref([]);


    const slideNext = (async () => {
      const currentIndex = await swiper.value.$el.getActiveIndex();
      if (currentIndex <= props.properties.length-1) {
        swiper.value.$el.slideNext();
      } else {
        swiper.value && swiper.value.$el.slideTo(1);
      }
      await slideChanged({ target: swiper.value.$el });
    })
    const slidePrev = (async () => {
      const currentIndex = await swiper.value.$el.getActiveIndex();
      if (currentIndex > 0) {
        swiper.value.$el.slidePrev();
      } else {
        swiper.value && swiper.value.$el.slideTo(props.properties.length-1);
      }
        await slideChanged({ target: swiper.value.$el });
    })
    const slideChanged = async (event: any) => {
      const index = await event.target.getActiveIndex();

      activeSlide.value = (index-1) % (props.properties.length);
      if (activeSlide.value === -1) {
        activeSlide.value = props.properties.length-1
      }

      await propertyMap.animateToProperty(props.properties[activeSlide.value]);
    }

    // propertyMap.setPropertyPointClickedListener((prop: any) => {
    //   const index = props.properties.findIndex(p => p.id === prop.id);
    //   swiper.value && swiper.value.$el.slideTo(index);
    //   propertyMap.animateToProperty(prop);
    // })
    //
    // propertyMap.setPropertyPointHoveredListener((prop: any) => {
    //   hoveredProp.value = prop;
    // })

    watch(() => props.properties, (newVal) => {
      const debouncedFctn = _.debounce(() => {
        propertySlideList.value = JSON.parse(JSON.stringify(props.properties));
        changesLoading.value = true;
        setTimeout(() => { changesLoading.value = false}, 400)
      }, 600)

      debouncedFctn();
      propertyMap.updateProperties(newVal);
    })

    setTimeout(() => {
      propertySlideList.value = JSON.parse(JSON.stringify(props.properties));

      propertyMap.reload()

      const interval = setInterval(() => {
        const mapWidth =  document.getElementById('map-root')?.clientWidth;
        const mapHeight = document.getElementById('map-root')?.clientHeight;

        if (mapWidth && mapHeight && mapWidth > 0 && mapHeight > 0) {
          console.log("Map already loaded.", mapWidth, mapHeight)
          propertyMap.reload();
          clearInterval(interval);
        } else {
          console.log("Map not loaded yet. Reloading...", mapHeight, mapWidth);
          propertyMap.reload();
        }
      }, 100)

    }, 250)

    const { isMinLarge } = useScreenSize();


    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData('lastBackendFetch'));
      return `${date.format('DD.MM.YYYY')} um ${date.format('HH:mm:ss')}`
    });


    const cardClicked = (prop: any) => {
      hoveredProp.value = prop;
      propertyMap.animateToProperty(prop);
    }

    const openProperty = (prop: any) => {
      const routeData = router.resolve({ path: `/property/${prop.id }` });
      window.open(routeData.href, '_blank');
    }

    /**
     * Workaround as ionic would have a weird bug that you cannot click on the card otherwise when using infinite loop
     */
    const jumpToEnd = () => {
      swiper.value.$el.slideTo(props.properties.length)
    }
    const jumpToStart = () => {
      swiper.value.$el.slideTo(1)
    }

    return {
      locationOutline,
      slideChanged,
      swiper,
      activeSlide,
      slideNext,
      slidePrev,
      isMinLarge,
      lastFetch,
      cardClicked,
      hoveredProp,
      arrowBack,
      changesLoading,
      jumpToEnd,
      jumpToStart,
      openProperty,
      propertySlideList,
      t,
      propertyMap,
    }
  },
});
