
import { addCircle } from 'ionicons/icons';
import { duplicateOutline, trashOutline , addOutline } from 'ionicons/icons';
import {
  IonSelect,
  IonIcon,
  IonSelectOption,
  IonButton,
} from "@ionic/vue";
import {popoverHelper} from "@/utilities/popover-helper";
import {computed} from "vue";
import useScreenSize from "@/composables/useScreenSize";
import {store} from "@/store/store";

import {defineComponent} from "vue";

export default defineComponent({
  name: "HzbaGroupCtas",
  components: {
    IonSelectOption,
    IonSelect,
    IonIcon,
    IonButton
  },
  props: {
    hideAddButton: {
      type: Boolean,
      default: false
    },
    onAdd: {
      type: Function,
      required: false
    },
  },
  emits: ['add', 'duplicate', 'delete'],
  setup() {

    const screenSize = useScreenSize();

    const largeIcons = computed(() => {
      return screenSize.screenWidth.value >= screenSize.screenTypes.SCREEN_LG_START
    })

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );


    return {
      addCircle,
      isReadonly,
      openPopover: popoverHelper,
      duplicateOutline,
      trashOutline,
      addOutline,
      screenSize,
      largeIcons
    }
  }
})
