
import { useI18n } from 'vue-i18n';
import {
  IonButton,
  IonIcon,
    IonSearchbar,
} from "@ionic/vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "Searchbar",
  components: {
    IonButton,
    IonIcon,
    IonSearchbar,
  },
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    toolbarMode: {
      type: Boolean,
      default: false
    }
  },
   setup(_, params) {


    const { t } = useI18n({ useScope: 'global' })
    return {
      t
    };
   }
})

