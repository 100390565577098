import { Model } from "@vuex-orm/core";

export default class EnumEingabewerte extends Model {

    static entity = "enum-eingabewerte";

    static fields() {
        return {
            werte: this.attr(null),
            name: this.attr(null)
        }
    }

    werte!: string[] | { [key: string]: string[] };
    name!: string;
}