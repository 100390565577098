import {computed} from "vue";
import {useStore} from "@/composables/useTypedStore";
import {APhotoUploadTask, BestandsaufnahmePhotoUploadTask} from "@/api/UploadApi";
import {instanceOfPhoto, isStrapiPhoto} from "@/utilities/get-media-url";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {generateUUID} from "@ionic/cli/lib/utils/uuid";
import {UploadBaPhotos} from "@/composables/Upload/UploadBaPhotos";

export default function useUploadImageStatus() {
    const store = useStore();

    const ba = computed<Bestandsaufnahme | undefined>(() => {
        return store.state.currentHzba.currentBa;
    });

    const photoUploads = computed<UploadBaPhotos | undefined>(() => {
        return store.state.currentHzba.photoUploads;
    });

    const currentImageUploadCounter = computed(() => {
        let successfulUploads = 0;
        let total = 0;

        photoUploads?.value?.getTotalTasks().forEach((upload) => {
            const status = upload.status;
            if (status === "success") { successfulUploads++; }
            if (status === "uploading" || status === 'success') { total++; }
        });

        return {
            uploading: (total - successfulUploads),
            uploaded: successfulUploads,
            total,
        }
    })

    const currentSuccessfulUploads = () => { return currentImageUploadCounter.value.uploaded }
    const currentTotalUploads = () => { return currentImageUploadCounter.value.total }

    const currentUploadPercentage = computed(() => {
        const currentUploadC = currentImageUploadCounter.value;
        return currentUploadC.uploaded / currentUploadC.total
    });


    const totalImageUploadCounter = computed(() => {
        //keep next line to keep this computed prop always updated.
        //Otherwise vue will not notice changes during image upload
        const test = photoUploads.value;

        const frages = ba.value?.getFrages();

        const fragePhotos = frages?.map(frage => frage.eingabeMedien).flat(1).filter(photo => !!photo);
        const mangelPhotos = ba.value?.getFiredMaengel().map(mangel => mangel.eingabeMedien).flat(1).filter(photo => !! photo)

        const photos = [];
        if (fragePhotos) { photos.push(...fragePhotos); }
        if (mangelPhotos) { photos.push(...mangelPhotos); }

        const uploaded = (photos ?? []).filter((photo) => isStrapiPhoto(photo));

        return {
            total: photos?.length || 0,
            uploaded: uploaded.length || 0,
            toUpload: uploaded.length - ((photos && photos.length) || 0)
        }
    })
    const totalSuccessfulUploads = () => { return totalImageUploadCounter.value.uploaded }
    const totalToUploadImages = () => { return totalImageUploadCounter.value.uploaded }
    const totalImages = () => { return totalImageUploadCounter.value.total }


    return {
        currentUploadPercentage,
        currentImageUploadCounter,
        currentSuccessfulUploads,
        currentTotalUploads,

        totalImageUploadCounter,
        totalToUploadImages,
        totalImages,
        totalSuccessfulUploads,
    }
}