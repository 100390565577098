import { APhoto } from '@/models/photo/a-photo.model';
import { Model } from '@vuex-orm/core';

export default class LocalMetaData extends Model {
    static entity = 'localMetaData';
    static primaryKey = 'id';

    static fields() {
        return {
            id: this.attr(this.number),
            lastBackendFetch: this.attr(''),
            backendBuildVersion: this.attr(''),
        };
    }

    id!: string;
    lastBackendFetch!: string;
    backendBuildVersion!: string;
}

export const getLocalMetaData = (key: string) => {
    const local: any = LocalMetaData.find('localMetaData');

    if (local){
        return local[key];
    }
    return undefined;
}


export const setLocalMetaData = async (key: string, value: any) => {
    const local: any = LocalMetaData.find('localMetaData');

    if (!local) {
        await LocalMetaData.insert({
            data: [
                { id: 'localMetaData' },
            ]
        })
    }

    const updateJson: any = {};
    updateJson[key] = value;

    await LocalMetaData.update({
        where: 'localMetaData',
        data: updateJson
    })
}