
import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  onIonViewWillEnter,
} from "@ionic/vue";

import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import HzbaPhotoInput from "@/components/hzba/Base/Input/HzbaPhotoInput";
import HzbaTextareaInput from "@/components/hzba/Base/Input/HzbaTextareaInput";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput";
import { useI18n } from "vue-i18n";
import { computed, PropType } from "vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput.vue";
import HzbaSelectInput from "@/components/hzba/Base/Input/HzbaSelectInput.vue";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import {
  MangelGewerk,
  MangelKategorie,
  MangelProduktTyp, MangelQuelle,
  MangelZeitpunkt,
} from "@/models/ba/Mangelvorlage";
import { TEXTBLOCK_ALLOWED_CHAR_LIMIT } from "@/models/ba/Frage";
import { MangelConfig } from "@/models/ba/interfaces/IMangelzuordnung";

export default {
  components: {
    HzbaSelectInput,
    HzbaBaseInput,
    InputError,
    HzbaPhotoInput,
    IonItem,
    HzbaTextareaInput,
    HzbaChooseInput,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
  },
  props: {
    mangel: {
      type: Object as PropType<Mangelzuordnung>,
      default: undefined,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: null,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    mangelConfig: {
      type: Object as PropType<MangelConfig>,
      default: undefined,
    },
    mangelvorlage: {
      type: Object,
      default: undefined,
    },
    isCustomMangel: {
      type: Boolean,
      default: false,
    },
    isCustomMangelAddMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:collapsed"],
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });

    const mangelGewerkKeys = Object.keys(MangelGewerk);
    const mangelGewerkValues = Object.values(MangelGewerk);

    const mangelKategorieKeys = Object.keys(MangelKategorie);
    const mangelKategorieValues = Object.values(MangelKategorie);

    const mangelProduktTypKeys = Object.keys(MangelProduktTyp);
    const mangelProduktTypValues = Object.keys(MangelProduktTyp);

    const mangelQuelleKeys = Object.keys(MangelQuelle);
    const mangelQuelleValues = Object.keys(MangelQuelle);

    const mangelZeitpunktKeys = Object.keys(MangelZeitpunkt);
    const mangelZeitpunktValues = Object.values(MangelZeitpunkt);

    const handlungsbedarfTaetigkeits: any = [];
    const handlungsbedarfZeitpunkt: any = [];

    function filterErrors(id: string) {
      return props.errors?.filter((el: any) => el.$id === id);
    }

    const textErrors = computed(() => filterErrors("freitext"));
    const imageErrors = computed(() => filterErrors("bilder"));
    const relevantErrors = computed(() => filterErrors("relevant"));
    const gewerkErrors = computed(() => filterErrors("customGewerk"));
    const customTitelErrors = computed(() => filterErrors("customTitel"));
    const handlungsbedarfTaetigkeitsErrors = computed(() => filterErrors("customHandlungsbedarfTaetigkeit"));
    const kategorieErrors = computed(() => filterErrors("customKategorie"));
    const produktTypErrors = computed(() => filterErrors("produktTyp"));
    const quelleErrors = computed(() => filterErrors("quelle"));
    const handlungsbedarfZeitpunktErrors = computed(() => filterErrors("customHandlungsbedarfZeitpunkt"));
    const maluspunkteErrors = computed(() => filterErrors("maluspunkte"));

    const showBodyEnabled = computed(() => {
      return !props.mangelConfig || props.mangelConfig.hideFields !== true;
    });

    return {
      t,
      chevronUpOutline,
      chevronDownOutline,
      showBodyEnabled,
      textErrors,
      imageErrors,
      relevantErrors,
      customTitelErrors,
      gewerkErrors,
      quelleErrors,
      handlungsbedarfTaetigkeits,
      handlungsbedarfZeitpunkt,
      handlungsbedarfTaetigkeitsErrors,
      handlungsbedarfZeitpunktErrors,
      mangelKategorieKeys,
      produktTypErrors,
      mangelKategorieValues,
      mangelProduktTypKeys,
      mangelQuelleKeys,
      mangelQuelleValues,
      mangelProduktTypValues,
      maluspunkteErrors,
      mangelZeitpunktKeys,
      mangelZeitpunktValues,
      kategorieErrors,
      mangelGewerkKeys,
      mangelGewerkValues,
      freitextLimit: props.mangelConfig?.maxCharacters || 300,
    };
  },
};
