/**
 * As we only have a certain space on a pdf page, we have to split up our in theory endless entries.
 * We use this to splice up the maengelzuordnung but for the custom input.
 *
 * We have a max score per page. Every entry adds up a score. Once the max score is full, we start with a new array aka page.
 *
 * Example: If we set a total score per page for 28, we could say every item (e.g. mangel) without an image would add 2 to the score. But with an image, the entry
 * will need more space. Let's say an entry with an image has double the size of the one without one. Then we would set a custom function that set it to 4.
 *
 * @param arr: The array of entries that will be shown on the list.
 * @param maxPageScore
 * @param defaultAdd
 * @param customAddFunction
 */
export function usePdfContentSlicer<Type>(arr: Type[], maxPageScore: number, defaultAdd: number, customAddFunction?: (element: Type) => number | null): Type[][] {
    const slicedArr: Type[][] = [];

    let currentPageScore = 0;
    let currentPageArr: Type[] = [];

    arr.forEach((element) => {
        let toAdd = defaultAdd;
        const customAdd = customAddFunction && customAddFunction(element);
        if (customAdd) { toAdd = customAdd }

        if (currentPageScore + toAdd <= maxPageScore) {
            // Add Mangel to current page
            currentPageScore += toAdd;
            currentPageArr.push(element);
        } else {
            // Starting with a new page. Add the old one to the return array and set up the new array.
            currentPageScore = toAdd;
            slicedArr.push(currentPageArr);
            currentPageArr = [];
            currentPageArr.push(element);
        }
    });
    slicedArr.push(currentPageArr);
    return slicedArr;
}
