
import moment from "moment";
import { useI18n } from 'vue-i18n';
import Toolbar from "@/components/Navigation/Toolbar.vue";
import Immobilie from "@/models/immobilie.model";
import {
  IonBackButton,
  IonCard,
  IonButton,
  IonButtons,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonPage,
  IonIcon,
  IonLabel,
  IonContent,
  IonImg,
  onIonViewWillEnter,
  IonSlide,
  IonSlides,
} from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import {computed, defineComponent, onMounted, ref} from "vue";
import { useRouter } from "vue-router";
import HzbaList from "@/components/hzba/HzbaList.vue";
import Skeleton from "@/components/Skeleton.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { useStore } from "@/composables/useTypedStore";
import PdfModal from "@/components/Base/PdfModal.vue";
import InfoField from "@/components/properties/InfoField.vue";
import HorizontalItemScroll from "@/components/hzba/HorizontalItemScroll.vue";
import {openBaseModal} from "@/utilities/modal-helper";
import AImage from "@/components/Base/AImage.vue";
// import DokumentList from "@/components/properties/DokumentList.vue";
import AButton from "@/components/Base/AButton.vue";

export default defineComponent({
  name: "LogsPage",
  components: {
    AButton,
    AImage,
    HorizontalItemScroll,
    InfoField,
    Toolbar,
    IonPage,
    IonButtons,
    IonBackButton,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    IonLabel,
    IonContent,
    IonImg,
    PdfModal,
    HzbaList,
    Skeleton,
    IonSlide,
    IonSlides,
    // DokumentList
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })
    const router = useRouter();
    const store = useStore();
    
    const logs = ref([]);

    const expandedItem = ref();


    // const reversedLogs = computed(() => {
    //   return store.state.app.consoleLogs.slice().reverse();
    // })
    
    const refreshLogs = () => {
      logs.value = store.state.app.consoleLogs.slice().reverse();
    }

    onMounted(() => {
      refreshLogs();
    })


    return {
      addCircleOutline,
      getPhotoUrl,
      openBaseModal,
      t,
      logs,
      moment,
      refreshLogs,
      expandedItem
    };
  },
});
