
import { defineComponent, PropType } from "vue";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import AImage from "@/components/Base/AImage.vue";
import PdfRowBase from "@/components/Pdf/Components/PdfRowBase.vue";
import { getNestedBreadcrumbTitles } from "@/composables/Bestandsaufnahme/useIdentifierFinder";
// import PdfImageCropped from "@/components/Pdf/Components/PdfImageCropped.vue";

export default defineComponent({
  name: "PdfRowMangel",
  components: {
    PdfRowBase,
    // PdfImageCropped,
    AImage,
    PdfChip,
  },
  props: {
    mangel: {
      type: Object as PropType<Mangelzuordnung>,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  setup(props) {

    /** TODO: Refactor code, getNestedBreadcrumbTitle is a duplicate */

    const getNestedBreadcrumbTitle = (path: string) => {
      const temp = getNestedBreadcrumbTitles(props.ba, path).split(' > ');
      let title = " ";
      temp.forEach(el => {
        if (title.includes(el)) { return }
        else { 
          title.length > 1 ? title = title.concat(' > ', el) : title = title.concat('', el);
        }
      })
      return title;
    }

    return {
      getPhotoUrl,
      getNestedBreadcrumbTitle
    };
  },
});
