
import {
  useIdentifierFrageFinder,
  useIdentifierFragenblockFinder,
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import PdfA4Page from "../Components/PdfA4Page";
import PdfAconLogo from "../Components/PdfAconLogo";
import PdfGeneralData from "../Components/PdfGeneralDataEnsimimav";
import PdfGeneralInfo from "./PdfEnsimimavOverviewGeneralInfo.vue";
import PdfPageBreak from "../Components/PdfPageBreak";
import PdfImageRow from "../Components/PdfImageRow";
import PdfColorCodes from "../Components/PdfColorCodes";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { ExtendedMSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import PdfMSEDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import { MaengelReport } from "@/models/ba/MaengelReport";
import Immobilie from "@/models/immobilie.model";
import PdfEnsimimavOverviewGeneralInfo from "@/components/Pdf/Bericht_Ensimimav/PdfEnsimimavOverviewGeneralInfo.vue";
import PageCounter from "@/components/Pdf/Components/PageCounter.vue";

export default defineComponent({
  name: "PdfEnsimimavOverviewPage",
  components: {
    PdfEnsimimavOverviewGeneralInfo,
    // PdfDataSectionSummary,
    PdfMSEDataTable,
    // PdfColorCodes,
    PdfImageRow,
    PdfPageBreak,
    PdfGeneralInfo,
    PdfGeneralData,
    PdfAconLogo,
    PdfA4Page,
    PageCounter
  },

  computed: {
    greenboxhasarrow(){
      if(useIdentifierFrageFinder(this.ba, "ensimimav.anlagenzustand-tab.anlagenzustand-group.heizanlagenbewertung")?.eingabeAuswahlWert === 'optimalerBetrieb'){
        return 'boxarrow';
      }
      return '';
    },
    yellowboxhasarrow(){
      if(useIdentifierFrageFinder(this.ba, "ensimimav.anlagenzustand-tab.anlagenzustand-group.heizanlagenbewertung")?.eingabeAuswahlWert === 'handlungsbedarf'){
        return 'boxarrow';
      }
      return '';
    },
    redboxhasarrow(){
      if(useIdentifierFrageFinder(this.ba, "ensimimav.anlagenzustand-tab.anlagenzustand-group.heizanlagenbewertung")?.eingabeAuswahlWert === 'erhoehterHandlungsbedarf'){
        return 'boxarrow';
      }
      return '';
    }
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    tableOfContentMSEData: {
      type: Object as PropType<ExtendedMSERow[] | undefined>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const baMaengelReport = new MaengelReport(
      props.ba?.getFiredMaengel({ allRelevantMangels: true })
    );
    const baSummaryMSE = baMaengelReport.getMSESummary();
  const ensimimavHeizraumBilder = computed(() => {
  const ensimimavHeizraumBilderFrage = props.ba && useIdentifierFrageFinder(props.ba, 'ensimimav.allgemein-tab.liegenschaftDaten.heizraum');
         
          let bilder = ensimimavHeizraumBilderFrage?.getCurrentInput();
          if (!bilder) { bilder = []; }
         
          while (bilder.length < 3) {
           bilder.push({ imageNotAvailable: 'Bild Heizraum'})
          }
          return bilder;
    });
    return {
      ensimimavHeizraumBilder,
      t,
      baSummaryMSE,
      useIdentifierFrageFinder,
      useIdentifierFragenblockFinder,
    };
  },
});
