import {toastController} from "@ionic/vue";


export function isNetworkError(error: any): boolean {
    return error.message === "Network Error"
}

export function is403Error(error: any): boolean {
    return error.response?.status === 403
}

export function is401Error(error: any): boolean {
    return error.response?.status === 401
}

export function isBadRequest(error: any): boolean {
    return error.response?.status === 400;
}

/**
 * Default prints Toasts to inform the user that something went wrong in these cases
 *      - network error (client has no internet connection)
 *      - 403 - Current User is not allowed to execute this action. Should be a bug most of the time.
 * @param error
 * @constructor
 */
export default async function apiErrorHelper(error: any, t: any): Promise<{ code: "network error" | 403 | undefined, didAlert: boolean }>{

    console.error("ApiErrorHelper reports:", error);
    console.error("ApiErrorHelper message:", error.message);
    console.error("ApiErrorHelper code:", error.response?.status);

    if (isNetworkError(error)) {
        const toast = await toastController.create({
            message: t('toasts.keineVerbindungMitBackendHergestellt'),
            duration: 2000
        })
        await toast.present();
        return { code: "network error", didAlert: true }
    } else if (is403Error(error)) {
        const toast = await toastController.create({
            message: t('toasts.apiRequestNichtErlaubt'),
            duration: 2000
        })
        await toast.present();
        return { code: 403, didAlert: true }
    } else {

        const toast = await toastController.create({
            message: t('toasts.unerwarteterFehler'),
            duration: 2000
        })
        await toast.present();
    }
    return { code: error.response?.status, didAlert: false }
}