
import { useI18n } from 'vue-i18n';
import ProgressIndicator from "@/components/ProgressIndicator";
import StatusProgressItem from "@/components/hzba/StatusProgressItem";
import { defineComponent, watch } from "vue";
import { IonButton, IonIcon } from "@ionic/vue";
import { checkmark } from 'ionicons/icons';
// import {getAllAPhotosInObj} from "@/utilities/photo-storage";
import {isStrapiPhoto} from "@/utilities/get-media-url";
import {computed} from "vue";
import { useStore } from "@/composables/useTypedStore";
import OfflineHint from "@/components/Other/OfflineHint";
import InfoBanner from "@/components/Base/InfoBanner.vue";
import { BestandsaufnahmePhotoUploadTask } from '@/api/UploadApi';
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {HzbaStatusKey} from "@/models/ba/interfaces/IBestandsaufnahme";
import {store} from "@/store/store";
import useUploadImageStatus from "@/composables/useUploadImageStatus";

export default defineComponent({
  name: "StatusProgress",
  components: {InfoBanner, OfflineHint, StatusProgressItem, ProgressIndicator, IonIcon, IonButton},
  props: {
    currentStatus: {
      type: String,
      required: true
    },
  },
  emits: ['saveHzba', 'saveHzbaLocally'],
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const stages: HzbaStatusKey[] = [ "ANGELEGT", "GEPLANT", "IN_DURCHFUEHRUNG", "ABGESCHLOSSEN", "FREIGEGEBEN" ]
    
    const getClassForStage = (stage: HzbaStatusKey) => {

      const index = stages.findIndex(el => el.toLowerCase() === stage.toLowerCase());
      const currentStatusIndex = stages.findIndex(el => el.toLowerCase() === props.currentStatus?.toLowerCase())

      if (index < currentStatusIndex) { return 'done' }
      if (index === currentStatusIndex) { return 'doing' }
      return ''
    }

    const isReadonly = computed(() => store.getters['currentHzba/isBaReadonly'] );

    const hzba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const photoUploads = computed<BestandsaufnahmePhotoUploadTask | undefined>(() => {
      return store.state.currentHzba.photoUploads;
    })


    const uploadImageStatus = useUploadImageStatus();

    const isOffline = computed(() => { return store.getters['app/isOffline'] })

    return {
      hzba,
      stages,
      getClassForStage,
      checkmark,
      isOffline,
      t,
      isReadonly,
      photoUploads,
      uploadImageStatus
    }

  }
})
