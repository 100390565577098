<template>
  <info-banner
    v-if="isOffline"
    :type="'warning'"
    class="mt-8"
  >
    <slot />
  </info-banner>
</template>

<script>
import {computed} from "vue";
import { useStore } from "@/composables/useTypedStore";
import InfoBanner from "@/components/Base/InfoBanner";

export default {
  name: "OfflineHint",
  components: {InfoBanner},
  setup() {
    const store = useStore();

    const isOffline = computed(() => {
      return store.getters['app/isOffline']
    })

    return {
      isOffline,
    }

  }
}
</script>

<style scoped>

</style>