import {useProperties} from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import useAlert from "@/composables/useAlert";
import useUser from "@/composables/useUser";
import {setLocalMetaData} from "@/models/local-meta-data";
import {useMetaData} from "@/composables/useMetaData";
import { useFaqs } from "./useFaqs";
import { useBestandsaufnahmens } from "./Bestandsaufnahme/useBestandsaufnahmens";
import Immobilie from "@/models/immobilie.model";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";


export default function useFetchData() {

    const { fetchMetaData } = useMetaData();
    const { loadImmobilienPreviews } = useProperties();
    const { loadBestandsaufnahmens } = useBestandsaufnahmens();
    const { loadFaqs } = useFaqs();

    const store = useStore();
    const alert = useAlert();


    async function fetch(alertWhenOffline = false) {

        const uUser = await useUser();

        if (!await uUser.isLoggedIn() || !uUser.isApproved.value) {
            return;
        }

        if(alertWhenOffline && store.getters['app/isOffline']){
            alert.show("Sie sind offline.", "Um aktuelle Daten zu erhalten, stellen Sie bitte eine Internetverbindung her.")
        }

        if (!store.getters['app/isOffline']){
            await setLocalMetaData('lastBackendFetch', (new Date()).toISOString())
        }

        await Promise.all([
            loadImmobilienPreviews(),
            fetchMetaData(),
            loadFaqs(),
            loadBestandsaufnahmens()
        ]);

    }


    /**
     * Discard current loaded data and load new
     */
    const doRefresh = async (clickEvent: any) => {
        console.log('doRefresh ...');
        BestandsaufnahmeModel.dispatch("setPage", 0);
        BestandsaufnahmeModel.deleteAll();
        Immobilie.dispatch("setPage", 0);
        Immobilie.deleteAll();

        await fetch(true);
        clickEvent?.target?.complete();
    };

    return {
        fetch,
        doRefresh,
    }
}