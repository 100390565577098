<template>
  <ion-item
    lines="none"
    data-cy="text-input-item"
  >
    <ion-grid class="p-0 mb-8">
      <ion-row
        v-if="!hideTitle"
        class="ion-wrap d-flex items-center"
      >
        <input-label>{{ label }}</input-label>
        <ion-col class="ion-float-right" />
        <unit-chip
          show-unit
          :unit="unit"
        />
      </ion-row>
      <ion-row>
        <ion-textarea
          data-cy="input-textarea"
          :value="modelValue"
          :placeholder="placeholder"
          class="inline-block text-right"
          :type="type"
          :maxlength="limit"
          :disabled="readonly"
          @input="onChanged"
        />
      </ion-row>

      <div v-if="limit" class="text-right" style="color: #7f7f7f">
        <span :class="(modelValue?.length || 0) <= limit ? '' : 'error-text'">{{ modelValue?.length || 0 }} / {{ limit }}</span>
      </div>

      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script>

import { IonItem, IonCol, IonRow, IonGrid, IonText, IonInput, IonLabel, IonTextarea} from "@ionic/vue";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
export default {
  name: "HzbaTextareaInput",
  components: {
    InputError,
    UnitChip,
    InputLabel,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel,
    IonTextarea,
  },
  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    unit: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.target.value);
    }

    return {
      onChanged
    }
  }
}
</script>

<style scoped lang="scss">

.unit {
  background: #efefef;
  padding: 4px 8px;
  border-radius: 4px;
}


ion-label {
  max-width: unset !important;
}


ion-textarea {
  background: $grey-100;
  border: 1px solid $grey-300;
  border-radius: 5px;
  min-height: 150px;
  text-align: left;
  padding: 16px;
  //margin-right: 16px;
  margin-bottom: 16px;
}

.error-text {
  color: $aconRed;
}

</style>

<style>
textarea {
  min-height: 150px;
}

</style>