import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecursiveForm = _resolveComponent("RecursiveForm")!

  return ($setup.section && $props.ba)
    ? (_openBlock(), _createBlock(_component_RecursiveForm, {
        key: 0,
        "parent-fragenblock": $setup.section,
        "save-on-frage-input-changed": true
      }, null, 8, ["parent-fragenblock"]))
    : _createCommentVNode("", true)
}