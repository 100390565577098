import EnumEingabewerte from "@/models/enum-eingabewerte.model";
import Faq from "@/models/faq.model";
import { computed } from "vue";
import { useStore } from "@/composables/useTypedStore";

export function useFaqs() {

    const store = useStore();
    const faqs = computed(() => Faq.all());

    const loadFaqs = async () => {
        try {
            const res = await Faq.api().get(`/faqs?locale=${store.getters["app/getLocale"]}`);
            Faq.dispatch('$replaceLocally', { data: (res.getDataFromResponse() as any).data.results }); 
        } catch (error: any) {
            if (error.message === "Network Error") {
                await Faq.dispatch("$fetchFromLocal");
            } else {
                console.error(error)
            }
        }
    }

    return {
        loadFaqs,
        faqs
    }
}