import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {computed} from "vue";

export default function useSupportedPdfIdentifiers(){

    /**
     * If the ba is not listed here, no pdf can be generated.
     */
    const supportedIdentifiers = ['hzba','ensimimav'];

    const isIdentifierSupported = (identifier: string) => {
        return supportedIdentifiers.indexOf(identifier) >= 0;
    }

    const isBaPdfSupported = (ba: Bestandsaufnahme) => {
        return ba && isIdentifierSupported(ba.identifier);
    }


    return {
        supportedIdentifiers,
        isIdentifierSupported,
        isBaPdfSupported,
    }

}