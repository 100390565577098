import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../../public/assets/icon/pdf/green-gradient.png'
import _imports_1 from '../../../../public/assets/icon/pdf/yellow-gradient.png'
import _imports_2 from '../../../../public/assets/icon/pdf/red-gradient.png'
import _imports_3 from '../../../../public/assets/icon/pdf/blue-gradient.png'


const _withScopeId = n => (_pushScopeId("data-v-f6e4c6ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-cy"]
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  style: {"width":"100%","height":"100%"},
  class: "bg-img"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  style: {"width":"100%","height":"100%"},
  class: "bg-img"
}
const _hoisted_4 = {
  key: 2,
  src: _imports_2,
  style: {"width":"100%","height":"100%"},
  class: "bg-img"
}
const _hoisted_5 = {
  key: 3,
  src: _imports_3,
  style: {"width":"100%","height":"100%"},
  class: "bg-img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chip", [
      _ctx.big
        ? 'inline-flex items-center mr-2.5 px-4 py-1.5 text-sm font-medium text-white '
        : 'normal',
      _ctx.mangelAmountMode ? 'mangelAmountMode' : '',
      _ctx.value === undefined ? 'small' : '',
      _ctx.color === 'green' ||
      _ctx.color === 'yellow' ||
      _ctx.color === 'red' ||
      _ctx.color === 'blue'
        ? 'color-white'
        : '',
      _ctx.pdfMode ? 'pdfMode' : '',
      !_ctx.pdfMode && _ctx.color === 'green' ? 'gradient-green' : '',
      !_ctx.pdfMode && _ctx.color === 'yellow' ? 'gradient-yellow' : '',
      !_ctx.pdfMode && _ctx.color === 'red' ? 'gradient-red' : '',
      !_ctx.pdfMode && _ctx.color === 'blue' ? 'gradient-blue' : '',
    ]]),
    "data-cy": `pdf-chip-${_ctx.color}`
  }, [
    (_ctx.pdfMode && _ctx.color === 'green')
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.pdfMode && _ctx.color === 'yellow')
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.pdfMode && _ctx.color === 'red')
      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.pdfMode && _ctx.color === 'blue')
      ? (_openBlock(), _createElementBlock("img", _hoisted_5))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.pdfMode ? 'relative z-50 top-0 p-0' : 'relative z-50 bottom-0.5 p-0')
    }, _toDisplayString(_ctx.value), 3)
  ], 10, _hoisted_1))
}