import { Model } from '@vuex-orm/core';
import { MangelvorlageJson } from '@/models/ba/interfaces/IMangelzuordnung';

export enum MangelKategorie {
  // HINWEIS = 'Hinweis',
  VERSORGUNGSGEFAEHRDEND = 'Versorgungsrelevant',
  SICHERHEITSRELEVANT = 'Sicherheitsrelevant',
  SONSTIGER = 'Sonstiger',
}

export enum MangelGewerk {
  SERVICE = 'Service',
  BAU_TISCHLER = 'Bau/Tischler',
  HEIZUNG_SANITAER_MSR = 'Heizung/Sanitär/MSR',
  ELEKTRO = 'Elektro',
}

export enum MangelZeitpunkt {
  ZEITNAH = 'Zeitnah',
  ABSOFORT = 'Ab sofort',
}

export enum MangelQuelle {
  FEUVO = 'FeuVO',
  NONE = 'NONE',
}

export enum MangelProduktTyp {
  // ACTIVE = 'Active',
  EFFICIENCY = 'Efficiency',
  HINWEIS = 'Hinweis',
  SAFETY = 'Safety',
}

export default class Mangelvorlage implements MangelvorlageJson {
  public id: number;
  public kurztext: string;
  public kategorie: string;
  public unterkategorie: string;
  public handlungsbedarfTaetigkeit: string;
  public handlungsbedarfZeitpunkt?: string;
  public handlingsbedarfZeitpunkt?: string; // TODO remove typo
  public gewerk: string;
  public maluspunkte: number;
  public produktTyp: string;
  public createdAt: string;
  public updatedAt: string;
  public uid: string;

  constructor(json: MangelvorlageJson) {
    this.id = json.id;
    this.kurztext = json.kurztext;
    this.kategorie = json.kategorie;
    this.unterkategorie = json.unterkategorie;
    this.handlungsbedarfTaetigkeit = json.handlungsbedarfTaetigkeit;
    this.handlungsbedarfZeitpunkt = json.handlungsbedarfZeitpunkt;
    this.gewerk = json.gewerk;
    this.maluspunkte = json.maluspunkte;
    this.produktTyp = json.produktTyp;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.uid = json.uid;
  }
}
