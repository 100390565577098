<template>
  <div class="flex p-2 items-center banner" style="background: white">
    <div>
      <ion-button
        fill="flat"
        size="small"
        class="close-button"
        @click="close()"
      >
        <ion-icon :icon="closeIcon" />
      </ion-button>
    </div>
    <div class="flex-shrink-0 px-2">
      <img
        src="../../public/assets/acon-icon.png"
        width="48"
        height="48"
        class="rounded-xl"
      >
    </div>
    <div class="px-2 flex-1">
      <div class="text-s">
        {{ t('downloadApp.titel') }}
      </div>
      <div class="text-xs">
        {{ t('downloadApp.beschreibung') }}
      </div>
    </div>
    <div>
      <ion-button
        fill="solid"
        shape="round"
        color="light"
        @click="open"
      >
        {{ t('downloadApp.open') }}
      </ion-button>
    </div>
  </div>
</template>

<script>

import { close as closeIcon } from "ionicons/icons";
import {
  getPlatforms,
  IonButton,
  IonIcon, isPlatform,
} from "@ionic/vue";
import AButton from "@/components/Base/AButton";
import {useStore} from "@/composables/useTypedStore";
import {useI18n} from "vue-i18n";

export default {
  name: "AppLinkBanner",
  components: {
    AButton,
    IonButton,
    IonIcon,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })

    const close = () => {
      store.commit('app/setShowAppLinkBanner', false)
    }

    const open = () => {
      console.log(getPlatforms())

      if (isPlatform('ios')) {
        window.open('https://apps.apple.com/at/app/acon-360/id1591590089', '_blank')
      } else if (isPlatform('android')) {
        window.open('https://play.google.com/store/apps/details?id=de.acon_energie.inventory360', '_blank')
      }
    }

    return {
      close,
      closeIcon,
      open,
      t
    }
  }
}
</script>

<style scoped>
.banner {
  border-bottom: 1px solid #e4e4e4;
  z-index: 100;
}

.close-button {
  --padding-start: 0;
  --padding-end: 0;
}

</style>