import { Model } from '@vuex-orm/core';
export default class Faq extends Model {
    static entity = "faq"
    
    static fields() {
        return {
            id: this.attr(null),
            frage: this.attr(null),
            antwort: this.attr(null)
        }
    }

    id!: string;
    frage!: string;
    antwort!: string;
}