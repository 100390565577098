<template>
  <span></span>
</template>

<script setup>

import Disposable from "ol/Disposable";
import { createCommentVNode, defineComponent } from "vue";

console.log("End");
console.log(window.pdfPageCounters);
window?.pdfPageCounters?.forEach(func => {
  func();
});

</script>