<template>
  <div class="p-2 lg:p-0">
    <ion-card
      v-for="card in [0]"
      :key="card"
      button="true"
      class="m-2"
      data-cy="property-list-item"
    >
      <ion-skeleton-text
        animated
        style="width: 100%; height: 60vh; margin: 0"
      />
    </ion-card>
  </div>
</template>

<script>
import {
  IonAvatar,
  IonLabel,
  IonItem,
  IonList,
    IonCard,
  IonListHeader,
  IonSkeletonText
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PdfPageSkeleton",
  components: {
    IonAvatar,
    IonSkeletonText,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
  },
});
</script>

<style>
ion-avatar {
  --border-radius: 0;
  height: 100%;
  min-width: 120px;
}
</style>