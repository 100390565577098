import {ref} from "vue";
import {modalController} from "@ionic/vue";

export default function useBaseModal() {


    const defaultCancel = async () => {
        await modalController.dismiss();
    }


    return {
        defaultCancel,
    }

}