

import { download, share, addCircleOutline } from "ionicons/icons";
import BaseModal from "@/components/Base/BaseModal";
import useBaseModal from "@/composables/useModal";
import { IonImg, IonContent, IonButtons, IonButton, IonIcon } from "@ionic/vue";
import { trashOutline } from "ionicons/icons";
import { getPhotoUrl } from "@/utilities/get-media-url";
import {onMounted, ref} from "vue";
import AImage from "@/components/Base/AImage";
import {getPdfUrl} from "../../utilities/get-media-url";
import useDownloadHelper from "@/composables/useDownloadHelper";

export default {
  name: "ImageGalleryModal",
  components: {AImage, BaseModal, IonImg, IonContent, IonButton, IonButtons, IonIcon },
  props: {
    title: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      default: undefined
    },
    onDelete: {
      type: Function,
      default: undefined,
    },
    allowAddMore: {
      type: Boolean,
      default: false
    },
    // addImmoPhoto: {
    //   type: Function,
    //   default: undefined
    // },
    isReadonly: {
      type: Boolean,
      default: false
    },
    downloadEnabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['close', 'save'],
  setup(props: any) {
    const content = ref();
    const { defaultCancel } = useBaseModal();


    const { canShare, downloadFile, isProcessing } = useDownloadHelper();


    async function scrollToIndex() {
      const scrollEl = await content.value.$el.getScrollElement();

      const el = document.getElementById(`photo-${props.index}`)
      scrollEl.scrollTo({ top: el?.offsetTop, behavior: 'smooth' })
    }


    onMounted(async () => {

      setTimeout(() => {
        if (props.index) {
          scrollToIndex();
        }
      }, 700)
    })

    function downloadResource(photo: any) {
      downloadFile(""+getPhotoUrl(photo), photo.name || 'image');
    }

    function downloadResources() {
      props.images.forEach((el: any) => {
        downloadResource(el);
      })
    }

    return {
      cancel: defaultCancel,
      canShare,
      getPhotoUrl,
      content,
      trashOutline,
      downloadResource,
      downloadResources,
      download,
      share,
      addCircleOutline
    }
  }
}
