
import useUser from "@/composables/useUser";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { popoverHelper } from "@/utilities/popover-helper";
import { defineComponent } from "vue";
import { IonButton, IonButtons, IonContent, IonImg, IonPage } from "@ionic/vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "UserDialogButton",
  components: {
    IonPage,
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
  },
  setup() {
    const { user } = useUser();
    const router = useRouter();


    function goToSettings() {
      router.push('/settings')
    }

    return {
      goToSettings,
      user,
      openPopover: popoverHelper,
      getPhotoUrl,
    };
  },
});
