import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_signature_field = _resolveComponent("signature-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ba.unterschrifts, (signature, key) => {
      return (_openBlock(), _createBlock(_component_signature_field, {
        key: signature,
        signature: signature,
        "is-readonly": _ctx.isReadonly,
        onOnSignatureChange: (imgUri) => _ctx.updateSignature(signature, imgUri),
        onOnSignatureDelete: ($event: any) => (_ctx.deleteSignature(key))
      }, null, 8, ["signature", "is-readonly", "onOnSignatureChange", "onOnSignatureDelete"]))
    }), 128))
  ]))
}