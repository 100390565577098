import useAlert from "@/composables/useAlert";
import {HzbaStatus} from "@/models/ba/interfaces/IBestandsaufnahme";
import {Device} from "@capacitor/device";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import useUser from "@/composables/useUser";
import {useStore} from "@/composables/useTypedStore";
import {i18n} from "@/i18n";
import { store } from '@/store/store';
import {useBestandsaufnahmens} from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import Faq from "@/models/faq.model";
import User from "@/models/user";
import apiErrorHelper, {isBadRequest, isNetworkError} from "@/api/apiErrorHelper";

const isWatchingPdfPreview = () => {
    if (document.URL.includes('pdf-preview')) {
        return true
    }
    return false
};

const lockOrViewAlert = (alert: any, t: any, abortMode: boolean | undefined, onLockHandler: any) => {


    if (isWatchingPdfPreview()) return;

    return alert.showAdv(
        t("alerts.hzbaInPlanung"),
        t("alerts.hzbaInPlanungText"),
        [
            {
                text: t("hzba.buttons.aufnahmeDurchfuehren"),
                handler: async () => {
                    await onLockHandler()
                }
            },
            { text: abortMode ? t("hzba.buttons.abbrechen") : t("hzba.buttons.baAnsehen") },
        ],
        {
            backdropDismiss: false
        }
    )
}

const inBearbeitungAlert = (alert: any, t: any, user?: User) => {

    if (isWatchingPdfPreview()) return;

    if (user && user.username) {
        return alert.showAdv(
            t("alerts.hzbaInBearbeitungVonUser", { username: user?.username }),
            t("alerts.hzbaInBearbeitungText"),
            [
                {
                    text: t("alerts.verstanden")
                }
            ],
            {
                backdropDismiss: false
            }    
        )
    }

    return alert.showAdv(
        t("alerts.hzbaInBearbeitung"),
        t("alerts.hzbaInBearbeitungText"),
        [
            {
                text: t("alerts.verstanden")
            }
        ],
        {
            backdropDismiss: false
        }    

    )
}

const inBearbeitungAnderesGeraet = (alert: any, t: any) => {
    
    if (isWatchingPdfPreview()) return;

    return alert.showAdv(
        t("alerts.hzbaInBearbeitungAufAnderemGerät"),
        t("alerts.hzbaInBearbeitungAufAnderemGerätText"),
        [
            {text: t("alerts.verstanden")},
        ],
        {
            backdropDismiss: false
        }
    )
}


const networkErrorLockAlert = (alert: any, t: any) => {
    return alert.showAdv(
        t("alerts.lockNetworkError"),
        t("alerts.lockNetworkErrorText"),
        [
            {
                text: t("alerts.verstanden")
            }
        ],
        {
            backdropDismiss: false
        }
    );
}

const confirmUnlockAndDiscardChangesAlert = (alert: any, t: any, onUnlockHandler: any) => {
    return alert.showAdv(
        t("alerts.unlockConfirm"),
        t("alerts.unlockConfirmText"),
        [
            {
                text: t("hzba.buttons.unlock"),
                handler: async () => {
                    await onUnlockHandler()
                }
            },
            { text: t("hzba.buttons.abbrechen") },
        ],
        {
            backdropDismiss: false
        }
    );
}

const networkErrorUnlockAlert = (alert: any, t: any) => {
    return alert.showAdv(
        t("alerts.unlockNetworkError"),
        t("alerts.unlockNetworkErrorText"),
        [
            {
                text: t("alerts.verstanden")
            }
        ],
        {
            backdropDismiss: false
        }
    );
}

/**
 * "The Brain" of modals when it comes to locking a ba or not.
 * Determines which modals to show.
 */
export const useBestandsaufnahmeViewAndLockAlert = async (ba: Bestandsaufnahme, t: any, abortMode?: boolean) => {
    if (!ba) {
        console.log('useBestandsaufnahmeViewAndLockAlert: BA is undefined')
        return
    }

    if (!store.getters['currentHzba/hasHzbaEditPermission']) {
        // User is not allowed to edit Hzba anyway, so no need to show status alerts
        return;
    }

    const { user } = useUser();
    const alert = useAlert();

    if (!user.value) {
        console.error('No user found? for useBestandsaufnahmeViewAndLockAlert')
        return;
    }

    if (!(ba.hasStatus(HzbaStatus.ANGELEGT) || ba.hasStatus(HzbaStatus.GEPLANT) || ba.hasStatus(HzbaStatus.IN_DURCHFUEHRUNG))) {
        return;
    }

    if (ba.isLocked()) {
        if (await ba.isLockedByCurrentDeviceAndUser()) {
            if (!ba.isDownloaded) {
                // e.g. user cleared his cache
                const { downloadBa } = useBestandsaufnahmens();
                await downloadBa(ba);
            }
            return;
        }

        if (ba.isLockedBySameUser(user.value)) {
            await inBearbeitungAnderesGeraet(alert, t)
            return alert.alert
        }

        await inBearbeitungAlert(alert, t, ba.bearbeitenderNutzer);
        return alert.alert;
    }

    await lockOrViewAlert(alert, t, abortMode, async() => {
        ba.status = "IN_DURCHFUEHRUNG";
        try {
            const { lockBa } = useBestandsaufnahmens();
            lockBa(ba, t);
        } catch (error) {
            if (isNetworkError(error)) {
                await networkErrorLockAlert(alert, t);
            } else if (isBadRequest(error)) {
                await inBearbeitungAlert(alert, t, ba.bearbeitenderNutzer);
            } else {
                await apiErrorHelper(error, t);
            }
        }
    })

    return alert.alert;

}


export const useBestandsaufnahmeUnlockAlert = async (ba: Bestandsaufnahme, t: any) => {
    if (!ba) {
        console.log('useBestandsaufnahmeViewAndLockAlert: BA is undefined')
        return
    }

    if (!store.getters['currentHzba/hasHzbaEditPermission']) {
        // User is not allowed to edit Hzba anyway, so no need to show status alerts
        return;
    }

    const { user } = useUser();
    const alert = useAlert();

    if (!user.value) {
        console.error('No user found? for useBestandsaufnahmeViewAndLockAlert')
        return;
    }

    if (!(ba.hasStatus(HzbaStatus.ANGELEGT) || ba.hasStatus(HzbaStatus.GEPLANT) || HzbaStatus.IN_DURCHFUEHRUNG)) {
        return;
    }

    if (!ba.isLocked()) {
        return;
    }

    if (await ba.isLockedByCurrentDeviceAndUser()) {
        confirmUnlockAndDiscardChangesAlert(alert, t, async () => {
            try {
                const { unlockBa } = useBestandsaufnahmens();
                await unlockBa(ba, t);
            } catch (error) {
                if (isNetworkError(error)) {
                    await networkErrorUnlockAlert(alert, t);
                } else {
                    console.error(error);
                }
            }
        });
        return alert.alert;
    }

    return alert.alert;

}