
import { useI18n } from "vue-i18n";
import AButton from "@/components/Base/AButton";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput";
import StatusProgress from "@/components/hzba/StatusProgress.vue";
import Tabs from "@/components/hzba/BaTabs.vue";
import Toolbar from "@/components/Navigation/Toolbar";
import ProgressIndicator from "@/components/ProgressIndicator";
import SignatureField from "@/components/Signature/SignatureField.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { openBaseModal } from "@/utilities/modal-helper";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonRow,
  IonCol,
  IonGrid,
  IonList,
  IonItem,
  IonChip,
} from "@ionic/vue";
import { warningOutline } from "ionicons/icons";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "@/composables/useTypedStore";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import PdfPreviewerAndCreator from "@/components/Pdf/PdfPreviewerAndCreator.vue";
import PdfHzbaTemplate from "@/components/Pdf/Bericht_Heizung/PdfHzbaTemplate.vue";
import useScreenSize from "@/composables/useScreenSize";
import useCurrentBaState from "@/composables/useCurrentBaState";
import useBestandsaufnahmeUpload from "@/composables/Bestandsaufnahme/useBestandsaufnahmeUpload";
import { PdfBerichtManager } from "@/composables/pdfBerichtManager";
import { chevronForward } from "ionicons/icons";
import getLocalization from "@/utilities/get-localization";
import { strapiUrl } from "@/api/api";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import useUser from "@/composables/useUser";
import PdfTemplates from "@/components/Pdf/PdfTemplates.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "BaSummary",
  components: {
    PdfTemplates,
    PdfHzbaTemplate,
    PdfPreviewerAndCreator,
    OfflineHint,
    // SignatureWrapper,
    SignatureField,
    StatusProgress,
    HzbaBaseInput,
    Tabs,
    IonList,
    AButton,
    HzbaGroup,
    ProgressIndicator,
    Toolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonPage,
    IonLabel,
    IonRow,
    IonIcon,
    IonCol,
    IonContent,
    IonSegment,
    IonItem,
    IonChip,
    IonSegmentButton,
    IonGrid,
  },
  setup(_) {
    const { t } = useI18n({ useScope: "global" });
    const store = useStore();
    const selectedView = ref("uebersicht");
    const { isMaxSmall } = useScreenSize();
    const uploadLanguages = ["en", "de"];
    const isUploading = ref(false);
    const router = useRouter();
    const isOffline = computed(() => {
      return store.getters["app/isOffline"];
    });

    const {
      immobilie,
      isReadonly,
      isSubmittable,
      totalProgress,
      isNotSubmitted,
      ba,
    } = useCurrentBaState();

    const user = useUser();

    const { syncBestandsaufnahme, submitBestandsaufnahme, saveBaLocally } =
      useBestandsaufnahmeUpload();

    const {
      downloadBa,
      removeDownloadedBa,
      lockAndEditBaAlert,
      unlockBaAndDeleteLocalDataAlert,
    } = useBestandsaufnahmens();

    const pdfManager = new PdfBerichtManager(uploadLanguages);

    const finishHzba = () => {
      isUploading.value = true;
      submitBestandsaufnahme(pdfManager);
    };

    const maengelReport = computed(() => {
      return ba.value?.maengelReport();
    });
    return {
      selectedView,
      immobilie,
      objectCountHeight: 62,
      syncBestandsaufnahme,
      uploadLanguages,
      openBaseModal,
      isMaxSmall,
      user,
      getLocalization,
      lockAndEditBaAlert,
      unlockBaAndDeleteLocalDataAlert,
      isReadonly,
      getPhotoUrl,
      isOffline,
      isSubmittable,
      maengelReport,
      finishHzba,
      setGeneratedPdfFile: pdfManager.setGeneratedPdfFile,
      setupPdfViewerRef: pdfManager.setupPdfViewerRef,
      totalProgress,
      warningOutline,
      isUploading,
      chevronForward,
      ba,
      baseUrl: strapiUrl,
      t,
      downloadBa,
      removeDownloadedBa,
      saveBaLocally,
      translatedHzbaStatus,
    };
  },
});
