<template>
  <!-- Multiple items allowed -->
  <div
    v-if="!showOnlyOne"
    class="photo-wrapper mb-2"
  >
    <div
      v-for="(d, key) in data"
      :key="key"
      class="photo-container p-4"
    >
      <a-image
        v-if="getType(d.media) === 'photo'"
        :src="getPhotoUrl(d.media)"
        :image-class="getThumbnailClass('img')"
        @click="() => openBaseModal('gallery', { images: [d.media], title: d.title })"
      />
      <div
        v-if="getType(d.media) === 'iframe-url'"
        @click="() => $emit('onIframeItemClicked', d.media?.url)"
      >
        <iframe
          class="rounded-lg"
          frameborder="0"
          allow="xr-spatial-tracking"
          :src="d.media?.url ?? d"
          width="300"
          height="200"
        />
      </div>
      <a-pdf-thumbnail
        v-if="getType(d.media) === 'pdf'"
        :id="`${d.title}_${key}_${propertyId}`"
        :url="getPdfUrl(d.media)"
        :file="d"
        :image-class="getThumbnailClass('pdf')"
        @click="() => openBaseModal('pdf.view', { pdf: d.media })"
      />
      <div
        v-if="d.title"
        class="mt-4 ml-2"
      >
        {{ d.title }}
      </div>
    </div>
    <div
      v-for="(item, key) in placeholderItems"
      :key="key"
      class="photo-container p-4"
    >
      <div
        :class="getThumbnailClass('placeholder')"
        style="background: #efefef"
      >
        {{ t("immobilie.platzhalterAufnahme") }}
      </div>
    </div>
  </div>

  

  <!-- One Video only -->
  <div
    v-else
    data-cy="immobilie-matterport"
  >
    <div
      class="p-3 iframe-video"
      @click="() => $emit('onIframeItemClicked', data[0].media?.url)"
    >
      <iframe
        class="rounded-lg"
        frameborder="0"
        allow="xr-spatial-tracking"
        :src="data[0].media?.url"
        width="100%"
        height="100%"
      />
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { getPhotoUrl } from "@/utilities/get-media-url";
import { getPdfUrl } from '@/utilities/get-media-url';
import { IonImg } from "@ionic/vue";
import AImage from "@/components/Base/AImage";
import APdfThumbnail from "@/components/Base/APdfThumbnail";
import {computed} from "vue";
import PdfReader from "@/components/PdfReader/PdfReader.vue";
import useUser from '@/composables/useUser';
import { openBaseModal } from "@/utilities/modal-helper";

export default {
  name: "HorizontalImageScrolls",
  components: {
    AImage,
    IonImg,
    APdfThumbnail,
    PdfReader
  },
  props: {
    areDokuments: {
      type: Boolean,
      default: false,
    },
    data:
    {
      type: Array,
      default: undefined
    },
    photoMode: {
      type: Boolean,
      default: true,
    },
    propertyId: {
      type: Number,
      default: null
    },
    showMinItems: {
      type: Number,
      default: 3
    },
    showOnlyOne: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });
    const user = useUser();
    const placeholderItems = computed(() => {

      if (!user.isImmobilienverwalter()) {
        let itemLength = 0;

        if (props.data) {
          if (Object.keys(props.data).length > 0) {
            // Object
            itemLength = Object.keys(props.data).length;
          } else {
            // Array
            itemLength = props.data.length
          }
        }

        if (itemLength < props.showMinItems) {
          const placeholderLength = props.showMinItems - itemLength;
          const arr = [];
          for (let i = 0; i < placeholderLength; i++) {
            arr.push({})
          }

          return arr;
        }
      }
      
      return [];
    })

    const getType = (d) => {
      if (!d) {
        return undefined;
      }

      if (d.ext === '.pdf' || d.ext === '.PDF') {
        return 'pdf';
      }

      if (d.ext === 'iframe') {
        return 'iframe-url'
      }

      return 'photo';
    }
    
    const getThumbnailClass = ( type ) => {

      const base = type === "pdf" || type === "img" ? "photo object-cover rounded-lg flex max-h-full max-width-full self-center hairline no-box-sizing" : "rounded-lg flex items-center justify-center max-h-full max-width-full hairline no-box-sizing";

      return base + ( props.areDokuments ? " w-210 h-297" : " w-300 h-200" )
    }
    
    return {
      getPhotoUrl,
      getPdfUrl,
      getThumbnailClass,
      placeholderItems,
      getType,
      openBaseModal,
      user,
      t
    }
  }
}
</script>

<style scoped>

.photo-wrapper {
  display: flex;
  overflow-x: auto;
}

.photo-container ~ .photo-container {
  padding-left: 0;
}

ion-img::part(image) {
  border-radius: 10px;
}

iframe {
  pointer-events: none;
}

.iframe-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.h-200 {
  height: 200px;
}

.w-300 {
  width: 300px;
}

.border-grey {
  border: 1px solid #E9E9E9;
}

.no-box-sizing {
  box-sizing: content-box !important;
}
.h-297 {
  height: 297px;
}


/* needed for mobile iOS devices */
.no-box-sizing {
  box-sizing: content-box !important;
}
.w-210 {
  width: 210px;

}

</style>