<template>
  <div class="hzba-group-wrapper" :class="outerMargin ? 'm-4' : ''">
    <div class="hzba-group-header mx-4 my-4 row items-center text-2xl" :class="!title ? 'mt-0' : ''">
      <progress-indicator
        v-if="!hideProgress"
        :progress="20"
        class="mr-4"
      />
      <div class="flex flex-1 items-center">
        <div v-if="title" :class="smallerTitle ? 'text-lg' : ''">{{ title }}</div>
        <div v-if="suffix " class="flex">
          &nbsp;-&nbsp;
          <span v-if="!isEditMode">{{ suffix }}</span>
          <ion-input
            v-else
            v-model="currentSuffix"
            class="inline-block h-8 w-32"
          />
          <ion-button
            v-if="editSuffixEnabled && !isEditMode"
            @click="startSuffixChange"
            fill="clear"
            color="dark"
            size="small"
          >
            <ion-icon
              :icon="pencilOutline"
              :size="'small'"
            />
          </ion-button>
          <ion-button
            v-if="editSuffixEnabled && isEditMode"
            @click="cancelSuffixChange"
            fill="clear"
            color="dark"
            size="small"
          >
            <ion-icon
              :icon="trashOutline"
              :size="'small'"
            />
          </ion-button>
          <ion-button
            v-if="editSuffixEnabled && isEditMode"
            @click="submitSuffixChange"
            size="small"
            fill="clear"
            color="dark"
          >
            <ion-icon
              :icon="checkmarkOutline"
              :size="'small'"
            />
          </ion-button>
        </div>
      </div>
      <slot v-if="!isMaxSmall" name="cta" />
    </div>

    <slot />


    <slot v-if="isMaxSmall" name="cta" />

    <slot name="cta-bottom" />
  </div>
</template>

<script>
import { IonButton, IonIcon, IonInput } from "@ionic/vue";
import {ref, watch} from "vue";
import ProgressIndicator from "../../ProgressIndicator";
import { pencilOutline, trashOutline, checkmarkOutline } from "ionicons/icons";
import useScreenSize from "@/composables/useScreenSize";

export default {
  name: "HzbaGroup",
  components: {ProgressIndicator, IonIcon, IonButton, IonInput},
  props: {
    title: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: '',
    },
    hideProgress: {
      type: Boolean,
      default: true
    },
    editSuffixEnabled: {
      type: Boolean,
      default: false
    },
    outerMargin: {
      type: Boolean,
      default: true
    },
    smallerTitle: {
      type: Boolean,
      default: false
    }
  }, 
  emits: ['suffixChanged'],
  setup(props, { emit }) {
    const currentSuffix = ref(props.suffix);
    const isEditMode = ref(false);
    const { isMaxSmall } = useScreenSize();

    // currentSuffix.value = props.suffix;

    watch(() => props.suffix, (newVal, oldVal) => {
      if (newVal !== currentSuffix.value) {
        currentSuffix.value = newVal;
      }
    })
    
    const startSuffixChange = () => {
      isEditMode.value = true;
    }

    const cancelSuffixChange = () => {

      isEditMode.value = false;
      currentSuffix.value = props.suffix;
    }

    const submitSuffixChange = () => {
      isEditMode.value = false;

      console.log("submit", currentSuffix.value);

      emit('suffixChanged', currentSuffix.value)
    }

    return {
      isEditMode,
      currentSuffix,
      submitSuffixChange,
      startSuffixChange,
      cancelSuffixChange,
      trashOutline,
      pencilOutline,
      checkmarkOutline,
      isMaxSmall
    }
  }
}
</script>

<style scoped lang="scss">

//.hzba-group-header {
  //background: $grey-200;
  //padding: 24px 16px 16px 26px;
//}

.hzba-group-wrapper {
  //border: 1px solid rgba(0, 0, 0, 0.41);
  //border-radius: 5px;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  //background: white;
  //margin: 16px 16px 24px 16px;
  padding-bottom: 24px;



  border: 1px solid #dfdfdf;
  background: white;
  border-radius: 10px;
}


ion-input {
  background: $grey-200;
}

</style>