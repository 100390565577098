import { AlertButton, alertController } from '@ionic/vue';
import {ref} from "vue";

export default function useAlert() {
  // async function showAdv(
  //   title: string,
  //   description: string,
  //   buttons: AlertButton[],
  // ) {
  //   const alert = await alertController.create({
  //     cssClass: 'alertCSS cypress-alert',
  //     header: title,
  //     // subHeader: 'Subtitle',
  //     message: description,
  //     buttons: buttons,
  //   });
  //   await alert.present();
  // }
  const alert = ref();

  async function showAdv(
    title: string,
    description: string,
    buttons: AlertButton[],
    flags?: any
  ) {
    alert.value = await alertController.create({
      cssClass: 'alertCSS',
      header: title,
      // subHeader: 'Subtitle',
      message: description,
      buttons: buttons,
        backdropDismiss: flags?.backdropDismiss !== false
    });
    await alert.value.present();
  }

  async function show(title: string, description: string, buttonText = 'OK') {
    await showAdv(title, description, [{ text: buttonText }]);
  }

  return {
    show,
    showAdv,
    alert,
  };
}
