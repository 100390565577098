<template>
  <IonButton
    :type="type"
    shape="round"
    color="light"
    :expand="expand ? 'block' : ''"
    class="light-button normal-case"
    @click="$emit('click')"
  >
    <ion-label><slot /></ion-label>
  </IonButton>
</template>

<script>
import { IonButton, IonLabel } from "@ionic/vue";
export default {
  name: "AFlatButton",
  components: {
    IonButton, IonLabel,
  },
  props: {
    type: {
      type: String,
      default: 'button'
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click']
}
</script>

<style scoped lang="scss">


.light-button ion-label {
  color: $primary;
}

</style>