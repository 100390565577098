
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useProperties } from "@/composables/Property/useProperties";
import PropertyList from "../components/properties/PropertyList.vue";
import PropertyMap from "../components/properties/PropertyMap.vue";
import {
  IonContent,
  onIonViewWillEnter,
  IonIcon,
  IonPage,
  IonBackButton,
  IonButtons,
  IonButton,
  IonImg,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
} from "@ionic/vue";
import {
  locationOutline,
  listOutline,
  swapVerticalOutline,
  arrowBack,
} from "ionicons/icons";
import Skeleton from "../components/Skeleton.vue";
import Toolbar from "../components/Navigation/Toolbar.vue";
import { useStore } from "@/composables/useTypedStore";
import AButton from "@/components/Base/AButton";
import useUser from "@/composables/useUser";
import { popoverHelper } from "@/utilities/popover-helper";
import useFetchData from "@/composables/useFetchData";
import Immobilie from "@/models/immobilie.model";
import moment from "moment";
import { getLocalMetaData } from "@/models/local-meta-data";
import useScreenSize from "@/composables/useScreenSize";
import { useRouter } from "vue-router";
import Searchbar from "@/components/hzba/Base/Searchbar.vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import SortBy from "@/components/Base/SortBy.vue";
import ListIcon from "../../public/assets/icon/ListIcon.vue";
import MapIcon from "../../public/assets/icon/MapIcon.vue";
import AIconButton from "@/components/Base/AIconButton.vue";
import { useI18n } from "vue-i18n";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import {SortTermActive} from "@/composables/Sort/SortTerms";

export default defineComponent({
  name: "PropertiesPage",
  components: {
    OfflineHint,
    PdfChip,
    AIconButton,
    MapIcon,
    ListIcon,
    SortBy,
    Searchbar,
    Toolbar,
    IonContent,
    IonIcon,
    IonButtons,
    PropertyList,
    PropertyMap,
    Skeleton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    AButton,
    IonBackButton,
    IonButton,
    IonImg,
    IonSearchbar,
    UserDialogButton,
  },
  setup() {
    const store = useStore();
    const useData = useFetchData();
    const router = useRouter();
    const uUser = useUser();
    const { t } = useI18n({ useScope: "global" });
    const { isMinLarge } = useScreenSize();
    const routeName = computed(() => { return router.currentRoute.value.name; });

    /**
     * View mode handling: map/list
     */
    const selectedView = ref("list");
    const toggleView = (selected: string) => {
      selectedView.value = selected;
      if (selected === "map") {
        router.push("/properties/map");
      } else {
        router.push("/properties");
      }
    };

    /**
     * Data handling: Fetch data, sort, search
     */
    const { properties, loadImmobilienPreviews, propertiesLoaded, supportedSortTerms } = useProperties();

    const searchTerm = ref<string>(Immobilie.getters("searchTerm"));
    const sortTerm = ref<SortTermActive | undefined>(Immobilie.getters("sortBy"));

    watch([searchTerm, sortTerm], () => {
      Immobilie.dispatch("setSearchTerm", searchTerm.value);
      Immobilie.dispatch("setSortBy", sortTerm.value);
      Immobilie.dispatch("setPage", 0);
      Immobilie.deleteAll();
      loadImmobilienPreviews();
    });

    const search = (e: any) => { searchTerm.value = e.target.value; };

    const showLoadMore = computed(() => {
      return (
        Immobilie.getters("pageCount") &&
        Immobilie.getters("page") < Immobilie.getters("pageCount")!
      );
    });

    const loadMore = () => {
      loadImmobilienPreviews();
    };


    /**
     * Set and Manage bottom navigation bar
     */
    const manageToolbar = (newVal: any) => {
      if (newVal === "propertiesMap") {
        store.commit("app/hideBottomNavigation");
      } else {
        store.commit("app/showBottomNavigation");
      }
    };
    onIonViewWillEnter(() => { manageToolbar(router.currentRoute.value.name); });
    watch(routeName, (newVal: any) => { manageToolbar(newVal); });


    /**
     * Others
     */
    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData("lastBackendFetch"));
      return `${date.format("DD.MM.YYYY")} / ${date.format("HH:mm:ss")} Uhr`;
    });

    return {
      selectedView,
      properties,
      propertiesLoaded,
      toggleView,
      searchTerm,
      objectCountHeight: 62,
      user: uUser.user,
      search,
      supportedSortTerms,
      sortTerm,
      t,
      showLoadMore,
      loadMore,
      locationOutline,
      listOutline,
      routeName,
      doRefresh: useData.doRefresh,
      openPopover: popoverHelper,
      lastFetch,
      isMinLarge,
      swapVerticalOutline,
      arrowBack,
    };
  },
});
