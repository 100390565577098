import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["size"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isReadonly)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 0,
          fill: "clear",
          color: "danger",
          class: "my-0",
          "data-cy": "delete-entity-button",
          size: _ctx.largeIcons ? 'default' : 'small',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              icon: _ctx.trashOutline,
              size: _ctx.largeIcons ? 'large' : 'small'
            }, null, 8, ["icon", "size"])
          ]),
          _: 1
        }, 8, ["size"]))
      : _createCommentVNode("", true),
    (!_ctx.isReadonly)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 1,
          fill: "clear",
          color: "dark",
          "data-cy": "duplicate-entity-button",
          class: "my-0",
          size: _ctx.largeIcons ? 'default' : 'small',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('duplicate')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              icon: _ctx.duplicateOutline,
              size: _ctx.largeIcons ? 'large' : 'small'
            }, null, 8, ["icon", "size"])
          ]),
          _: 1
        }, 8, ["size"]))
      : _createCommentVNode("", true),
    (!_ctx.isReadonly && !_ctx.hideAddButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "btn-no-styling my-0",
          "data-cy": "button-add-items",
          fill: "clear",
          color: "dark",
          size: _ctx.largeIcons ? 'default' : 'small',
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('add')))
        }, [
          _createVNode(_component_ion_icon, {
            icon: _ctx.addOutline,
            size: _ctx.largeIcons ? 'large' : 'small'
          }, null, 8, ["icon", "size"])
        ], 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}