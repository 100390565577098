import {Mangelzuordnung} from "@/models/ba/Mangelzuordnung";
import moment from "moment";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {computed} from "vue";
import Immobilie from "@/models/immobilie.model";
import {useI18n} from "vue-i18n";


export default function useDownloadMaengel(ba: Bestandsaufnahme, downloadFile: Function, t: any) {
    const maengel = ba.getFiredMaengel();
    const immobilie = computed(() => { return Immobilie.find(ba.immobilie);})

    const header = [
        t('hzba.maengelliste.mangelname'),
        t('hzba.maengelliste.maluspunkte'),
        t('hzba.maengelliste.freitext'),
        t('hzba.maengelliste.relevant'),
        t('hzba.maengelliste.gewerk'),
        t('hzba.maengelliste.handlungsbedarfZeitpunkt'),
        t('hzba.maengelliste.handlungsbedarfTaetigkeit'),
        t('hzba.maengelliste.kategorie'),
    ]

    const mapMangel = (mangel: Mangelzuordnung) => {
        return [
            mangel.titel(),
            mangel.maluspunkte(),
            mangel.eingabeFreitext,
            mangel.eingabeRelevant,
            mangel.gewerk(),
            mangel.handlungsbedarfZeitpunkt(),
            mangel.handlungsbedarfTaetigkeit(),
            mangel.kategorie(),
        ]
    }

    const rows = [
        header,
        ...maengel.map((el: any) => mapMangel(el))
    ];

    const csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(";")).join("\n");

    const encodedURI = encodeURI(csvContent);
    const fixedEncodedURI = encodedURI.replaceAll('#', '%23');

    downloadFile(
        fixedEncodedURI,
        `${immobilie.value?.strasse}, ${immobilie.value?.plz} ${immobilie.value?.stadt} - ${t('hzba.maengelliste.maengelliste')} ${moment(ba.begehungsdatum).format('DD.MM.YYYY')}.csv`
    );
}