<template>
  <div class="py-3" :class="separateLine ? 'block' : 'flex'">
    <div class="tracking-wider text-sm font-bold" style="width: 120px; flex-shrink: 0">{{ title }}</div>
    <div class="value-text"><slot></slot>{{ value }}</div>
  </div>
  <div style="border-bottom: 1px solid #f2f2f2"></div>
</template>

<script>
import HzbaSeparator from "@/components/hzba/Base/Input/HzbaSeparator";
export default {
  name: "InfoField",
  components: {HzbaSeparator},
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: ''
    },
    separateLine: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">

.value-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

</style>