import Localization, { lang } from "@/models/localization.model";
import { useStore } from "@/composables/useTypedStore";

export default function getLocalization(item: Localization, forceLanguage?: string): string {
    // const store = useStore();
    // const locale = store.getters["app/getLocale"] as  lang;

    const locale: lang = (forceLanguage || (localStorage.getItem('locale') || 'de')) as lang;


    if (typeof item === 'string') {
        return item;
    }

    return item && item[locale]!;
}