
import { useI18n } from 'vue-i18n';
import {getPhotoUrl} from "@/utilities/get-media-url";
import moment from "moment";

import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import {computed, defineComponent, PropType} from "vue";
import { useStore } from "@/composables/useTypedStore";
import Immobilie from "@/models/immobilie.model";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import GenericPdfDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralData.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {Fragenblock} from "@/models/ba/Fragenblock";
import useUser from "@/composables/useUser";
import GenericPdfMgtSummaryTable from '../Components/GenericPdfMgtSummaryTable.vue';




export default defineComponent({
  name: "PdfAnlagenschemaTemplate",
  components: {
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    GenericPdfDataTable,
    PdfColorCodes,
    PdfMSEValues, PdfImageRow, PdfDataSectionSummary, PdfA4Page, PdfPageHeader, PdfAconLogo,
    GenericPdfMgtSummaryTable,
},
  props: {
    hzba: {
      // type: Object as PropType<Heizungsbestandsaufnahme>,
      required: true
    },
    // genericHzba: {
    //   type: Object as PropType<Bestandsaufnahme>,
    //   required: true
    // },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  setup(props: any) {
    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })
    const maxPages = 7;

    const uUser = useUser();

    const genericHzbaFromStore = computed<Bestandsaufnahme | undefined>(() => {
      console.log('store.state.currentHzba',store.state)
      return store.state.currentHzba.currentBa;
    });

    const fragenblocks = computed<Fragenblock[] | undefined>(() => {
      console.log('typeof hzba.value?.fragenblocks', genericHzbaFromStore.value?.fragenblocks)
      return genericHzbaFromStore.value?.fragenblocks
    })
    // console.log(techData())

    function techData(){
      if(!fragenblocks.value) return
      const copyOfFragenBlocks = [...fragenblocks.value]
      console.log('copyOfFragenBlocks',copyOfFragenBlocks)
      copyOfFragenBlocks.shift()
      console.log('techdatatest',techData)
    return copyOfFragenBlocks
    }



    return {
      // hzba,
      // immobilie,
      getPhotoUrl,
      techData,
      fragenblocks,
      maxPages,
      moment,
      user: uUser.user,
      genericHzbaFromStore,
      t
    }
  }
})
