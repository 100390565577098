<template>
  <ion-col
    v-if="showUnit"
    class="flex-grow-0 p-0"
  >
    <div
      style="width: 50px"
    >
      <div
        v-if="unit"
        class="inline-block unit"
        :class="unit.length >= 4 ? 'text-xs' : ''"
      >
        {{ unit }}
      </div>
    </div>
  </ion-col>
</template>

<script>
import { IonCol } from "@ionic/vue";

export default {
  name: "UnitChip",
  components: { IonCol },
  props: {
    showUnit: {
      type: Boolean,
      default: true
    },
    unit: {
      type: String,
      default: undefined
    },
  },
}
</script>

<style scoped>

.unit {
  /*background: #efefef;*/
  padding: 4px 8px;
  border-radius: 4px;
}


</style>