<template>

  <ion-item lines="none">
    <div class="w-full border-b border-black" style="height: 1px; background: #efefef"></div>
    <div class="absolute left-4 px-2" style="background: white; color: #a9a9a9">
      <slot></slot>
    </div>
  </ion-item>

</template>

<script>

import { IonItem } from '@ionic/vue';

export default {
  components: {
    IonItem,
  },
  name: "HzbaSeparator"
}
</script>

<style scoped>

</style>