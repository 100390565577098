import { APhoto } from '@/models/photo/a-photo.model';
import { Frage } from '@/models/ba/Frage';
import { BaNode, ToJsonSettings } from '@/models/ba/interfaces/IBaNode';
import { MangelConfig, MangelLogik, MangelzuordnungCustom, MangelzuordnungFlatJson, MangelzuordnungJson } from '@/models/ba/interfaces/IMangelzuordnung';
import Mangelvorlage, { MangelGewerk } from '@/models/ba/Mangelvorlage';
import { LogikElement } from '@/models/ba/LogikElement';
import { isEmptyObject } from '@/utilities/helper';
import { MaengelReport } from '@/models/ba/MaengelReport';
import { ExtendedMSERow } from '@/models/ba/interfaces/IGenericPdfDataRow';
import {APhotoUploadTask} from "@/api/UploadApi";
import {generateUUID} from "@ionic/cli/lib/utils/uuid";
import {instanceOfPhoto} from "@/utilities/get-media-url";
import Immobilie from "@/models/immobilie.model";
import {usePhotoRecording} from "@/composables/usePhotoRecording";

export class Mangelzuordnung implements BaNode, MangelzuordnungFlatJson {
  /**
   * Backend Fields stored as primitives
   */
  public id?: number;
  public createdAt?: string;
  public updatedAt?: string;
  public mangelvorlage?: Mangelvorlage;
  public eingabeRelevant?: boolean | null;
  public eingabeFreitext?: string | null;
  public eingabeMedien: APhoto[] = [];
  public custom: MangelzuordnungCustom;
  public isTriggered?: boolean;
  public config?: MangelConfig;
  public tag3d?: any;

  public mangellogik?: LogikElement;

  public isFireDetectorSetup = false;
  private showErrors = false;
  private mangelLogicElements: { currentEl: Frage; logic: MangelLogik }[] = [];

  public collapsed = true;

  constructor(json: MangelzuordnungJson, public parentPath: string, private mode?: number, private isCustomProp?: boolean) {
    this.id = json.id;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.mangelvorlage = json.mangelvorlage;
    this.eingabeRelevant = json.eingabeRelevant;
    this.eingabeFreitext = json.eingabeFreitext;
    this.eingabeMedien = json.eingabeMedien || [];
    this.custom = json.custom || {};
    this.isTriggered = json.isTriggered;
    this.config = json.config;
    this.tag3d = json.tag3d;

    if (json.mangellogik && !isEmptyObject(json.mangellogik)) {
      this.mangellogik = new LogikElement(JSON.parse(JSON.stringify(json.mangellogik)));
    }
  }

  /**
   * Find all Elements referenced by the identifier to keep track on those variables when they have changed.
   *
   * For some reason, when this function is called within a .ts file, the store won't be initialized. That's why
   * we don't call it in the constructor, but quite similar after setup within a .vue file.
   */
  public setupFireDetector(): void {
    // if (this.mangellogik && this.mangellogik.length > 0) {
    //     console.log("-------------", this.parentPath, this.mode)
    //     this.logikElements[0].setupFireDetector(this.parentPath, this.mode)
    //     // todo support more than the first child or change from array to object
    //     this.isFireDetectorSetup = true;
    // }

    if (!this.mangellogik || isEmptyObject(this.mangellogik)) {
      this.isFireDetectorSetup = false;
    } else {
      this.mangellogik.setupFireDetector(this.parentPath, this.mode);
      this.isFireDetectorSetup = true;
    }
  }

  /**
   * Update whether this Mangel is fired or not.
   * "setupFireDetector" needs to be called in advance.
   */
  public isFired(isParentShown?: boolean) {
    if (isParentShown === false) {
      this.isTriggered = false;
      return false;
    }

    if (!this.isFireDetectorSetup) {
      return this.isTriggered;
    }

    if (this.mangellogik) {
      this.isTriggered = this.mangellogik.isFired();
      return this.isTriggered;
    } else {
      this.isTriggered = true;
      return true;
    }
  }

  public isEingabeRelevantValid() {
    return this.config?.hideIstRelevant || this.config?.optionalIstRelevant || (this.eingabeRelevant !== undefined && this.eingabeRelevant !== null);
  }
  public isEingabeFreitextValid() {
    return this.config?.hideFreitext || this.config?.optionalFreitext || (this.eingabeFreitext !== undefined && this.eingabeFreitext !== null && this.eingabeFreitext.length > 0);
  }
  public isEingabeBildValid() {
    return this.config?.hideBilder || this.config?.optionalBilder || (this.eingabeMedien && this.eingabeMedien.length > 0);
  }
  public isEingabeCustomTitelValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomTitel ||**/ (this.custom?.titel && this.custom.titel?.length > 0);
  }
  public isEingabeCustomGewerkValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomGewerk ||**/ (this.custom?.gewerk && this.custom.gewerk?.length > 0);
  }
  public isEingabeCustomHandlungsbedarfTaetigkeitValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomHandlungsbedarfTaetigkeit ||**/ (this.custom?.handlungsbedarfTaetigkeit && this.custom.handlungsbedarfTaetigkeit?.length > 0);
  }
  public isEingabeCustomHandlungsbedarfZeitpunktValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomHandlungsbedarfZeitpunkt || **/ (this.custom?.handlungsbedarfZeitpunkt && this.custom.handlungsbedarfZeitpunkt?.length > 0);
  }
  public isEingabeCustomKategorieValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomKategorie ||**/ (this.custom?.kategorie && this.custom.kategorie?.length > 0);
  }
  public isMaluspunkteValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomKategorie ||**/ ((this.custom?.maluspunkte) && (this.custom.maluspunkte > 0));
  }
  public isProduktTypValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomKategorie ||**/ (this.custom?.produktTyp);
  }

  public isQuelleValid() {
    return !this.isCustomProp || /**!this.config?.requireCustomKategorie ||**/ (this.custom?.quelle);
  }

  public getFreitextLabel(t: any) {
    return this.config?.labelFreitext || t('hzba.mangel.freitext');
  }

  public getIstRelevantLabel(t: any) {
    return this.config?.labelIstRelevant || t('hzba.mangel.istRelevant');
  }

  public getBilderLabel(t: any) {
    return this.config?.labelBilder || t('hzba.bilder.titel');
  }

  public isInputValid() {
    return (
      this.isEingabeRelevantValid() &&
      this.isEingabeFreitextValid() &&
      this.isEingabeBildValid() &&
      this.isEingabeCustomTitelValid() &&
      this.isEingabeCustomGewerkValid() &&
      this.isEingabeCustomHandlungsbedarfTaetigkeitValid() &&
      this.isEingabeCustomHandlungsbedarfZeitpunktValid() &&
      this.isEingabeCustomKategorieValid() &&
      this.isMaluspunkteValid() &&
      this.isQuelleValid() &&
      this.isProduktTypValid()
    );
  }

  public titel() {
    return this.custom?.titel ? this.custom.titel : this.mangelvorlage?.kurztext;
  }
  public maluspunkte() {
    // return this.custom?.maluspunkte || this.mangelvorlage?.maluspunkte || 0;

    // Make sure custom Mangel (String) is added to total mangelcount!
    try {
      // @ts-ignore
      return Number.parseInt(this.custom?.maluspunkte || this.mangelvorlage?.maluspunkte || 0);
    } catch (err) {
      console.error('Error while parsing maluspunkte()!', err);
      return -1;
    }
  }
  public malusColor() {
    const mr = new MaengelReport([this]);
    return mr.getColorByMaengel();
  }

  public getMSERow(): ExtendedMSERow {
    const mr = new MaengelReport([this]);

    const mseSummary = mr.getMSESummary();
    mseSummary.title = this.titel();

    return mseSummary;
  }

  public uid() {
    return this.mangelvorlage?.uid || undefined;
  }
  public gewerk() {
    return this.custom?.gewerk || this.mangelvorlage?.gewerk || undefined;
  }

  public gewerkLabel(): string {
    const gewerk = this.gewerk();

    // @ts-ignore
    return gewerk && MangelGewerk[gewerk];
  }

  public kategorie() {
    return this.custom?.kategorie || this.mangelvorlage?.kategorie || undefined;
  }
  public handlungsbedarfTaetigkeit() {
    return this.custom?.handlungsbedarfTaetigkeit || this.mangelvorlage?.handlungsbedarfTaetigkeit || undefined;
  }
  public handlungsbedarfZeitpunkt() {
    return this.custom?.handlungsbedarfZeitpunkt || this.mangelvorlage?.handlungsbedarfZeitpunkt || '-';
  }
  public isCustom() {
    return !!this.custom?.titel;
  }

  public setShowErrors() {
    this.showErrors = true;
  }

  public getColor() {
    if (this.kategorie() === 'VERSORGUNGSGEFAEHRDEND') {
      return 'yellow';
    }
    if (this.kategorie() === 'SICHERHEITSRELEVANT') {
      return 'red';
    }
    return 'blue';
  }

  public isSicherheitsrelevant() {
    return this.kategorie() === 'SICHERHEITSRELEVANT';
  }
  public isVersorgungsgefaehrdend() {
    return this.kategorie() === 'VERSORGUNGSGEFAEHRDEND';
  }
  static getVersorgungsgefaehrdendLabel() {
    return 'Versorgungsrelevant'; // todo i18n
  }
  static getSicherheitsrelevantLabel() {
    return 'Sicherheitsrelevant'; // todo i18n
  }
  static getSonstigerLabel() {
    // return 'Sonstiger'; // todo i18n
    return 'Nicht relevant'
  }


  public getMediaUploadTasks(immobilie: Immobilie, baIdentifier: string, localOnly?: boolean): APhotoUploadTask[] {
    if (!this.eingabeMedien || this.eingabeMedien?.length <= 0) { return []; }
    if (!this.config) this.config = {};
    this.config.photoUploadUid = generateUUID();

    return this.eingabeMedien.filter(el => !localOnly || instanceOfPhoto(el)).map(el => {
      return {
        field: "eingabeMedien",
        image: el,
        ref: "mangels",
        api: "api::mangel.mangel",
        uid: this.config!.photoUploadUid || '',
        status: instanceOfPhoto(el) ? "preparing" : "uploaded",
        instanceId: this.id,
        fileName: `${immobilie?.strasse}_${immobilie?.plz}_${immobilie?.stadt}_${baIdentifier}_mangel.jpeg`
      }
    });
  }

  public errors(t: any) {
    if (!this.showErrors) {
      return [];
    }

    const errors = [];
    if (!this.isInputValid() && this.isFired()) {
      if (!this.isEingabeRelevantValid()) {
        errors.push({
          $id: 'relevant',
          $message: t('hzba.mangel.mangelRelevantInfo'),
        });
      }
      if (!this.isEingabeFreitextValid()) {
        errors.push({
          $id: 'freitext',
          $message: t('hzba.mangel.freitextBenoetigtInfo'),
        });
      }
      if (!this.isEingabeBildValid()) {
        errors.push({
          $id: 'bilder',
          $message: t('hzba.mangel.bilderBenoetigtInfo'),
        });
      }

      if (!this.isEingabeCustomTitelValid()) {
        errors.push({
          $message: t('hzba.mangel.mangelTitelInfo'),
          $id: 'customTitel',
        });
      }
      if (!this.isEingabeCustomGewerkValid()) {
        errors.push({
          $message: t('hzba.mangel.mangelGewerkInfo'),
          $id: 'customGewerk',
        });
      }
      if (!this.isEingabeCustomHandlungsbedarfTaetigkeitValid()) {
        errors.push({
          $message: t('hzba.mangel.mangelHandlungsbedarfTaetigkeitInfo'),
          $id: 'customHandlungsbedarfTaetigkeit',
        });
      }
      if (!this.isEingabeCustomHandlungsbedarfZeitpunktValid()) {
        errors.push({
          $message: t('hzba.mangel.mangelHandlungsbedarfZeitpunktInfo'),
          $id: 'customHandlungsbedarfZeitpunkt',
        });
      }
      if (!this.isEingabeCustomKategorieValid()) {
        errors.push({
          $message: t('hzba.mangel.mangelKategorieInfo'),
          $id: 'customKategorie',
        });
      }
      if (!this.isProduktTypValid()) {
        errors.push({
          $message: t('hzba.mangel.produktTypInfo'),
          $id: 'produktTyp',
        });
      }
      if (!this.isQuelleValid()) {
        errors.push({
          $message: t('hzba.mangel.quelleInfo'),
          $id: 'quelle',
        });
      }
      if (!this.isMaluspunkteValid()) {
          if ((this.custom?.maluspunkte) && (this.custom.maluspunkte < 0)) {

          errors.push({
            $message: t('hzba.mangel.maluspunkteInfoNegativ'),
            $id: 'maluspunkte',
          });
        } else {
            errors.push({
              $message: t('hzba.mangel.maluspunkteInfo'),
              $id: 'maluspunkte',
            });
        }
      }
    }
    return errors;
  }

  public submit() {
    this.isTriggered = this.isFired();
  }

  public async toClassJson(settings: ToJsonSettings = {}): Promise<MangelzuordnungJson> {
    const logikElement = settings.cleanup ? this.mangellogik?.cleanAndCopyJson() : this.mangellogik?.copyJson();


    let copyOfEingabeMedien: APhoto[] = this.eingabeMedien;
    if (settings.cleanup) {
      copyOfEingabeMedien = await Promise.all(this.eingabeMedien.map(async el => {
        const dupPhoto = await usePhotoRecording().duplicatePhoto(el);
        if (dupPhoto.success && dupPhoto.data && dupPhoto.data.length > 0) {
          return dupPhoto.data[0];
        }
        console.error('Duplicating photo was not successful', dupPhoto, el)
        return el;
      }))
    }

    return {
      id: settings.cleanup ? undefined : this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      mangelvorlage: this.mangelvorlage,
      mangellogik: logikElement,
      eingabeRelevant: this.isTriggered ? this.eingabeRelevant : undefined,
      eingabeFreitext: this.isTriggered ? this.eingabeFreitext : undefined,
      eingabeMedien: settings.prepareForSync || !this.isTriggered ? undefined : copyOfEingabeMedien,
      custom: this.isTriggered ? this.custom : {},
      isTriggered: this.isTriggered,
      config: this.config,
      tag3d: this.tag3d,
    };
  }

  public async toLeanJson(settings: ToJsonSettings = {}): Promise<MangelzuordnungJson> {
    return {
      id: settings.cleanup ? undefined : this.id,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      eingabeRelevant: this.isTriggered ? this.eingabeRelevant : undefined,
      eingabeFreitext: this.isTriggered ? this.eingabeFreitext : undefined,
      eingabeMedien: settings.prepareForSync || !this.isTriggered ? undefined : this.eingabeMedien,
      custom: this.isTriggered ? this.custom : {},
      isTriggered: this.isTriggered,
      config: this.config,
    };
  }

  /**
   * Deep copy this class with all of it's subobjects.
   */
  public async copyJson(settings: ToJsonSettings = {}): Promise<MangelzuordnungJson> {
    return JSON.parse(JSON.stringify(await this.toClassJson(settings)));
  }

  public async copyLeanJson(settings: ToJsonSettings = {}): Promise<MangelzuordnungJson> {
    return JSON.parse(JSON.stringify(await this.toLeanJson(settings)));
  }

  /**
   * Deep copy this class with all of it's subobjects.
   * Use this if you want an object that is like the original
   * but without specific id's that makes the original instance unique (e.g. copy from template, duplicate)
   */
  public async cleanAndCopyJson(): Promise<MangelzuordnungJson> {
    return JSON.parse(JSON.stringify(await this.toClassJson({ cleanup: true })));
  }

  public async copyMangelzuordnung(forceCustomProp?: boolean): Promise<Mangelzuordnung> {
    return new Mangelzuordnung(JSON.parse(JSON.stringify(await this.copyJson())), this.parentPath, this.mode, forceCustomProp || this.isCustomProp);
  }

  public async print(): Promise<void> {
    console.log(`Print Mangelzuordnung with identifier ${this.parentPath} and mode ${this.mode}`, await this.toClassJson());
  }

  public filterPhotos(filterFctn: Function): APhoto[] {
    const photos: APhoto[] = [];
    this.eingabeMedien?.forEach((el) => {
      if (filterFctn(el)) {
        photos.push(el);
      }
    });

    return photos;
  }
}
