
import { defineComponent } from "vue";

export default defineComponent({
  name: "PdfA4Page",
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
    pdfKey: {
      type: String,
      required: true,
    }
  },
});
