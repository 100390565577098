
import { close, download, list, share } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import HzbaBaseModal from "@/components/Base/BaseModal.vue";
import HzbaGroup from "@/components/hzba/Base/HzbaGroup.vue";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput.vue";
import { computed, defineComponent, PropType } from "vue";
import { arrowBack } from "ionicons/icons";
import {
  IonIcon,
  IonContent,
  IonHeader,
  IonChip,
  IonButton,
  IonButtons,
} from "@ionic/vue";
import SectionWrapper from "@/components/Base/SectionWrapper.vue";
import Toolbar from "@/components/Navigation/Toolbar.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import useBaseModal from "@/composables/useModal";
import useDownloadHelper from "@/composables/useDownloadHelper";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { MangelGewerk, MangelKategorie } from "@/models/ba/Mangelvorlage";
import Immobilie from "@/models/immobilie.model";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import moment from "moment";
import HzbaChooseInput from "@/components/hzba/Base/Input/HzbaChooseInput.vue";

export default defineComponent({
  name: "MaengelUebersichtInImmobilileModal",
  components: {
    HzbaChooseInput,
    BaseModal,
    IonChip,
    Toolbar,
    HzbaBaseInput,
    HzbaGroup,
    HzbaBaseModal,
    SectionWrapper,
    IonIcon,
    IonContent,
    IonHeader,
    IonButton,
    IonButtons,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
  },
  emits: ["close", "save"],
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });
    const { defaultCancel } = useBaseModal();

    const maengelJson = computed(() => {
      return props.ba.mangellists && props.ba.mangellists.filter((el: any) => el.eingabeRelevant);
    });

    const { canShare, downloadFile } = useDownloadHelper();

    const correctSpelling = (input:string) => {

      switch(input) {
        
        case 'VERSORGUNGSGEFAEHRDEND': return 'Versorgungsrelevant';
        case 'VERSORGUNGSRELEVANT': return 'Versorgungsrelevant';
        case 'SICHERHEITSRELEVANT': return 'Sicherheitsrelevant';
        case 'SONSTIGER': return 'Sonstiger';
        case 'SERVICE': return 'Service';
        case 'BAU_TISCHLER': return 'Bau/Tischler';
        case 'HEIZUNG_SANITAER_MSR': return 'Heizung/Sanitär/MSR';
        case 'ELEKTRO': return 'Elektro';
        case 'ZEITNAH': return 'Zeitnah';
        case 'ABSOFORT': return 'Ab sofort';
        case 'FEUVO': return 'FeuVO';
        case 'EFFICIENCY': return 'Efficiency';
        case 'HINWEIS': return 'Hinweis';
        case 'SAFETY': return 'Safety';

        default: return input;
      }
    };

    const downloadMangelliste = (
      ba: Bestandsaufnahme,
      downloadFile: Function,
      t: any
    ) => {
      const immobilie = computed(() => {
        return Immobilie.find(ba.immobilie);
      });
      // console.log('immobilie', immobilie);
      const header = [
        t("hzba.maengelliste.mangelname"),
        t("hzba.maengelliste.maluspunkte"),
        t("hzba.maengelliste.freitext"),
        t("hzba.maengelliste.relevant"),
        t("hzba.maengelliste.gewerk"),
        t("hzba.maengelliste.handlungsbedarfZeitpunkt"),
        t("hzba.maengelliste.handlungsbedarfTaetigkeit"),
        t("hzba.maengelliste.kategorie"),
        t("hzba.maengelliste.pfad"),
      ];

      const mapMangel = (mangel: any) => {
        return [
          mangel.kurztext,
          mangel.maluspunkte,
          mangel.eingabeFreitext,
          mangel.eingabeRelevant,
          mangel.gewerk,
          mangel.handlungsbedarfZeitpunkt,
          mangel.handlungsbedarfTaetigkeit,
          mangel.kategorie,
          mangel.pfad
        ];
      };

      const rows = [header, ...maengelJson.value.map((el: any) => mapMangel(el))];

        const csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");

        const encodedURI = encodeURI(csvContent);
        const fixedEncodedURI = encodedURI.replaceAll('#', '%23');

        downloadFile(
            fixedEncodedURI,
            `${immobilie.value?.strasse}, ${immobilie.value?.plz} ${immobilie.value?.stadt} - ${t('hzba.maengelliste.maengelliste')} ${moment(ba.begehungsdatum).format('DD.MM.YYYY')}.csv`
      );
    };

    const downloadResource = () => {
      downloadMangelliste(props.ba, downloadFile, t);
    };

    return {
      arrowBack,
      closeModal: defaultCancel,
      maengelJson,
      MangelKategorie,
      MangelGewerk,
      canShare,
      t,
      share,
      close,
      downloadResource,
      download,
      correctSpelling
    };
  },
});
