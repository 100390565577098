
import AppLinkBanner from "@/components/AppLinkBanner.vue";
import DevBox from "@/components/DevBox";
import Navigation from "@/components/Navigation/Toolbar";
import UpdateScreen from "@/components/Other/UpdateScreen.vue";
import useFetchData from "@/composables/useFetchData";
import useNetworkState from "@/composables/useNetworkState";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import AppLogger from "@/utilities/AppLogger";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { Geolocation } from '@capacitor/geolocation';
import {
IonApp,
IonHeader,
IonIcon,
IonLabel,
IonPage,
IonRouterOutlet,
IonSlide,
IonSlides,
IonTabBar,
IonTabButton,
IonTabs,
IonTitle,
IonToolbar,
isPlatform,
} from "@ionic/vue";
import { home, reader, settings } from "ionicons/icons";
import { computed, defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useRoute } from "vue-router";
import useUser from './composables/useUser';
import useEnvVar from "@/composables/useEnvVar";

export default defineComponent({
  name: "App",
  components: {
    UpdateScreen,
    AppLinkBanner,
    Navigation,
    IonApp,
    IonTabBar,
    IonTabButton,
    IonSlides,
    IonSlide,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonHeader,
    IonToolbar,
    IonTitle,
    DevBox,
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const store = useStore();
    const useData = useFetchData();
    const screenSize = useScreenSize();
    const networkState = useNetworkState();
    const user = useUser();
    const route = useRoute();
    let justStarted = true;
    const { getVar } = useEnvVar();

    const showBottomNavigation = computed(() => {
      return store.state.app.showBottomNavigation;
    });

    const showAppLink = computed(() => {
      return store.state.app.showAppLinkBanner;
    });

    const isDesktopToolbarForbidden = computed(() => {
      return !(isPlatform("ios") || isPlatform("ipad"));
    });

    const isDev = getVar("NODE_ENV") == "development";

    AppLogger.setup(store);


    onMounted(async () => {
      const { uuid } = await Device.getId();
      await networkState.mountMe();
      store.commit("app/setDeviceId", uuid);

      if (isPlatform("mobileweb")) {
        store.commit('app/setShowAppLinkBanner', true)
      }

      useData.fetch();
      screenSize.mountMe();

      const getLocaleFromStorage = localStorage.getItem('locale') || 'de';
      store && store.commit('app/setLocale', getLocaleFromStorage);
    });


    App.addListener('appRestoredResult', data => {
      console.log('Restored state:', data);
    });

    onUnmounted(async() => {
      screenSize.unmountMe();
      await networkState.unmountMe();
    });
    requestPermissionsForMobile();
    async function requestPermissionsForMobile() {
      if ((isPlatform("ios") || isPlatform("ipad") || isPlatform("android"))) {
        await Geolocation.requestPermissions();
      }
    }


    return {
      isDev,
      home,
      settings,
      reader,
      showBottomNavigation,
      showAppLink,
      isDesktopToolbarForbidden,
      t,
      user,
    };
  },
});
