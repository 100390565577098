
import { computed, defineComponent, PropType } from "vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { useI18n } from "vue-i18n";
import PdfRowMangel from "@/components/Pdf/Components/PdfRowMangel.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfRowCustomInput from "@/components/Pdf/Components/PdfRowCustomInput.vue";
import {usePdfContentSlicer} from "@/components/Pdf/Bericht_Heizung/usePdfContentSlicer";
import {CustomInputData} from "@/components/Pdf/Bericht_Heizung/useHzbaPdfReport";

export default defineComponent({
  name: "PdfCustomInputPage",
  components: {
    PdfRowCustomInput,
    PdfColorCodes,
    PdfPageHeader,
    PdfRowMangel,
    PdfDataTable,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    customInputPageCount: {
      type: Number,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    customInputs: {
      type: Object as PropType<CustomInputData[]>,
      required: true
    },
    pagesOptions: {
      type: Object as PropType<{ currentStartPage: any; maxPages: any }>,
      required: true,
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
  },
emits: ['update:customInputPageCount'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });

    const pagedCustomInput = computed(() => {
      const pagedInput = usePdfContentSlicer<CustomInputData>(props.customInputs || [], 26, 2, (element: CustomInputData) => {
        return element.bilder ? 4 : null
      });

      emit("update:customInputPageCount", pagedInput.length);
      return pagedInput;
    });

    return {
      pagedCustomInput,
      t,
    };
  },
});
