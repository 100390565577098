
export interface AnzeigeLogik {
    id: number;
    updatedAt: string;
    createdAt: string;
    pfad: string;
    wert: boolean;
}


export interface ToJsonSettings {
    cleanup?: boolean;
    prepareForSync?: boolean;
}


export interface BaNode {
    toClassJson: Function
}


export interface BaNodeFrageAndFragenblock extends BaNode {

    setShowErrors: Function;

    // isInputValid: Function;

}



export const sortArrayPosition = (a: any, b: any) => {
    return (b.arrayPosition ? b.arrayPosition : 0) > (a.arrayPosition? a.arrayPosition : 0) ? -1 : 1;
}