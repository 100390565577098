import {useBestandsaufnahmens} from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import useAlert from "@/composables/useAlert";
import {useRouter} from "vue-router";


export default async function useLogoutAlert(router: any, t: any) {
    const { doLocalBestandsaufnahmesExists } = useBestandsaufnahmens();

    if (!await doLocalBestandsaufnahmesExists()) {
        await router.push('/logout')
    } else {
        const alert = useAlert();

        return alert.showAdv(
            t("alerts.logoutLocalData"),
            t("alerts.logoutLocalDataText"),
            [
                {
                    text: t("hzba.buttons.ausloggenUndDatenLoeschen"),
                    handler: async () => {
                        await router.push('/logout')
                    }
                },
                { text: t("hzba.buttons.abbrechen") },
            ],
            {
                backdropDismiss: false
            }
        )
    }
}