
import moment from "moment";
import { computed, defineComponent, PropType } from "vue";

import { usePdfContentSlicer } from "@/components/Pdf/Bericht_Ensimimav/usePdfContentSlicer";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeaderEnsimimav.vue";
import PdfRowCustomInput from "@/components/Pdf/Components/PdfRowCustomInputEnsimimav.vue";
import PdfRowMangel from "@/components/Pdf/Components/PdfRowMangel.vue";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import { APhoto } from '@/models/photo/a-photo.model';
import { getPhotoUrl } from '@/utilities/get-media-url';
import { useI18n } from "vue-i18n";

export interface CustomInputData {
  titel: string,
  freitext: string,
  bilder: APhoto[],
  gewerk: string,
}

export default defineComponent({
  name: "PdfMangelInputPageEnsimimav",
  components: {
    PdfRowCustomInput,
    PdfPageHeader,
    PdfRowMangel,
    PdfDataTable,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    customInputPageCount: {
      type: Number,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    customInputs: {
      type: Object as PropType<CustomInputData[]>,
      required: true
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
     title: {
      type: String,
      default: "Bezeichnung"
     },
    noValues: {
      type: String,
      default: "Keine Einträge vorhanden."
    },
    aufmass: {
      type: Boolean,
      default: false,
    },
    unterschrift: {
      type: Boolean,
      default: false
    },
    crumbsBeginning: {
      type: String,
      default: ""
    },
    crumbsMiddle: {
      type: String,
      default: ""
    },
    crumbsEnd: {
      type: String,
      default: ""
    }
  },
emits: ['update:customInputPageCount'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });

    const pagedCustomInput = computed(() => {
      const pagedInput = usePdfContentSlicer<CustomInputData>(props.customInputs || [], 26, 2, (element: CustomInputData) => {
        return element.bilder ? 4 : null
      });

      emit("update:customInputPageCount", pagedInput.length);
      return pagedInput;
    });

    return {
      moment,
      pagedCustomInput,
      t,
      getPhotoUrl
    };
  },
});
