
import { computed, defineComponent, PropType } from "vue";
import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import { MSERow } from "@/models/ba/interfaces/IGenericPdfDataRow";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import { getNestedBreadcrumbTitles } from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { useI18n } from "vue-i18n";
import PdfRowMangel from "@/components/Pdf/Components/PdfRowMangel.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import {usePdfContentSlicer} from "@/components/Pdf/Bericht_Heizung/usePdfContentSlicer";

export default defineComponent({
  components: {
    PdfColorCodes,
    PdfPageHeader,
    PdfRowMangel,
    PdfDataTable,
    PdfA4Page,
  },
  props: {
    lang: {
      type: String,
      required: true,
    },
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    mangelPages: {
      type: Number,
      required: true,
    },
    pagesOptions: {
      type: Object as PropType<{ currentStartPage: any; maxPages: any }>,
      required: true,
    },
    techData: {
      type: Object as PropType<Fragenblock>,
      required: true,
    },
  },
emits: ['update:mangelPages'],
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });

    const maengelComp = computed(() => {
      return props.ba.getFiredMaengel({ relevantMangelsOnly: true });
    });

    const maengelCompCustom = computed(() => {
      return props.ba.getFiredMaengel({
        freieMangelsOnly: true,
        relevantMangelsOnly: true,
      });
    });


    const pagedMaengel = computed(() => {
      const includeCustomMaengel = maengelComp.value.concat(
          maengelCompCustom.value
      );

      const pagedMaengel = usePdfContentSlicer<Mangelzuordnung>(includeCustomMaengel || [], 26, 2, (element) => {
        return element.eingabeMedien ? 4 : null
      });

      emit("update:mangelPages", pagedMaengel.length);
      return pagedMaengel;
    });

    return {
      pagedMaengel,
      t,
    };
  },
});
