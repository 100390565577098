
import { computed, defineComponent } from "vue";
import {
  IonContent,
  IonProgressBar,
  IonButton,
  IonButtons,
  popoverController,
  modalController,
} from "@ionic/vue";
import { useStore } from "@/composables/useTypedStore";
import { useI18n } from "vue-i18n";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import useUploadImageStatus from "@/composables/useUploadImageStatus";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import AButton from "@/components/Base/AButton.vue";
import {logger} from "@/utilities/logging";
import useScreenSize from "@/composables/useScreenSize";

export default defineComponent({
  name: "HzbaUploadModal",
  components: {AButton, IonContent, IonProgressBar, IonButton, IonButtons },
  setup(props) {
    const store = useStore();
    const { t } = useI18n({ useScope: "global" });

    const uploadImageStatus = useUploadImageStatus();

    const ba = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const uploadStatus = computed(() => {
      return store.state.currentHzba.uploadStatus;
    });

    const isUploadActive = computed(() => {
      return (
        uploadStatus.value === "preparing" ||
        uploadStatus.value === "uploadingData" ||
        uploadStatus.value === "uploadingImages" ||
        uploadStatus.value === "uploadingPdfs" ||
        (uploadStatus.value === "success_json_images" &&
          ba.value?.status === "ABGESCHLOSSEN")
      );
    });

    const isUploadFailed = computed(() => {
      logger.error(`Uploading BA failed`);
      return (
        uploadStatus.value === "errorNoInternetConnection" ||
        uploadStatus.value === "errorBackendDidNotRespond" ||
        uploadStatus.value === "errorData" ||
        uploadStatus.value === "errorImage"
      );
    });

    const isUploadSucceeded = computed(() => {
      logger.info(`BA uploaded successfully!`);
      return (
        uploadStatus.value === "success_sync" ||
        uploadStatus.value === "success" ||
        (uploadStatus.value === "success_json_images" &&
          ba.value?.status !== "ABGESCHLOSSEN")
      );
    });
    const isUploadSucceededSync = computed(() => {
      return (
        (uploadStatus.value === "success_json_images" &&
          ba.value?.status !== "ABGESCHLOSSEN") ||
        uploadStatus.value === "success_sync"
      );
    });

    const { unlockBa: unlockBaCompos } = useBestandsaufnahmens();

    const confirm = () => {
      store.dispatch("currentHzba/confirmSuccessfullUpload");
      // popoverController.dismiss();     
      modalController.dismiss();
    };

    const unlockBa = async () => {
      try {
        ba.value && (await unlockBaCompos(ba.value, t));
        confirm();
      } catch (err) {
        console.error("Could not unlock Ba");
        logger.error(`Could not unlock BA ${ba.value}`);
      }
    };

    const abortSync = async () => {
      await modalController.dismiss();
      store.commit("currentHzba/setPleasePauseSync", true)
    }

    const oneImageToUpload = computed(() => {
      const totalUploads = uploadImageStatus.currentTotalUploads()
      const succesfulUploads = uploadImageStatus.currentSuccessfulUploads()
      return totalUploads - succesfulUploads <= 1 && totalUploads > 0;
    })

    const { isMaxXSmall } = useScreenSize();

    return {
      uploadImageStatus,
      uploadStatus,
      ba,
      abortSync,
      unlockBa,
      confirm,
      isUploadActive,
      isUploadFailed,
      isUploadSucceededSync,
      isUploadSucceeded,
      oneImageToUpload,
      t,
      isMaxXSmall
    };
  },
});
