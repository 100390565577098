
import { useI18n } from 'vue-i18n';
import {IonContent, IonItem, popoverController, IonButton, IonIcon} from '@ionic/vue';
import {defineComponent, PropType, ref, Ref} from 'vue';
import { chevronUpOutline, chevronDownOutline } from "ionicons/icons";
import AFlatButton from "@/components/Base/AFlatButton.vue";
import {SortTerm, SortTermActive} from "@/composables/Sort/SortTerms";

export default defineComponent({
  name: 'SortByPopover',
  components: {AFlatButton, IonContent, IonItem, IonButton, IonIcon },
  props: {
    items: {
      type: Array as PropType<SortTerm[]>,
      required: true
    },
    preselectedItem: {
      type: Object as () => SortTermActive,
      default: undefined,
    }
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' })

    const currentItem: Ref<SortTermActive | undefined> = ref(JSON.parse(JSON.stringify(props.preselectedItem)));

    function dismiss() {
      popoverController.dismiss(currentItem.value);
    }

    function chooseItem(item: SortTerm) {
      const orderBy = currentItem.value?.orderBy;

      if (item.label !== currentItem.value?.label) {
        currentItem.value = {
          label: item.label,
          fieldName: item.fieldName,
          subObject: item.subObject,
          orderBy: 'asc'
        }
      } else if (currentItem.value && item.label === currentItem.value?.label && orderBy === 'asc'){
        currentItem.value.orderBy = 'desc';
      } else if (currentItem.value && item.label === currentItem.value?.label && orderBy === 'desc') {
        currentItem.value.orderBy = 'asc';
      }
    }

    return {
      chooseItem,
      currentItem,
      dismiss,
      chevronUpOutline,
      chevronDownOutline,
      t
    }
  }
});
