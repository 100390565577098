
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import SignaturePad from "signature_pad";
import { refreshOutline, trashOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/vue";
import AInput from "@/components/Base/AInput.vue";
import { getPhotoUrl } from "@/utilities/get-media-url";
import HzbaBaseInput from "@/components/hzba/Base/Input/HzbaBaseInput.vue";

export default defineComponent({
  name: "SignatureField",
  components: {
    HzbaBaseInput,
    AInput,
    IonIcon,
    IonButton,
  },
  props: {
    signature: {
      type: Object,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onSignatureChange", "onSignatureDelete"],
  setup(props, { emit }) {
    const canvasRef = ref();
    const parentRef = ref({ offsetWidth: -1, offsetHeight: -1 });
    const signaturePad = ref();
    const canvasDim = ref<any>({});

    function resize() {
      if (!parentRef.value) {
        return;
      }
      canvasDim.value.width = parentRef.value.offsetWidth;
      canvasDim.value.height = parentRef.value.offsetHeight;

      if (props.isReadonly) {
        return;
      }

      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvasRef.value.width = canvasDim.value.width * ratio;
      canvasRef.value.height = canvasDim.value.height * ratio;
      canvasRef.value.getContext("2d").scale(ratio, ratio);
    }

    function redraw() {
      const bild = props.signature.bild;
      if (bild && bild.rawData) {
        const blob = blobString(bild.rawData);
        signaturePad.value.fromDataURL(blob);
      }
    }

    function blobString(blob: string) {
      if (!blob) {
        return "";
      }
      if (blob.includes("data:image/png")) {
        return blob;
      }

      return `data:image/png;base64,${blob}`;
    }

    onMounted(async () => {
      await nextTick();
      resize();

      window.addEventListener("resize", async () => {
        await nextTick();
          resize();
          redraw();
      });

      if (!props.isReadonly) {
        signaturePad.value = new SignaturePad(canvasRef.value);

        signaturePad.value.addEventListener("endStroke", async () => {
          const dataUri = signaturePad.value.toDataURL();

          // .toDataUrl("image/jpeg") sets the background to black

          const response = await fetch(dataUri);
          const blob: Blob = await response.blob();

          const ret = {
            // filepath: undefined,
            format: blob.type,
            webPath: URL.createObjectURL(blob),
            rawData: dataUri,
            // path: cameraPhoto.path,
          };
          emit("onSignatureChange", ret);
          // setValidation(true)
        });
      }
    });


    watch(() => props.signature.name, async () => {
      await nextTick();
      resize();
      redraw();
    }, { immediate: true })

    watch(
      () => parentRef.value.offsetWidth,
      (newParent) => {
        console.log(
          "Signature Debugging: Parent's width has changed",
          newParent
        );
        resize();
      }
    );

    setTimeout(() => {
      console.log("Signature Debugging: Initial timeout called");
      resize();
      redraw();
    }, 500);

    onUnmounted(() => {
      window.removeEventListener("resize", () => resize());
    });

    function resetSignature() {
      console.log("Reset signature");
      props.signature.bild = undefined;
      signaturePad.value && signaturePad.value.clear();
      emit("onSignatureChange", undefined);
    }

    function deleteSignature() {
      emit("onSignatureDelete");
    }

    return {
      canvasRef,
      parentRef,
      deleteSignature,
      canvasDim,
      refreshOutline,
      resetSignature,
      trashOutline,
      getPhotoUrl,
    };
  },
});
